import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import { makeStyles } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import "./BusinessValueform.css";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import img1 from "../../../assets/Jobs/Iconly-Light-Delete.svg";
import img2 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
  select: {
    height: "1vw",
    width: "100%",
    padding: "1vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
  },
  select2: {
    height: "1vw",
    width: "100%",
    padding: "1vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "black",
  },
}));

const BusinessValueFormCard = ({ data, setRecall, recall }) => {
  const width = 900;
  const classes = useStyles();
  const [errorp, setErrorp] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const deleltecatalogue = () => {
    axios
      .post(
        `${API_HOST}/businessValueForm/removeBusinessform`,
        {
          id: data?._id,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setRecall(!recall);
        handleClose();
      });
  };

  const [title, setTitle] = useState("");
  const [type, settype] = useState(0);
  const [required, setRequired] = useState(false);
  const [valueMultiple, setvalueMultiple] = useState();
  const [optionValues, setOptionValues] = useState([
    { label: "", value: null },
  ]);
  const handleChange3 = (event) => {
    settype(event.target.value);
  };

  useEffect(() => {
    setTitle(data.title);
    settype(data.feildType);
    setRequired(data?.required);
    setOptionValues(data?.options);
    setvalueMultiple(data?.valueMultiple);
  }, [data]);

  const handleaddcertificate = () => {
    const formdata = new FormData();

    formdata.append("id", data?._id);
    formdata.append("title", title);

    formdata.append("feildType", type);
    (type === "Multiple Choice" ||
      type === "Checkboxes" ||
      type === "Drop Down") &&
      formdata.append("options", JSON.stringify(optionValues));
    type === "Number" && formdata.append("valueMultiple", valueMultiple);
    formdata.append("required", required);

    axios
      .post(`${API_HOST}/businessValueForm/editBusinessform`, formdata, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose1();
      });
  };
  return (
    <div
      style={{
        margin: "1vw",
        border: "none",
        boxShadow: "1px 5px 10px #00000050",
        padding: "1vw",
        marginBottom: "2vw",
        height: "fit-content",
        width: "100%",
      }}
      className="workhistrybox"
    >
      <div
        className="workhistryboxtitle"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {data?.title}
          {data.required && <span style={{ color: "red" }}>*</span>}{" "}
          <span style={{ fontSize: "1vw" }}>({data?.feildType})</span>
        </div>
        <div style={{ width: "6vw", height: "3vw", display: "flex" }}>
          <img
            onClick={() => {
              handleOpen1();
            }}
            style={{
              margin: "0vw 0.5vw",
              marginRight: "1vw",
              width: "2vw ",
              height: "2vw",
              borderRadius: "50%",
              cursor: "pointer",
              objectFit: "cover",
            }}
            src={img2}
            alt=""
          />
          <img
            onClick={() => {
              handleOpen();
            }}
            style={{
              margin: "0vw 0.5vw",
              marginRight: "2vw",
              width: "2vw ",
              height: "2vw",
              borderRadius: "50%",
              cursor: "pointer",
              objectFit: "cover",
            }}
            src={img1}
            alt=""
          />
        </div>
      </div>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div style={{ maxHeight: "90vh", overflowY: "scroll" }}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">
                Edit Business Valuation Form Field
              </div>
              <div className="profiledetailnavmanu">
                <CloseIcon
                  onClick={handleClose1}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    position: "relative",
                    right: "1vw",
                  }}
                />
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Field title"
                variant="outlined"
                value={title}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <Box
                sx={{
                  background: "white",
                  border: "1px solid #7070705b",
                  height: "3vw",
                  width: "64vw",
                  borderRadius: "5px",
                  padding: "0.4vw 0.2vw",
                }}
                className="setting-toggler"
              >
                <FormControl variant="standard" fullWidth>
                  <Select
                    className={classes.select2}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={type}
                    disableUnderline
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                    onChange={handleChange3}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: "white",

                          "& .MuiMenuItem-root": {
                            padding: "0.1vw 2vw",
                            width: "100%",
                            height: "3vw",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "1vw",
                            lineHeight: "24px",
                            color: "#6b6b6b",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={() => {}} value={0} hidden>
                      Select Type
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        settype("Text");
                      }}
                      value={"Text"}
                    >
                      Text
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        settype("Number");
                      }}
                      value={"Number"}
                    >
                      Number
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        settype("Multiple Choice");
                      }}
                      value={"Multiple Choice"}
                    >
                      Multiple Choice
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        settype("Checkboxes");
                      }}
                      value={"Checkboxes"}
                    >
                      Checkboxes
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        settype("Drop Down");
                      }}
                      value={"Drop Down"}
                    >
                      Drop Down
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            {type === "Number" && (
              <div>
                <TextField
                  id="outlined-basic"
                  label="Value Multiple Ratio"
                  variant="outlined"
                  value={valueMultiple}
                  style={{ width: "100%" }}
                  type="number"
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => setvalueMultiple(e.target.value)}
                />
              </div>
            )}
            {(type === "Multiple Choice" ||
              type === "Checkboxes" ||
              type === "Drop Down") &&
              optionValues?.map((data, index) => {
                return (
                  <div
                    style={{
                      display: "flex",

                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        left: "0vw",
                        width: width > 700 ? "47%" : "100% ",
                        marginTop: "0",
                        marginLeft: "0%",
                      }}
                      className="loginfield"
                    >
                      <TextField
                        id="outlined-basic"
                        label="label"
                        variant="outlined"
                        value={data.label}
                        style={{ width: "100%" }}
                        type="text"
                        InputLabelProps={{
                          style: {
                            fontSize: width > 700 ? "1vw" : "3vw",
                            fontFamily: "Poppins",
                            fontStyle: "500",
                            fontWeight: "500",
                            color: "black",
                          },
                        }}
                        inputProps={{ className: classes.input }}
                        onChange={(e) =>
                          setOptionValues([
                            ...optionValues.slice(0, index),
                            {
                              label: e.target.value,
                              value: optionValues[index].value,
                            },
                            ...optionValues.slice(
                              index + 1,
                              optionValues.length
                            ),
                          ])
                        }
                      />
                    </div>

                    <div
                      style={{
                        left: "0vw",
                        width: width > 700 ? "47%" : "100% ",
                        marginTop: "0",
                        marginLeft: "0%",
                      }}
                      className="loginfield"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Value "
                        variant="outlined"
                        value={data.value}
                        style={{ width: "100%" }}
                        type="number"
                        InputLabelProps={{
                          style: {
                            fontSize: width > 700 ? "1vw" : "3vw",
                            fontFamily: "Poppins",
                            fontStyle: "500",
                            fontWeight: "500",
                            color: "black",
                          },
                        }}
                        inputProps={{ className: classes.input }}
                        onChange={(e) =>
                          setOptionValues([
                            ...optionValues.slice(0, index),
                            {
                              label: optionValues[index].label,
                              value: e.target.value,
                            },
                            ...optionValues.slice(
                              index + 1,
                              optionValues.length
                            ),
                          ])
                        }
                      />
                    </div>

                    <div
                      style={{
                        left: "0vw",
                        width: width > 700 ? "3%" : "10% ",
                        marginTop: "0",
                        marginLeft: "0%",
                      }}
                      className="loginfield"
                    >
                      <CloseIcon
                        onClick={() => {
                          if (optionValues.length > 1) {
                            setOptionValues([
                              ...optionValues.slice(0, index),
                              ...optionValues.slice(
                                index + 1,
                                optionValues.length
                              ),
                            ]);
                          }
                        }}
                        style={{
                          fontSize: width > 700 ? "1.5vw" : "4vw",
                          position: "relative",
                          top: "1vw",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            {(type === "Multiple Choice" ||
              type === "Checkboxes" ||
              type === "Drop Down") && (
              <div
                style={{ marginTop: "0.1vw" }}
                className="addmoreservicecatalog"
                onClick={() => {
                  setOptionValues([
                    ...optionValues,
                    { label: "", value: null },
                  ]);
                }}
              >
                <span>
                  <AddIcon
                    style={{ fontSize: width > 700 ? "1.3vw" : "4vw" }}
                  />
                </span>{" "}
                Add More Offers
              </div>
            )}

            <FormControlLabel
              label="Required"
              className="m-1"
              control={
                <Switch
                  checked={required}
                  onChange={(e) => setRequired(!required)}
                />
              }
            />
            <p
              style={{
                color: "red",
                fontSize: width > 700 ? "0.9vw" : "2.5vw",
                lineHeight: "2vw",
              }}
            >
              {errorp}
            </p>

            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose1}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={handleaddcertificate}
              >
                SAVE
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">
              {" "}
              Delete Business Valuation Form Field
            </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "2.7vw",
              fontWeight: "300",
            }}
            className="profiledetailstitle"
          >
            The action will delete this field from Business Valuation Form.
          </div>
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "2.7vw",
              fontWeight: "400",
              marginBottom: "5vw",
            }}
            className="profiledetailstitle"
          >
            Are you sure you want to delete this field
          </div>
          <hr style={{ color: "#000000" }} />
          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={deleltecatalogue}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>

      {data.feildType === "Text" && (
        <div>
          <div style={{ left: "0vw", width: "100%" }} className="loginfield">
            <TextField
              id="outlined-basic"
              label="Enter your comment"
              variant="outlined"
              multiline
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
            />
          </div>
        </div>
      )}
      {data.feildType === "Number" && (
        <div>
          <div style={{ left: "0vw", width: "100%" }} className="loginfield">
            <TextField
              id="outlined-basic"
              label="Enter your value"
              variant="outlined"
              type="number"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
            />
          </div>
        </div>
      )}

      {data.feildType === "Multiple Choice" && (
        <div class="Bformoptions">
          {data?.options?.map((data1) => {
            return (
              <label class="Bformoption">
                <input type="radio" name="capital" value={data.label} />
                {data1.label}
              </label>
            );
          })}
        </div>
      )}
      {data.feildType === "Checkboxes" && (
        <div class="Bformoptions">
          {data?.options?.map((data1) => {
            return (
              <label class="Bformoption">
                <input type="checkbox" name="capital" value={data.label} />
                {data1.label}
              </label>
            );
          })}
        </div>
      )}

      {data.feildType === "Drop Down" && (
        <div style={{ left: "0vw", width: "100%" }} className="loginfield">
          <Box
            sx={{
              background: "white",
              border: "1px solid #7070705b",
              height: "3vw",
              width: "100%",
              borderRadius: "5px",
              padding: "0.4vw 0.2vw",
            }}
            className="setting-toggler"
          >
            <FormControl variant="standard" fullWidth>
              <Select
                className={classes.select2}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                disableUnderline
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "white",

                      "& .MuiMenuItem-root": {
                        padding: "0.1vw 2vw",
                        width: "100%",
                        height: "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "1vw",
                        lineHeight: "24px",
                        color: "#6b6b6b",
                      },
                    },
                  },
                }}
                defaultValue={0}
              >
                <MenuItem onClick={() => {}} value={0} hidden>
                  Please Select
                </MenuItem>

                {data?.options?.map((data1) => {
                  return <MenuItem value={data1.label}>{data1.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
      )}
    </div>
  );
};

export default BusinessValueFormCard;
