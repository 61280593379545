import React, { useEffect, useState } from "react";
import "./Wallet.css";
import img5 from "../../../../assets/walletimage/wallet copy.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import API_HOST from "../../../../env";
import Skillpopupcopy from "./Skillpopupcopy";
import coin_symbol from '../../../../assets/wallet/coin_symbol.png'

export default function Wallet({ width, user }) {
  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };

  const [page, setPage] = useState(1);

  const [checkwallet, setCheckwallet] = useState("");
  const [walletdata, setWalletdata] = useState();

  useEffect(() => {
    handleToggle121();
    axios
      .post(
        `${API_HOST}/wallet/viewWalletbyuserName`,
        { userName: user?.userName },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setWalletdata(res?.data?.success?.data);
        handleClose121();
        setChecktransaction(!checktransaction);
      })
      .catch(() => {
        handleClose121();
      });
  }, [checkwallet,user]);

  const [checktransaction, setChecktransaction] = useState(false);
  const [transactiondata, setTransactiondata] = useState();

  useEffect(() => {
    handleToggle121();
    axios
      .post(
        `${API_HOST}/wallet/viewTransactionsByUserName`,
        { pageSize: 10, pageNumber: page, userName: user?.userName },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setTransactiondata(res?.data?.success?.data);
        handleClose121();
      })
      .catch(() => {
        handleClose121();
      });
  }, [checktransaction, page,user]);

  return (
    <div
      style={{ height: 'calc(100vh - 15vw)', paddingTop: '0vw' }}
      className='BrowseWorkMain-cntainer ScrollTable'
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: '0 60vw',
          paddingLeft: '2vw',
        }}
      >
        <div className='cardofexamstype'>
          <div className='titileofcardofexamstype'>Wallet Amount</div>
          <div
            style={{ background: '#0052cc20' }}
            className='imgsamllexamtype'
          >
            <img
              src={img5}
              alt=''
            />
          </div>
          <div className='valueofcardofexamstype'>
           
            <div
              className='valueofcardofexamstype'
              style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}
            >
             
              <div style={{ width: '18px', height: '18px' }}>
                <img
                  src={coin_symbol}
                  style={{ width: '100%', height: '100%' }}
                  alt='coin_symbol'
                />
              </div>
              <span style={{ marginTop: '0.1rem' }}>
                {walletdata?.balance?.$numberDecimal}
              </span>
            </div>{' '}
           
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: '1vw', padding: '0.5vw' }}
        className='profiledetailstitle'
      >
        Transaction List
      </div>
      <div
        style={{
          alignItems: 'center',
          padding: '0.75vw 0.5vw',
          margin: width > 700 ? '1vw 0vw' : '2vw 0vw',
          width: width > 700 ? '82vw' : '194vw',
          fontSize: '1.1vw',
        }}
        className='navoftableblogs'
      >
        <div style={{ width: '14vw' }}>Transaction </div>
        <div style={{ width: '23.5vw' }}>Transaction id</div>
        <div style={{ width: '7vw' }}>Type</div>
        <div style={{ width: '9vw' }}>Date </div>
        <div style={{ width: '9vw' }}>Amount</div>
        <div style={{ width: '10vw' }}>Status</div>
      </div>
      {transactiondata?.map((data) => {
        return (
          <Skillpopupcopy
            data={data}
            width={width}
          />
        );
      })}

      <div
        style={{ width: width > 700 ? '25vw' : '90vw' }}
        className='paginationbox'
      >
        <div>
          <ArrowBackIosIcon
            style={{ fontSize: width > 700 ? '1.5vw' : '4vw' }}
            onClick={() => {
              if (page - 1 > 0) {
                setPage(page - 1);
              }
            }}
          />
        </div>

        <div
          hidden={page - 4 > 0 ? false : true}
          onClick={() => setPage(page - 4)}
        >
          {page - 4}
        </div>
        <div
          hidden={page - 3 > 0 ? false : true}
          onClick={() => setPage(page - 3)}
        >
          {page - 3}
        </div>
        <div
          hidden={page - 2 > 0 ? false : true}
          onClick={() => setPage(page - 2)}
        >
          {page - 2}
        </div>
        <div
          hidden={page - 1 > 0 ? false : true}
          onClick={() => setPage(page - 1)}
        >
          {page - 1}
        </div>
        <div style={{ color: '#2A6599' }}>{page}</div>
        <div onClick={() => setPage(page + 1)}>{page + 1}</div>
        <div onClick={() => setPage(page + 2)}>{page + 2}</div>
        <div onClick={() => setPage(page + 3)}>{page + 3}</div>

        <div>
          <ArrowForwardIosIcon
            style={{ fontSize: width > 700 ? '1.5vw' : '4vw' }}
            onClick={() => {
              setPage(page + 1);
            }}
          />
        </div>
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open121}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}
