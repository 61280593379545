import React, { useEffect, useState } from "react";
import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";
import Skillpopupcopy from "./Skillpopupcopy";
import searchicon from "../../../assets/Dashboard/Iconly-Light-Search.svg";
export default function Withdraw({ width }) {
  const { user } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [setSelectedCategory, setSetSelectedCategory] = useState("");
  const [allusers, setAllusers] = useState([]);
  const [recall, setRecall] = useState(false);

  useEffect(() => {
    if (user?.userName) {
      axios
        .post(
          `${API_HOST}/withdrawl/allWithdrawl`,
          {
            pageSize: 12,
            pageNumber: page,
            search: setSelectedCategory,
            requester_request: "",
            checkOut_status: "",
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          setAllusers(res.data?.success?.data);
        });
    }
  }, [page, setSelectedCategory, recall, user]);

  return (
    <div style={{ paddingTop: "1vw" }} className="BrowseWorkMain-cntainer">
      <div
        style={{ alignItems: "center", justifyContent: "flex-start" }}
        className="searchboxcontainer"
      >
        <div className="serachjobbox">
          <span>
            <img
              src={searchicon}
              style={{ fontSize: width > 700 ? "1.7vw" : "5vw" }}
            />{" "}
          </span>
          <input
            type="text"
            placeholder={"search Yours Subscription"}
            value={setSelectedCategory}
            onChange={(e) => {
              setSetSelectedCategory(e.target.value);
            }}
          />
        </div>
      </div>

      <div
        style={{
          overflowX: "scroll",
          width: width > 700 ? "82vw" : "94vw",
        }}
        className="ScrollTable"
      >
        <div
          style={{
            margin: width > 700 ? "0vw 1vw" : "1vw",
            padding: "0vw 0vw",
            width: width > 700 ? "80vw" : "220vw",
            marginTop: width > 700 && "0.61vw",
          }}
          className="navoftableblogs"
        >
          <div style={{ width: width > 700 ? "15vw" : "50vw" }}>Full Name</div>

          <div style={{ width: width > 700 ? "15vw" : "30vw" }}>
            Withdraw Id
            <span
              onClick={() => {
                setSort("buyerName");
              }}
            ></span>{" "}
          </div>
          <div style={{ width: width > 700 ? "15vw" : "30vw" }}>
            Transaction Id
            <span
              onClick={() => {
                setSort("buyerName");
              }}
            ></span>{" "}
          </div>
          

          <div style={{ width: width > 700 ? "10vw" : "39vw" }}>
            Date <span></span>
          </div>
          <div style={{ width: width > 700 ? "7vw" : "39vw" }}>
            Price <span></span>
          </div>

          <div style={{ width: width > 700 ? "7vw" : "31vw" }}> Status </div>
          <div style={{ width: width > 700 ? "7vw" : "25vw" }}> </div>
        </div>
        {allusers?.length > 0 &&
          allusers?.map((data, index) => {
            return (
              <Skillpopupcopy
                width={width}
                data={data}
                index={index}
                page={page}
                setRecall={setRecall}
                recall={recall}
              />
            );
          })}

        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div>
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page - 1 > 0) {
                  setPage(page - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page - 4 > 0 ? false : true}
            onClick={() => setPage(page - 4)}
          >
            {page - 4}
          </div>
          <div
            hidden={page - 3 > 0 ? false : true}
            onClick={() => setPage(page - 3)}
          >
            {page - 3}
          </div>
          <div
            hidden={page - 2 > 0 ? false : true}
            onClick={() => setPage(page - 2)}
          >
            {page - 2}
          </div>
          <div
            hidden={page - 1 > 0 ? false : true}
            onClick={() => setPage(page - 1)}
          >
            {page - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page}</div>
          <div onClick={() => setPage(page + 1)}>{page + 1}</div>
          <div onClick={() => setPage(page + 2)}>{page + 2}</div>
          <div onClick={() => setPage(page + 3)}>{page + 3}</div>

          <div>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
