import React, { useEffect, useState } from "react";

import API_HOST from "../../../../env";
import axios from "axios";
import Profilerefrencedetail from "./Profilepopup/profilerefrencedetail";

export default function Profilerefrence({ user, width ,setRefrencesall }) {
  const [certificated, setcertificated] = useState([]);
  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/users/viewRefrence?pageSize=50&pageNumber=1&userName=${user?.userName}`
        )
        .then((res) => {
          setcertificated(res?.data?.success?.data);
          setRefrencesall(res?.data?.success?.data);
        });
    }
  }, [user]);
  return (
    <div style={{ height: "fit-content" }} className="profilebadgecontainer">
      <div
        style={{ padding: "1.5vw", margin: "0 0vw" }}
        className="profiletitleandmenunav"
      >
        <div className="profiledetailstitle">Refrence</div>
      </div>
      {certificated?.length > 0 &&
        certificated?.map((education) => {
          return (
            <Profilerefrencedetail
              education={education}
              width={width}
              setcertificated={setcertificated}
              user={user}
            />
          );
        })}
    </div>
  );
}
