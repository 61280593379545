import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import "react-tippy/dist/tippy.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import img111111 from "../../../assets/unnamed.png";
import img3 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Document.svg";
import img4 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Delete.svg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import { useNavigate } from "react-router";
const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 3,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  overflow: "scroll",
  p: 1,
};
const style4 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
export default function Skillpopupcopy({ width, data, setRecall, recall }) {
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const handledelete = () => {
    const formdata = new FormData();
    formdata.append("postId", data?.postId);
    axios
      .post(`${API_HOST}/posts/removePost`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose2();
      });
  };

  const navigate = useNavigate();

  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);

  const handleClosex = () => setOpenx(false);

  const [openx1, setOpenx1] = React.useState(false);
  const handleOpenx1 = () => setOpenx1(true);

  const handleClosex1 = () => setOpenx1(false);

  const [imagesave, setImagesave] = useState();

  return (
    <div style={{ maxWidth: "40vw" }} className="eventboxbottom">
      <div className="eventboxdate">
        <div className="eventboxdatedate">
          {" "}
          <span>
            <FiberManualRecordIcon
              style={{
                fontSize: "0.81vw",
                marginRight: "0.5vw",
                color: "#0052cc",
              }}
            />
          </span>
          {data?.startDate && (
            <span>
              {new Date(data?.startDate).getDate()}/
              {new Date(data?.startDate).getMonth() + 1}/
              {new Date(data?.startDate).getFullYear()}
            </span>
          )}
          {data?.endDate && (
            <span>
              - {new Date(data?.endDate).getDate()}/
              {new Date(data?.endDate).getMonth() + 1}/
              {new Date(data?.endDate).getFullYear()}
            </span>
          )}
          <span
            style={{
              margin: width > 700 ? "0vw 0.5vw" : "1vw 2vw",
              marginLeft: "0.5vw",

              marginBottom: "0.2vw",
              width: "fit-content",
              padding: width > 700 ? "0.2vw 0.6vw" : "0.5vw 1vw",
            }}
            className="tagblue"
          >
            {data?.typeOfPost}
          </span>
        </div>
        <div
          onClick={() => {
            data?.userName !== "admin0V89" &&
              navigate(`/dashboard/${data?.userName}/My Profile`);
          }}
          className="eventboxdatedescription"
          style={{ cursor: "pointer" }}
        >
          Posted by - {data?.user_id?.fullName}
        </div>
      </div>
      <div className="eventboxtitle">
        <div className="eventboxtitlename">{data?.title}</div>
        <div
          style={{
            width: width > 700 ? "6vw" : "10vw",
            display: "flex",
            justifyContent: "space-around",
          }}
          className="eventboxpreiew-button"
        >
          <img
            style={{
              width: width > 700 ? "1.5vw" : "4vw",
              cursor: "pointer",
              marginRight: "0.75vw",
              filter: "brightness(0)",
            }}
            onClick={() => {
              handleOpenx();
            }}
            src={img3}
            alt=""
          />

          <img
            onClick={handleOpen2}
            style={{
              width: width > 700 ? "1.5vw" : "4vw",
              cursor: "pointer",
            }}
            src={img4}
            alt=""
          />
        </div>
      </div>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Delete Your Post</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose2}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div
            style={{
              marginBottom: "0.5vw",
              marginTop: "1.3vw",
              fontSize: width > 700 ? "1.2vw" : "3.2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Are you sure want to delete this post
          </div>

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose2}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={handledelete}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style2}>
          <div
            style={{
              padding: width > 700 ? "0vw" : "1vw",
              width: "100%",
              height: "100%",
              maxHeight: "90vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="appliedjobformtitleflex"
            >
              <div>Post Detail</div>
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <hr style={{ height: "0.1vw" }} />
            <div
              style={{ margin: "1vw", fontSize: width > 700 ? "" : "2.5vw" }}
              className="activejobpistbudgetbox"
            >
              <div>
                Title <br />{" "}
                <span style={{ cursor: "pointer" }}>{data?.title}</span>
              </div>
              <div>
                Posted by <br />{" "}
                <span style={{ cursor: "pointer" }}>
                  {data?.user_id?.fullName}
                </span>
              </div>
              <div style={{ marginRight: "1vw" }}>
                Start On <br />{" "}
                {data?.startDate && (
                  <span>
                    {new Date(data?.startDate).getDate()}/
                    {new Date(data?.startDate).getMonth() + 1}/
                    {new Date(data?.startDate).getFullYear()}
                    {!data?.allDay &&
                      "," +
                        data?.startDate
                          ?.slice(11, 16)
                          .toString()
                          .padStart(2, "0")}
                  </span>
                )}
              </div>
              <div style={{ marginRight: "1vw" }}>
                End On <br />{" "}
                {data?.endDate && (
                  <span>
                    {new Date(data?.endDate).getDate()}/
                    {new Date(data?.endDate).getMonth() + 1}/
                    {new Date(data?.endDate).getFullYear()}
                    {!data?.allDay &&
                      "," +
                        data?.endDate
                          ?.slice(11, 16)
                          .toString()
                          .padStart(2, "0")}
                  </span>
                )}
              </div>
            </div>

            {data?.description && (
              <div
                style={{ color: "#0052CC", margin: "0.5vw", fontWeight: "500" }}
              >
                Description
              </div>
            )}
            <div
              style={{
                width: "100%",
                margin: "0.5vw 1vw",
                fontSize: width > 700 ? "0.85vw" : "2.5vw",
                marginBottom: "0.0vw",
                marginRight: "2vw",
                color: "black",
              }}
              className="dashboardtitilemainparabid"
            >
              <div
                className=""
                style={{ color: "black", fontSize: "0.91vw" }}
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></div>
            </div>

            {data?.files?.length > 0 && (
              <div
                style={{
                  color: "#0052CC",
                  margin: width > 700 ? "0.5vw" : "1vw",
                  fontWeight: "500",
                }}
              >
                Image / Documents
              </div>
            )}
            <div
              style={{
                margin: "1vw",
                flexWrap: "wrap",
                marginTop: "0vw",
                justifyContent: "flex-start",
              }}
              className="activejobpistbudgetbox"
            >
              {data?.files?.map((data) => {
                return (
                  <div
                    style={{ margin: width > 700 ? "" : "1.5vw" }}
                    className="boxofimageorpdf"
                  >
                    <div
                      onClick={() => {
                        if (
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "jpeg" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "png" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "svg" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "jpg" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "webp"
                        ) {
                          handleOpenx1();
                          setImagesave(data);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="imageshowboxofpdf"
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        src={
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "jpeg" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "png" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "svg" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "jpg" ||
                          data?.split(".")[data?.split(".")?.length - 1] ===
                            "webp"
                            ? data
                            : img111111
                        }
                        alt=""
                      />
                    </div>
                    <div className="imageshowboxofpdfname">
                      <div>
                        <PictureAsPdfIcon
                          style={{
                            color: "red",
                            fontSize: width > 700 ? "1.7vw" : "4.5vw",
                          }}
                        />
                      </div>
                      <div className="nameifimagedocuments">
                        {data?.split("$")[1]?.slice(0, 16)}
                      </div>
                      <div className="inputfilesshowncatboxsingleimg">
                        <a href={`${data}`} download>
                          {" "}
                          <CloudDownloadOutlinedIcon
                            style={{
                              fontSize: width > 700 ? "1.5vw" : "5vw",
                              margin: "0 1vw",
                            }}
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Modal
              open={openx1}
              onClose={handleClosex1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style4}>
                {imagesave && (
                  <div className="imgbocofcerti">
                    <img src={imagesave} alt="" />
                  </div>
                )}
              </Box>
            </Modal>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
