import React from "react";

import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

export default function Modal22({ event, timeText, width }) {
  return (
    <>
      <Tooltip
        title={event?.extendedProps?.description}
        position="bottom"
        trigger="mouseenter"
        arrow={true}
        size="small"
        style={{
          width: "100%",
        }}
      >
        <button
          position="bottom"
          trigger="mouseenter"
          arrow={true}
          style={{
            width: "100%",
            display: "flex",
            height: "2vw",
            alignItems: "center",
            fontSize: "0.75vw",
            overflowX: "scroll",
            background: event?.extendedProps?.colour + "40",
            border: "none",
            outline: "none",
            paddingRight: "0.51vw",
          }}
          className="hb-button"
        >
          {console.log(event)}
          {!event?.extendedProps?.allDay && (
            <div
              style={{
                marginRight: "0.5vw",
                color: event?.extendedProps?.colour,
              }}
            >
              {event?.extendedProps?.startDate
                ?.slice(11, 16)
                .toString()
                .padStart(2, "0") + " "}
            </div>
          )}

          <div style={{ color: event?.extendedProps?.colour }}>
            {event?.extendedProps.title}
          </div>
        </button>
      </Tooltip>
    </>
  );
}
