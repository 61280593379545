import React, { useEffect, useState } from "react";
import "./Postcreator.css";
import "react-tippy/dist/tippy.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
import imageCompression from "browser-image-compression";
import { TextField } from "@mui/material";
import img2 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";
import img3 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Document.svg";
import img4 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Delete.svg";
import img5 from "../../../assets/Dashboard/Skill center – 2/Plus.svg";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import API_HOST from "../../../env";
import { useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 3,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  overflow: "scroll",
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

export default function Upcomingevent({
  data,
  width,
  setRecall,
  recall,
  setSelecteventpost,
}) {
  const { user, loggedInStatus } = useSelector((state) => state.user);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [types, setTypes] = useState("");
  const [year1, setyear1] = useState("");
  const [arrayoffiles, setArrayoffiles] = useState([]);
  const [year2, setyear2] = useState("");
  const [wrongcallageaddname, setWrongcallageaddname] = useState(false);
  const [descriptioncallageadd, setdescriptioncallageadd] = useState("");
  const [callagename, setCallagename] = useState("");
  const [themecolr, setthemecolr] = useState("#000000");
  const [checkedtocompany, setCheckedtocompany] = useState(true);

  const [addimages, setAddimages] = useState();

  const [startdateerr, setStartdateerr] = useState(false);

  const handleCreate = () => {
    if (!callagename) {
      setWrongcallageaddname(true);
      return;
    }
    if (!year1) {
      setStartdateerr(true);
      return;
    }

    if (arrayoffiles?.length === 0) {
      setShowimageerror(true);
      return;
    }

    const formdata = new FormData();
    formdata.append("title", callagename);
    formdata.append("description", descriptioncallageadd);
    formdata.append("startDate", year1);
    year2 && formdata.append("endDate", year2);
    formdata.append("colour", themecolr);
    formdata.append("typeOfPost", types);
    formdata.append("postId", data?.postId);
    formdata.append("allDay", checkedtocompany);
    formdata.append("files", JSON?.stringify(arrayoffiles));

    axios
      .post(`${API_HOST}/posts/editPost`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose();
        setShowimageerror(false);
      });
  };

  const handleCreate1 = () => {
    const formdata = new FormData();
    formdata.append("fileName", addimages);
    formdata.append("postId", data?.postId);

    axios
      .post(`${API_HOST}/posts/fileUpload`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose1();
      });
  };

  const handledelete = () => {
    const formdata = new FormData();

    formdata.append("postId", data?.postId);

    axios
      .post(`${API_HOST}/posts/removePost`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose2();
      });
  };

  const upadteimage = (img) => {
    const formdata = new FormData();
    formdata.append("fileName", img);
    formdata.append("folderName", "postsObjects");
    axios
      ?.post(`${API_HOST}/contentManagement/tableContent`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setArrayoffiles([...arrayoffiles, res.data?.success?.data?.file]);
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log(imageFile?.type?.includes("image"));

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      setShowimageerror(false);
      //   await uploadToServer(compressedFile); // write your own logic
      await upadteimage(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }
  async function handleImageUpload1(event) {
    const imageFile = event.target.files[0];
    console.log(imageFile?.type?.includes("image"));

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await setAddimages(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }

  const [anchorElx2, setAnchorElx2] = React.useState(null);
  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };
  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;

  useEffect(() => {
    setCallagename(data?.title);
    setyear2(data?.endDate?.slice(0, 16));
    setyear1(data?.startDate?.slice(0, 16));
    setCheckedtocompany(data?.allDay);
    setthemecolr(data?.colour);
    setdescriptioncallageadd(data?.description);
    setArrayoffiles(data?.files);
    setTypes(data?.typeOfPost);
  }, [data]);
  const [showimageerror, setShowimageerror] = useState(false);

  return (
    <div className="eventboxbottom">
      <div className="eventboxdate">
        <div className="eventboxdatedate">
          {" "}
          <span>
            <FiberManualRecordIcon
              style={{
                fontSize: "0.81vw",
                marginRight: "0.5vw",
                color: "#0052cc",
              }}
            />
          </span>
          {data?.startDate && (
            <span>
              {new Date(data?.startDate).getDate()}/
              {new Date(data?.startDate).getMonth() + 1}/
              {new Date(data?.startDate).getFullYear()}
            </span>
          )}
          {data?.endDate && (
            <span>
              - {new Date(data?.endDate).getDate()}/
              {new Date(data?.endDate).getMonth() + 1}/
              {new Date(data?.endDate).getFullYear()}
            </span>
          )}
          {data?.typeOfPost && (
            <span
              style={{
                margin: width > 700 ? "0vw 0.5vw" : "1vw 2vw",
                marginLeft: "0.5vw",

                marginBottom: "0.2vw",
                width: "fit-content",
                padding: width > 700 ? "0.2vw 0.6vw" : "0.5vw 1vw",
              }}
              className="tagblue"
            >
              {data?.typeOfPost}
            </span>
          )}
        </div>
        <div className="eventboxdatedescription">
          {data?.allDay
            ? "Full day Post / Event "
            : data?.startDate && (
                <span>
                  {data?.startDate?.slice(11, 16).toString().padStart(2, "0")}
                  {data?.endDate &&
                    " To " +
                      data?.endDate
                        ?.slice(11, 16)

                        .toString()
                        .padStart(2, "0")}
                </span>
              )}
        </div>
      </div>
      <div className="eventboxtitle">
        <div className="eventboxtitlename">{data?.title}</div>
        <div
          style={{
            width: width > 700 ? "8vw" : "12vw",
            display: "flex",
            justifyContent: "space-around",
          }}
          className="eventboxpreiew-button"
        >
          {user?.userName === data?.userName ? (
            <img
              onClick={() => {
                handleOpen();
              }}
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
              }}
              src={img2}
              alt=""
            />
          ) : (
            <div
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
              }}
            ></div>
          )}

          {user?.userName === data?.userName ? (
            <img
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
                filter: "brightness(0)",
              }}
              onClick={handleOpen1}
              src={img5}
              alt=""
            />
          ) : (
            <div
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
                marginRight: "0.75vw",
              }}
            ></div>
          )}
          <img
            style={{
              width: width > 700 ? "1.5vw" : "4vw",
              cursor: "pointer",
              marginRight: "0.75vw",
              filter: "brightness(0)",
            }}
            onClick={() => {
              setSelecteventpost(data);
            }}
            src={img3}
            alt=""
          />

          {user?.userName === data?.userName ? (
            <img
              onClick={handleOpen2}
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
              }}
              src={img4}
              alt=""
            />
          ) : (
            <div
              style={{
                width: width > 700 ? "1.5vw" : "4vw",
                cursor: "pointer",
              }}
            ></div>
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Edit Post</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div style={{ left: "0vw", width: "100%" }} className="loginfield">
            <TextField
              error={!wrongcallageaddname ? false : true}
              value={callagename}
              id="outlined-basic"
              label="Post Title"
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: !wrongcallageaddname ? "black" : "#dc3545",
                },
              }}
              onChange={(e) => {
                setWrongcallageaddname(false);
                setCallagename(e.target.value);
              }}
              inputProps={{ className: classes.input }}
            />
          </div>

          <div
            style={{
              left: "0vw",
              width: width > 700 ? "100%" : "99%",
              marginLeft: "0%",
            }}
            className="loginfield"
            onClick={handleClickx2}
          >
            <TextField
              id="outlined-basic"
              label="Post Type"
              variant="outlined"
              value={types}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                  paddingRight: "2vw",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setTypes(e.target.value);
              }}
            />
            {width > 700 && (
              <span style={{ width: "0.1vw" }}>
                <KeyboardArrowDownOutlined
                  style={{
                    fontSize: "1.5vw",
                    position: "relative",
                    right: "2vw",
                    top: "1vw",
                  }}
                />
              </span>
            )}
          </div>

          <Popover
            id={idx2}
            open={openx2}
            anchorEl={anchorElx2}
            onClose={handleClosex2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                maxHeight: width > 700 ? "18vw" : "40vw",
                overflow: "scroll",
                width: width > 700 ? "46.5vw" : "90vw",
              }}
            >
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setTypes("Motivational Post");
                  setthemecolr("#0053cc");
                  handleClosex2();
                }}
              >
                Motivational Post
              </Typography>
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setTypes("Celebration Post");
                  setthemecolr("#ff836c");
                  handleClosex2();
                }}
              >
                Celebration Post
              </Typography>
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setTypes("Company Announcements");
                  setthemecolr("#b948bc");
                  handleClosex2();
                }}
              >
                Company Announcements
              </Typography>
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setTypes("Informative Post");
                  handleClosex2();
                  setthemecolr("#ff5294");
                }}
              >
                Informative Post
              </Typography>
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setTypes("Promotions and Discount Post");
                  handleClosex2();
                  setthemecolr("#ffbf57");
                }}
              >
                Promotions and Discount Post
              </Typography>
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setTypes("Customer Testimonial Post");
                  handleClosex2();
                  setthemecolr("#954500");
                }}
              >
                Customer Testimonial Post
              </Typography>
              <Typography
                sx={{
                  p: 0.51,
                  pl: 1,
                  ml: 1,
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setTypes("Product or Service Post");
                  handleClosex2();
                  setthemecolr("#109650");
                }}
              >
                Product or Service Post
              </Typography>
            </div>
          </Popover>

          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <div className="jobpodtedfieldtitile">Start Date</div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    left: "0vw",
                    width: "100%",
                    margin: "0.3vw 0",
                  }}
                  className="loginfield"
                  // onClick={handleClickx}
                >
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div className="jobpostfieldinputbox">
                        <input
                          style={{ width: "100%" }}
                          type="datetime-local"
                          className="input-homejobformdate"
                          name=""
                          id=""
                          value={year1}
                          maxlength="4"
                          onChange={(e) => {
                            setyear1(e.target.value);
                            setStartdateerr(false);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="jobpodtedfieldtitile">End Date (Excluded)</div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    left: "0vw",
                    width: "100%",
                    margin: "0.3vw 0",
                  }}
                  className="loginfield"
                  // onClick={handleClickx2}
                >
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <div className="jobpostfieldinputbox">
                        <input
                          style={{ width: "100%" }}
                          type="datetime-local"
                          className="input-homejobformdate"
                          name=""
                          id=""
                          value={year2}
                          maxlength="4"
                          onChange={(e) => {
                            setyear2(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>{" "}
            </div>
          </div>
          <div style={{ color: "red" }} className="redp">
            {startdateerr && "Please select start date"}
          </div>
          <label htmlFor="checkbox">
            <input
              type="checkbox"
              onChange={() => {
                setCheckedtocompany(!checkedtocompany);
              }}
              checked={checkedtocompany}
              name=""
              id="checkbox"
            />

            <div
              className=""
              style={{
                fontSize: width > 700 ? "1vw" : "3vw",
                fontFamily: "Poppins",
                fontStyle: "500",
                fontWeight: "500",
                color: "#000000",
                display: "inline",
                margin: "0 1vw",
              }}
            >
              set Post / Event for Full Day
            </div>
          </label>
          <div style={{ left: "0vw", width: "100%" }} className="loginfield">
            <TextField
              id="outlined-basic"
              label="Description (Optional)"
              variant="outlined"
              value={descriptioncallageadd}
              multiline
              rows="1"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setdescriptioncallageadd(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: width > 700 ? "100%" : "100%",
              border: "1px solid black",
              borderRadius: "5px",
              height: width > 700 ? "3vw" : "9vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: width > 700 ? "0.5vw" : "1.5vw",
              marginLeft: "0",
              left: "0",
              marginTop: "1.5vw",
            }}
            className="loginfield"
          >
            <div className="jobpodtedfieldtitile">
              {themecolr ? themecolr : "Choose a Theme color"}
            </div>
            <input
              id="outlined-basic"
              label="Choose a Theme color "
              variant="outlined"
              type="color"
              value={themecolr}
              style={{
                width: width > 700 ? "2vw" : "7vw",
                height: width > 700 ? "2vw" : "7vw",
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setthemecolr(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              marginBottom: "0.5vw",
              marginTop: "1.3vw",
              fontSize: width > 700 ? "1.2vw" : "3.2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Post Image
          </div>
          <div
            style={{
              background: "white",
              padding: "0.51vw",
              marginTop: "0vw",
            }}
          >
            <div style={{ height: "8vw" }} className="inputfilebox">
              <div>
                <label htmlFor="inputctaelogfile">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div
            className={arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""}
          >
            {arrayoffiles?.length > 0 &&
              arrayoffiles?.map((file, index) => {
                return (
                  <div className="inputfilesshowncatboxsingle">
                    <div className="inputfilesshowncatboxsingleimg">
                      <img src={img1} alt="" />
                    </div>
                    <div className="fileselctednamecate">
                      {file?.split("$")[1]}
                    </div>
                    <div className="inputfilesshowncatboxsingleimg">
                      <img
                        style={{
                          width: width > 700 ? "1.2vw" : "4vw",
                          marginLeft: "1vw",
                          cursor: "pointer",
                        }}
                        src={img4}
                        alt=""
                        onClick={() => {
                          setArrayoffiles([
                            ...arrayoffiles.slice(0, index),
                            ...arrayoffiles.slice(
                              index + 1,
                              arrayoffiles.length
                            ),
                          ]);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          {showimageerror && (
            <p
              className="redp"
              style={{
                color: "red",
                fontSize: width > 700 ? "0.91vw" : "2.5vw",
              }}
            >
              {" "}
              Add Post image for create a Post !
            </p>
          )}
          {/* <hr style={{ color: "#000000" }} /> */}

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={handleCreate}
            >
              Update
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Add Post Images</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose1}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div
            style={{
              marginBottom: "0.5vw",
              marginTop: "1.3vw",
              fontSize: width > 700 ? "1.2vw" : "3.2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Select Image
          </div>
          <div
            style={{
              background: "white",
              padding: "0.51vw",
              marginTop: "0vw",
            }}
          >
            <div style={{ height: "8vw" }} className="inputfilebox">
              <div>
                <label htmlFor="inputctaelogfile">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile"
                    onChange={(e) => {
                      handleImageUpload1(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <div className={addimages > 0 ? "inputfilesshowncatebox" : ""}>
            {addimages && (
              <div className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img src={img1} alt="" />
                </div>
                <div className="fileselctednamecate">{addimages?.name}</div>
                <div className="inputfilesshowncatboxsingleimg">
                  <img
                    style={{
                      width: width > 700 ? "1.5vw" : "4vw",
                      marginLeft: "1vw",
                      cursor: "pointer",
                    }}
                    src={img1}
                    alt=""
                    onClick={() => {
                      setAddimages();
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {/* <hr style={{ color: "#000000" }} /> */}

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose1}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={handleCreate1}
            >
              Add
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Delete Your Post</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose2}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div
            style={{
              marginBottom: "0.5vw",
              marginTop: "1.3vw",
              fontSize: width > 700 ? "1.2vw" : "3.2vw",
            }}
            className="jobpodtedfieldtitile"
          >
            Are you sure want to delete this post
          </div>

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose2}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={handledelete}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
