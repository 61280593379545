import React, { useEffect, useState } from "react";

import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { makeStyles } from "@material-ui/core";

import { useSelector } from "react-redux";
import Skillpopupcopy from "./Skillpopupcopy";

import searchicon from "../../../assets/Dashboard/Iconly-Light-Search.svg";
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

export default function AllPost({ width }) {
  const { user } = useSelector((state) => state.user);

  const [page, setPage] = useState(1);

  const [setSelectedCategory, setSetSelectedCategory] = useState("");

  const [allusers, setAllusers] = useState([]);
  const [recall, setRecall] = useState(false);
  const [setdatefilter, setsetdatefilter] = useState("");

  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/posts/adminPostsView?pageSize=14&pageNumber=${page}&search=${setSelectedCategory}${
            setdatefilter && "&from=" + setdatefilter
          }`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          setAllusers(res.data?.success?.data);
        });
    }
  }, [page, setSelectedCategory, setdatefilter, recall, user]);

  return (
    <div style={{ paddingTop: "1vw" }} className="BrowseWorkMain-cntainer">
      <div
        style={{ alignItems: "center", justifyContent: "flex-start" }}
        className="searchboxcontainer"
      >
        <div className="serachjobbox">
          <span>
            <img
              src={searchicon}
              style={{ fontSize: width > 700 ? "1.7vw" : "5vw" }}
            />{" "}
          </span>
          <input
            type="text"
            placeholder={"search Posts"}
            value={setSelectedCategory}
            onChange={(e) => {
              setSetSelectedCategory(e.target.value);
            }}
          />
        </div>
        <div className="serachjobbox">
          <input
            type="date"
            placeholder={"Select Upcoming Date"}
            value={setdatefilter}
            onChange={(e) => {
              setsetdatefilter(e.target.value);
            }}
          />
        </div>

        <div
          onClick={() => {
            setSetSelectedCategory("");
            setsetdatefilter("");

            setRecall(!recall);
          }}
          style={{ cursor: "pointer" }}
          className="filtericonboxname"
        >
          Clear all
        </div>
      </div>

      <div
        style={{
          overflowX: "scroll",
          width: width > 700 ? "82vw" : "94vw",
        }}
        className="ScrollTable"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {allusers?.length > 0 &&
            allusers?.map((data, index) => {
              return (
                <Skillpopupcopy
                  width={width}
                  data={data}
                  index={index}
                  page={page}
                  setRecall={setRecall}
                  recall={recall}
                />
              );
            })}
        </div>

        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div>
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page - 1 > 0) {
                  setPage(page - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page - 4 > 0 ? false : true}
            onClick={() => setPage(page - 4)}
          >
            {page - 4}
          </div>
          <div
            hidden={page - 3 > 0 ? false : true}
            onClick={() => setPage(page - 3)}
          >
            {page - 3}
          </div>
          <div
            hidden={page - 2 > 0 ? false : true}
            onClick={() => setPage(page - 2)}
          >
            {page - 2}
          </div>
          <div
            hidden={page - 1 > 0 ? false : true}
            onClick={() => setPage(page - 1)}
          >
            {page - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page}</div>
          <div onClick={() => setPage(page + 1)}>{page + 1}</div>
          <div onClick={() => setPage(page + 2)}>{page + 2}</div>
          <div onClick={() => setPage(page + 3)}>{page + 3}</div>

          <div>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
