import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import API_HOST from "../../../env";
import CloseIcon from "@mui/icons-material/Close";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import img10 from "../../../assets/User Dashboard After Login - Apply work Description/Iconly-Light-Calendar.svg";
import img11 from "../../../assets/User Dashboard After Login - Apply work Description/Iconly-Light-Chart.svg";
import img2 from "../../../assets/User Dashboard After Login - Apply work Description/Iconly-Light-Profile.svg";
import img3 from "../../../assets/User Dashboard After Login - Apply work Description/verified_black_24dp.svg";
import img41 from "../../../assets/User Dashboard After Login - Apply work Description/Wallet.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Listofproposals from "./Listofproposals";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    input: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "0.91vw",
      color: "#263238",
      border: "yellow !important",
    },
  }));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: "2vw",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 1,
  };
export default function Finacedetail({ width }) {
  const [down1, setDown1] = useState(false);

  const { id } = useParams();
  const [finnacelenderdata, setFinnacelenderdata] = useState();
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });

    axios
      ?.get(`${API_HOST}/lender/a_id?financerId=${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setFinnacelenderdata(res?.data?.success?.data[0]);
      });
  }, [id]);
  const navigate = useNavigate();

  const handleapprov = () => {
    axios
      ?.post(
        `${API_HOST}/lender/adminAction`,
        { financerId: finnacelenderdata?.financerId },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setFinnacelenderdata(res?.data?.success?.data);
      });
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [dateerror, setdateerror] = useState("");
  const [datestart, setDatestart] = useState();
  const [dateend, setDateend] = useState();
  const handleexport = () => {
    axios
      .post(
        `${API_HOST}/lender/exportwithDatesViaLender`,
        {
          to: dateend,
          from: datestart,
          finacer_id:finnacelenderdata?._id
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        window.location.href = response.data?.success?.data;
        handleClose1();
      });
  };

  const handleexport1 = () => {
    axios
      .post(
        `${API_HOST}/lender/exportAllApllicantViaLender`,
        {finacer_id:finnacelenderdata?._id},
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        window.location.href = response.data?.success?.data;
        handleClose1();
      });
  };
  return (
    <div
      style={{
        height: width > 700 ? "calc(100vh - 4vw)" : "calc(100vh - 14vw)",
      }}
      className="ScrollTable"
    >
      <div
        style={{
          width: "100%",
          background: "white",
          padding: width > 700 ? "1vw 1vw" : "2vw",
          margin: "1vw 0vw",
          paddingBottom: width > 700 ? "3vw" : "5vw",
          marginBottom: width <= 700 && "10vw",
        }}
      >
        <button
          style={{
            cursor: "pointer",
            zIndex: "100",
            padding: "0.5vw 0.7vw",
            backgroundColor: "white",
            color: "#000",
            fontSize: "1.2vw",
            borderRadius: "0.3vw",
            border: "1px solid #d7d7d7",
            marginBottom: width > 700 ? "1vw" : "3vw",
            marginTop: "0vw",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
        </button>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "0vw" }} className="tagblue">
              {finnacelenderdata?.organizerName_bankName}
            </div>
          </div>
          <div style={{ display: "flex" }}>
         { !finnacelenderdata?.approved &&  <div
              style={{
                margin: "0",
                height: width > 700 ? "2.4vw" : "8vw",
                background: "#E4E4E4",
                fontSize: "400",
                marginRight: width > 700 ? "1vw" : "3vw",
              }}
              onClick={handleapprov}
              className="digitalwallate"
            >
              <span
                style={{
                  padding: "0.6vw 0.5vw",
                  fontSize: "400",
                  background: "none",
                }}
              >
                Approve
              </span>
            </div>}
            <div
              style={{
                margin: "0",
                height: width > 700 ? "2.4vw" : "8vw",
                background: "#E4E4E4",
                fontSize: "400",
                marginRight: width > 700 ? "1vw" : "3vw",
              }}
              onClick={()=>handleOpen1()}
              className="digitalwallate"
            >
              <span
                style={{
                  padding: "0.6vw 0.5vw",
                  fontSize: "400",
                  background: "none",
                }}
              >
                Export Application
              </span>
            </div>
            <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div
              style={{
                maxHeight: "80vh",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">Add Filters</div>
                <div className="profiledetailnavmanu">
                  <div>
                    <CloseIcon
                      onClick={handleClose1}
                      style={{
                        fontSize: width > 700 ? "1.5vw" : "4vw",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: "#000000" }} />
              <div className="jobpodtedfieldtitile"> Select Dates </div>

              <div
                style={{ display: "flex", alignItems: "center", width: "97%" }}
              >
                <div style={{ width: "50%" }}>
                  <div className="jobpodtedfieldtitile">From</div>
                  <div className="jobpostfieldinputbox">
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={datestart}
                      className="input-homejobformdate"
                      name=""
                      id=""
                      maxlength="4"
                      onChange={(e) => {
                        setDatestart(e.target.value);
                        setdateerror("");
                      }}
                    />
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <div className="jobpodtedfieldtitile">To</div>

                  <div className="jobpostfieldinputbox">
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={dateend}
                      className="input-homejobformdate"
                      name=""
                      id=""
                      maxlength="4"
                      onChange={(e) => {
                        setDateend(e.target.value);
                        setdateerror("");
                      }}
                    />
                  </div>
                </div>
              </div>
              <p style={{ color: "red" }} className="redp">
                {dateerror}
              </p>

              <div
                style={{ marginTop: "0.31vw" }}
                className="handlemoreaboutskill"
              >
                <div
                  style={{
                    cursor: "pointer",
                    marginRight: "2vw",
                    width: width > 700 ? "13vw" : "26vw",
                  }}
                  className="handlecirclieaboutsave"
                  onClick={() => {
                    handleexport1();
                  }}
                >
                  Export All Data
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="handlecirclieaboutsave"
                  onClick={() => {
                    if (!datestart || !dateend) {
                      setdateerror("Please Enter Date");
                    } else {
                      handleexport();
                    }
                  }}
                >
                  Export
                </div>
              </div>
            </div>
          </Box>
        </Modal>
 
          </div>
        </div>
        <div
          style={{
            fontWeight: "500",
            fontSize: width > 700 ? "1.3vw" : "3.5vw",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "1vw",
            marginLeft: "1vw",
            color: "black",
            justifyContent: "space-between",
          }}
        >
          <div>{finnacelenderdata?.title}</div>

          <div>
            Status :{" "}
            <span
              style={{
                color: finnacelenderdata?.approved ? "green" : "red",
                marginRight: "1vw",
              }}
            >
              {finnacelenderdata?.approved
                ? "Approved"
                : finnacelenderdata?.published
                ? "Published"
                : "Pending"}
            </span>
          </div>
        </div>
        <div
          style={{
            width: "fit-content",
            margin: "0.8vw 1vw",
            fontSize: width > 700 ? "1vw" : "2.5vw",
            marginBottom: "0.0vw",
            marginRight: "5vw",
            color: "black",
          }}
          className="dashboardtitilemainparabid"
        >
          <div
            className=""
            style={{ color: "black" }}
            dangerouslySetInnerHTML={{
              __html: finnacelenderdata?.description,
            }}
          ></div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              fontSize: width > 700 ? "1.1vw" : "3.3vw",
              fontWeight: "500",
              margin: "1vw",
            }}
          >
            Proposal Details
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            margin: "1vw 2vw",
            marginTop: "0vw",
            flexWrap: width <= 700 && "wrap",
          }}
        >
          <div className="detailjobposttags">
            <div
              style={{
                margin: "0.5vw",
                color: "#0052CC",
                marginBottom: "0.40vw",
                fontSize: width > 700 ? "1vw" : "3vw",
                fontWeight: "500",
              }}
            >
              Posted by
            </div>
            <div
              style={{
                display: "block",
                height: "fit-content",
                paddingBottom: width > 700 ? "0.3vw" : "1vw",
                paddingLeft: width > 700 ? "0.61vw" : "1vw",
                lineHeight: width > 700 ? "2.7vw" : "6vw",
                cursor: "pointer",
              }}
              className="tagvaluejobpostbox"
              onClick={() => {
                navigate(
                  `/dashboard/profile/${finnacelenderdata?.user_id?.userName}`
                );
              }}
            >
              <img
                style={{
                  width: width > 700 ? "1.5vw" : "4vw",
                  margin: "0 0.1vw",
                  borderRadius: "50%",
                }}
                src={finnacelenderdata?.user_id?.media}
                alt=""
              />{" "}
              {finnacelenderdata?.postedByName?.slice(0, 18)}
            </div>
          </div>

          <div className="detailjobposttags">
            <div
              style={{
                margin: "0.5vw",
                color: "#0052CC",
                marginBottom: "0.40vw",
                fontSize: width > 700 ? "1vw" : "3vw",
                fontWeight: "500",
              }}
            >
              Limit
            </div>
            <div className="tagvaluejobpostbox">
              <span>
                <img
                  style={{
                    margin: width > 700 ? "0.6vw" : "1.2vw",
                    width: width > 700 ? "1.5vw" : "4vw",
                    objectFit: "contain",
                  }}
                  src={img41}
                  alt=""
                />
              </span>
              Rs. {finnacelenderdata?.finaceLimit}
            </div>
          </div>

          <div className="detailjobposttags">
            <div
              style={{
                margin: "0.5vw",
                color: "#0052CC",
                marginBottom: "0.40vw",
                fontSize: width > 700 ? "1vw" : "3vw",
                fontWeight: "500",
              }}
            >
            Date of Post
            </div>
            <div className="tagvaluejobpostbox">
              <span>
                <img
                  style={{
                    margin: width > 700 ? "0.6vw" : "1.2vw",
                    width: width > 700 ? "1.5vw" : "4vw",
                    objectFit: "contain",
                  }}
                  src={img10}
                  alt=""
                />
              </span>
              {finnacelenderdata?.created_at && (
                <span>
                  {new Date(finnacelenderdata?.created_at).getDate()}/
                  {new Date(finnacelenderdata?.created_at).getMonth() + 1}/
                  {new Date(finnacelenderdata?.created_at).getFullYear()}
                </span>
              )}
            </div>
          </div>

          <div className="detailjobposttags">
            <div
              style={{
                margin: "0.5vw 0vw",
                color: "#0052CC",
                marginBottom: "0.40vw",
                fontSize: width > 700 ? "1vw" : "3vw",
                fontWeight: "500",
              }}
            >
              Intrest Rate
            </div>
            <div className="tagvaluejobpostbox">
              <span>
                <img
                  style={{
                    margin: width > 700 ? "0.6vw" : "1.2vw",
                    width: width > 700 ? "1.5vw" : "4vw",
                    objectFit: "contain",
                  }}
                  src={img11}
                  alt=""
                />
              </span>
              {finnacelenderdata?.intrestRate} %
            </div>
          </div>

          <div className="detailjobposttags">
            <div
              style={{
                margin: "0.5vw 0vw",
                color: "#0052CC",
                marginBottom: "0.40vw",
                fontSize: width > 700 ? "1vw" : "3vw",
                fontWeight: "500",
              }}
            >
              No of Application
            </div>
            <div className="tagvaluejobpostbox">
              <span>
                <img
                  style={{
                    margin: width > 700 ? "0.6vw" : "1.2vw",
                    width: width > 700 ? "1.5vw" : "4vw",
                    objectFit: "contain",
                  }}
                  src={img2}
                  alt=""
                />
              </span>
              {finnacelenderdata?.loanApplicatnt?.length || 0}
            </div>
          </div>
        </div>
        <div
          style={{
            height: down1
              ? `${
                finnacelenderdata?.loanApplicatnt?.length *
                  (width > 700 ? 4 : 12) + (width > 700 ? 7 : 12)
                }vw`
              : "",
          }}
          className="boxofextension"
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDown1(!down1);
            }}
            className="flexofboxextentionnav"
          >
            <div style={{ color: down1 ? "#0052CC" : "", marginLeft: "0.5vw" }}>
              List of Proposal ( {finnacelenderdata?.loanApplicatnt?.length} )
            </div>
            <div>
              {!down1 ? (
                <KeyboardArrowDownIcon
                  style={{
                    fontSize: width > 700 ? "2vw" : "5vw",
                    margin: "1vw",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  style={{
                    fontSize: width > 700 ? "2vw" : "5vw ",
                    margin: "1vw",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          </div>
          <div hidden={!down1}>
            <div>
              <div
                style={{
                  margin: "0.5vw 1vw",
                  padding: "0vw 1vw",
                  width: width > 700 ? "80vw" : "192vw",
                  marginTop: width > 700 && "0.01vw",
                }}
                className="navoftableblogs"
              >
                <div style={{ width: width > 700 ? "16vw" : "30vw" }}>
                  Loan Purpose
                </div>
                <div style={{ width: width > 700 ? "12vw" : "50vw" }}>
                  Loan type
                </div>
                <div style={{ width: width > 700 ? "15vw" : "30vw" }}>
                  Loan Term
                </div>

                <div style={{ width: width > 700 ? "15vw" : "40vw" }}>
                  Profession
                </div>

                <div style={{ width: width > 700 ? "8vw" : "30vw" }}>Date</div>

                <div style={{ width: width > 700 ? "9vw" : "15vw" }}>
                  {" "}
                  Status{" "}
                </div>
              </div>
            </div>
            {finnacelenderdata?.loanApplicatnt?.map((data) => {
              return (
                <Listofproposals
                  width={width}
                  data={data}
                  finnacelenderdata={finnacelenderdata}
                />
              );
            })}
          </div>
        </div>
   
      </div>
    </div>
  );
}
