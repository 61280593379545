import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import API_HOST from "../../../env";
import axios from "axios";

import { useLocation, useNavigate, useParams } from "react-router";

import { Backdrop, CircularProgress } from "@material-ui/core";

export default function Connectiondetail({ width }) {
  const [data, setdata] = useState();
  const { id } = useParams();

  const [open121, setOpen121] = React.useState(false);
  const handleClose121 = () => {
    setOpen121(false);
  };
  const handleToggle121 = () => {
    setOpen121(!open121);
  };

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_HOST}/connection/byId?connectionId=${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setdata(res?.data?.success?.data);
      });
  }, [id]);

  const location = useLocation();
  const url = new URLSearchParams(location.search);

  return (
    <div
      style={{
        height: width > 700 ? "calc(100vh - 4vw )" : "calc(100vh - 14vw )",
      }}
      className="ScrollTable"
    >
      <div
        style={{
          width: "100%",
          background: "white",
          padding: width > 700 ? "1vw 1vw" : "2vw",
          margin: "0vw",
        }}
      >
        <button
          style={{
            cursor: "pointer",
            zIndex: "100",
            padding: "0.5vw 0.7vw",
            backgroundColor: "white",
            color: "#000",
            fontSize: width > 700 ? "1.2vw" : "3.3vw",
            borderRadius: "0.3vw",
            border: "1px solid #d7d7d7",
            marginBottom: width > 700 ? "1vw" : "3vw",
            marginTop: "0vw",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
        </button>

        <div
          style={{
            padding: width > 700 ? "1vw" : "2vw",
            boxShadow:
              "-7px -7px 15px #e9e9e9, 10px 10px 20px rgba(124, 124, 124, 0.4)",
            borderRadius: "1vw",
            marginLeft: "1vw",
            marginBottom: width <= 700 && "5vw",
            marginTop: "0.5vw",
            width: width > 700 ? "80vw" : "94vw",
          }}
          className="websiteoffercontainer "
        >
          <div
            style={{
              marginTop: "0vw",
              display: "flex",
              justifyContent: "flex-start",
              flexBasis: "50%",
            }}
            className="offerheading"
          >
            <span style={{ flexBasis: "50%" }}> Client Detail</span>
            <span style={{ flexBasis: "50%" }}>Freelancer Detail</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: width <= 700 && "column-reverse",
            }}
          >
            <div
              style={{ width: width > 700 ? "50%" : "100%" }}
              className="orderdtailsinfoaddress"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "99%" : "100%" }}
                    htmlFor="jj"
                  >
                    <div>Fullname</div>
                    <div
                      onClick={() => {
                        navigate(
                          `/dashboard/${data?.user_id?.userName}/My Profile`
                        );
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span>
                        <img
                          style={{
                            height: "fit-content",
                            width: width > 700 ? "1.4vw" : "5vw",
                            margin: "0vw",
                            marginRight: "0.2vw",
                            objectFit: "contain",
                            borderRadius: "50%",
                          }}
                          src={data?.user_id?.media}
                          alt=""
                        />
                      </span>
                      <input
                        value={data?.user_id?.fullName}
                        id="jj"
                        disabled
                        className="websiteaddresdetailinput"
                        style={{
                          fontSize: width > 700 ? "1vw" : "3vw",
                          width: width > 700 ? "22vw" : "100%",
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                      ></input>
                    </div>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "99%" : "100%" }}
                    htmlFor="jj"
                  >
                    <div>Mobile</div>
                    <input
                      value={data?.user_id?.contactNo}
                      id="jj"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "100%" : "100%",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "99%" : "100%" }}
                    htmlFor="ccca"
                  >
                    <div>Email Id </div>
                    <input
                      className="websiteaddresdetailinput"
                      id="ccca"
                      disabled
                      value={data?.user_id?.emailId}
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "100%" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ddda">
                    <div>Title</div>
                    <input
                      value={data?.user_id?.title}
                      id="ddda"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "100%" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div
                style={{
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  margin: "0.3vw 0vw",
                }}
                className="offerheading"
              >
                <label style={{ width: "100%" }} htmlFor="kk">
                  <div style={{ width: "100%" }}> Location </div>
                  <textarea
                    id="kk"
                    className="websiteaddresdetailinput"
                    value={data?.user_id?.address}
                    rows={2}
                    disabled
                    style={{
                      width: width > 700 ? "100%" : "90vw",
                      fontWeight: "400",
                      fontSize: width > 700 ? "1" : "3vw",
                      lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                    }}
                  ></textarea>
                </label>
              </div>
            </div>

            <div
              style={{ width: width > 700 ? "50%" : "100%" }}
              className="orderdtailsinfoaddress"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "99%" : "100%" }}
                    htmlFor="jj"
                  >
                    <div>Fullname</div>
                    <div
                      onClick={() => {
                        navigate(
                          `/dashboard/${data?.clientUser_id?.userName}/My Profile`
                        );
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span>
                        <img
                          style={{
                            height: "fit-content",
                            width: width > 700 ? "1.4vw" : "5vw",
                            margin: "0vw",
                            marginRight: "0.2vw",
                            objectFit: "contain",
                            borderRadius: "50%",
                          }}
                          src={data?.clientUser_id?.media}
                          alt=""
                        />
                      </span>
                      <input
                        value={data?.clientUser_id?.fullName}
                        id="jj"
                        disabled
                        className="websiteaddresdetailinput"
                        style={{
                          fontSize: width > 700 ? "1vw" : "3vw",
                          width: width > 700 ? "22vw" : "100%",
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                      ></input>
                    </div>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "99%" : "100%" }}
                    htmlFor="jj"
                  >
                    <div>Mobile</div>
                    <input
                      value={data?.clientUser_id?.contactNo}
                      id="jj"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "100%" : "100%",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "99%" : "100%" }}
                    htmlFor="ccca"
                  >
                    <div>Email Id </div>
                    <input
                      className="websiteaddresdetailinput"
                      id="ccca"
                      disabled
                      value={data?.clientUser_id?.emailId}
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "100%" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw 0vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "99%" : "100%" }}
                    htmlFor="ddda"
                  >
                    <div>Title</div>
                    <input
                      value={data?.clientUser_id?.title}
                      id="ddda"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "100%" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div
                style={{
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  margin: "0.3vw 0vw",
                }}
                className="offerheading"
              >
                <label style={{ width: "100%" }} htmlFor="kk">
                  <div style={{ width: "100%" }}> Location </div>
                  <textarea
                    id="kk"
                    className="websiteaddresdetailinput"
                    value={data?.clientUser_id?.address}
                    rows={2}
                    disabled
                    style={{
                      width: width > 700 ? "100%" : "90vw",
                      fontWeight: "400",
                      fontSize: width > 700 ? "1" : "3vw",
                      lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                    }}
                  ></textarea>
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              width: width > 700 ? "76vw" : "90vw",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                cursor: "pointer",
                fontSize: width > 700 ? "1vw" : "3vw",
                lineHeight: width > 700 ? "1.3vw" : "3.5vw",
              }}
              className="notificationname"
            >
              <span style={{ fontWeight: "500", marginRight: "0.5vw" }}>
                Purpose:{" "}
              </span>{" "}
              {data?.purpose}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open121}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
