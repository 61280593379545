import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import imgfilter from "../../../assets/walletimage/Iconly-Light-Color-Filter.svg";
import Modal from "@mui/material/Modal";
import searchicon from "../../../assets/Dashboard/Iconly-Light-Search.svg";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ProfileCatelogdetails from "./ProfileCatelogDetails";

import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",

    color: "#263238",
    border: "yellow !important",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: "2vw",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 1,
};

export default function Finlender({ width }) {
  const { user } = useSelector((state) => state.user);
  const [workhistorytoggle, setWorkhistorytoggle] = useState(1);

  const [page, setPage] = useState(1);

  const [setSelectedCategory, setSetSelectedCategory] = useState("");



  const [allusers, setAllusers] = useState([]);

  const [recall, setRecall] = useState(false);

  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/lender/adminView?pageSize=9&pageNumber=${page}${
            setSelectedCategory ? `&search=${setSelectedCategory}` : ""
          }
          `,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          setAllusers(res.data?.success?.data);
        });
    }
  }, [page, setSelectedCategory, recall, user]);


  const navigate = useNavigate();
  return (
    <div className="BrowseWorkMain-cntainer">
     
      <div
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          height: width > 700 ? "4vw" : "10vw",
          marginBottom: width > 700 ? "0.51vw":"1vw",
        }}
        className="searchboxcontainer"
      >
        <div className="serachjobbox">
          <span>
            <img
              src={searchicon}
              style={{ width: width > 700 ? "1.7vw" : "5vw" }}
              alt=""
              alt=""
            />
          </span>
          <input
            type="text"
            placeholder={"search here ..."}
            value={setSelectedCategory}
            onChange={(e) => {
              setSetSelectedCategory(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            width: width > 700 ? "40%" : "15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flexWrap: "wrap",
              marginTop: width > 700 ? "0vw" : "1vw",
              marginBottom: "0vw",
              paddingBottom: "0vw",
              paddingTop: "0vw",
            }}
            className="filterboxflex"
          ></div>
        </div>

    
      </div>

      <div className="ScrollTable">
        {workhistorytoggle === 1 ? (
          <>
            <div className="catalogcontainerdashbaord">
              {allusers?.length > 0 ? (
                allusers?.map((data, index) => {
                  return (
                    <ProfileCatelogdetails
                      catalogue={data}
                      width={width}
                      recall={recall}
                      setRecall={setRecall}
                
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    textAlign: "center",
                    width: "100%",
                    marginTop: "2vw",
                  }}
                >
                  There are no Cataogues similar to your search .{" "}
                </div>
              )}
            </div>

            <div
              style={{ width: width > 700 ? "25vw" : "90vw" }}
              className="paginationbox"
            >
              <div>
                <ArrowBackIosIcon
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  onClick={() => {
                    if (page - 1 > 0) {
                      setPage(page - 1);
                    }
                  }}
                />
              </div>

              <div
                hidden={page - 4 > 0 ? false : true}
                onClick={() => setPage(page - 4)}
              >
                {page - 4}
              </div>
              <div
                hidden={page - 3 > 0 ? false : true}
                onClick={() => setPage(page - 3)}
              >
                {page - 3}
              </div>
              <div
                hidden={page - 2 > 0 ? false : true}
                onClick={() => setPage(page - 2)}
              >
                {page - 2}
              </div>
              <div
                hidden={page - 1 > 0 ? false : true}
                onClick={() => setPage(page - 1)}
              >
                {page - 1}
              </div>
              <div style={{ color: "#2A6599" }}>{page}</div>
              <div onClick={() => setPage(page + 1)}>{page + 1}</div>
              <div onClick={() => setPage(page + 2)}>{page + 2}</div>
              <div onClick={() => setPage(page + 3)}>{page + 3}</div>

              <div>
                <ArrowForwardIosIcon
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
