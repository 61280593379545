import axios from 'axios';
import React, { useEffect, useState } from 'react';
import API_HOST from '../../../../env';

import Companydetails from './Companydetails';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SearchSharp } from '@mui/icons-material';

export default function Company({ userName }) {
  const [showcompanydetails, setShowcompanydetails] = useState(false);

  const [companydetail, setCompanydetail] = useState([]);
  const [recall, setRecall] = useState();
  const [page, setPage] = useState(1);

  const [setSelectedCategory, setSetSelectedCategory] = useState('');

  const [sort, setSort] = useState('created_at');
  const width = 900;
  useEffect(() => {
    axios
      .get(
        `${API_HOST}/companyDetails/adminView?userName=${userName}&pageNumber=${page}&pageSize=10&search=${setSelectedCategory}`,
        {
          headers: {
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('token')),
          },
        }
      )
      .then((res) => {
        console.log(res?.data?.success?.data);
        setCompanydetail(res?.data?.success?.data);
      });
  }, [recall, page, setSelectedCategory]);
  return (
    <div>
      {!showcompanydetails && (
        <div>
          <div
            style={{
              color: 'black',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              width: width > 700 ? '96%' : '90%',
              margin: 'auto',
            }}
            className='profileworkhistruytoggleervalue'
          >
            <div>List Of company details</div>
            <div
              style={{ left: '0vw', width: '34vw', marginLeft: '0%' }}
              className='serachjobbox'
            >
              <span>
                <SearchSharp style={{ fontSize: '1.7vw' }} />
              </span>
              <input
                type='text'
                placeholder='search Company'
                value={setSelectedCategory}
                onChange={(e) => {
                  setSetSelectedCategory(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='flexbanakcards'>
            {companydetail?.map((data) => {
              return (
                <div
                  style={{
                    marginLeft: width < 700 && '4.5vw',
                    height: 'fit-content',
                    cursor: 'pointer',
                  }}
                  className='bankcardContainer'
                  onClick={() => {
                    setShowcompanydetails(data);
                  }}
                >
                  <div
                    style={{ marginBottom: '1vw' }}
                    className='titleboxflexofbank'
                  >
                    <div>Company Detail</div>
                  </div>

                  <div className='flexofbankdetails'>
                    <div className='titleboxflexofbank'>Company Name</div>
                    <div
                      style={{ fontWeight: '400' }}
                      className='titleboxflexofbank'
                    >
                      {data?.companyName}
                    </div>
                  </div>

                  <div className='flexofbankdetails'>
                    <div className='titleboxflexofbank'>Email</div>
                    <div
                      style={{ fontWeight: '400' }}
                      className='titleboxflexofbank'
                    >
                      {data?.emailId}
                    </div>
                  </div>
                  <div className='flexofbankdetails'>
                    <div className='titleboxflexofbank'>Gst No</div>
                    <div
                      style={{ fontWeight: '400' }}
                      className='titleboxflexofbank'
                    >
                      {data?.gstNo}
                    </div>
                  </div>
                  <div className='flexofbankdetails'>
                    <div className='titleboxflexofbank'>Mobile</div>
                    <div
                      style={{ fontWeight: '400' }}
                      className='titleboxflexofbank'
                    >
                      {data?.mobile}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

         
            <div
              style={{ width: '25vw' }}
              className='paginationbox'
            >
              <div
                onClick={() => {
                  if (page > 1) {
                    setPage(page - 1);
                  }
                }}
              >
                <ArrowBackIosIcon
                  style={{ fontSize: '1.5vw' }}
                  onClick={() => {
                    if (page - 1 > 0) {
                      setPage(page - 1);
                    }
                  }}
                />
              </div>

              <div
                hidden={page - 4 > 0 ? false : true}
                onClick={() => setPage(page - 4)}
              >
                {page - 4}
              </div>
              <div
                hidden={page - 3 > 0 ? false : true}
                onClick={() => setPage(page - 3)}
              >
                {page - 3}
              </div>
              <div
                hidden={page - 2 > 0 ? false : true}
                onClick={() => setPage(page - 2)}
              >
                {page - 2}
              </div>
              <div
                hidden={page - 1 > 0 ? false : true}
                onClick={() => setPage(page - 1)}
              >
                {page - 1}
              </div>
              <div style={{ color: '#2A6599' }}>{page}</div>
              <div onClick={() => setPage(page + 1)}>{page + 1}</div>
              <div onClick={() => setPage(page + 2)}>{page + 2}</div>
              <div onClick={() => setPage(page + 3)}>{page + 3}</div>

              <div onClick={() => setPage(page + 1)}>
                <ArrowForwardIosIcon
                  style={{ fontSize: '1.5vw' }}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                />
              </div>
            </div>
          
        </div>
      )}
      {showcompanydetails && (
        <Companydetails
          showcompanydetails={showcompanydetails}
          setShowcompanydetails={setShowcompanydetails}
          width={width}
          setRecall={setRecall}
          recall={recall}
          userName={userName}
        />
      )}
    </div>
  );
}
