import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import img3 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Document.svg";
import img4 from "../../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",

  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const stylex = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  maxHieght: "90vh",
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
};
export default function Listofproposals({ width, data }) {
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const navigate = useNavigate();
  const handleClosex = () => setOpenx(false);

  return (
    <div
      style={{ marginTop: "0.3vw", padding: "0.25vw" }}
      className="listofproposalname"
    >
      <div
        style={{
          padding: width > 700 ? "0" : "2vw 0vw",

          fontWeight: "400",
          fontSize: width > 700 ? "1vw" : "2.5vw",
        }}
        className="navoftableblogs"
      >
        <div style={{ width: width > 700 ? "16vw" : "30vw" }}>
          {data?.loan_id?.loanPurpose?.slice(0, 30)}
        </div>
        <div style={{ width: width > 700 ? "12.2vw" : "50vw" }}>
          {data?.loan_id?.loanType?.slice(0, 25)}
        </div>
        <div style={{ width: width > 700 ? "15vw" : "30vw" }}>
          {data?.loan_id?.preferredLoanTerm?.slice(0, 30)}
        </div>
        <div style={{ width: width > 700 ? "15vw" : "40vw" }}>
          {data?.loan_id?.profession?.slice(0, 30)}
        </div>
        <div style={{ width: width > 700 ? "8.25vw" : "30vw" }}>
          {data?.loan_id?.created_at && (
            <span>
              {new Date(data?.loan_id?.created_at).getDate()}/
              {new Date(data?.loan_id?.created_at).getMonth() + 1}/
              {new Date(data?.loan_id?.created_at).getFullYear()}
            </span>
          )}
        </div>
        <div
          style={{
            width: width > 700 ? "8vw" : "30vw",
            color: data?.loan_id?.status === "pending" ? "red" : "green",
          }}
        >
          {data?.loan_id?.status}{" "}
          <img
            onClick={() => handleOpenx()}
            style={{
              width: width > 700 ? "1.82vw" : "4vw",
              cursor: "pointer",
              marginLeft: "0.1vw",
              filter: "brightness(0)",
            }}
            src={img3}
            alt=""
          />
        </div>
      </div>
      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style2}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Application Detail </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div className="titleboxflexofbank">Loan purpose :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.loanPurpose}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Date :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.created_at && (
                  <span>
                    {new Date(data?.loan_id?.created_at).getDate()}/
                    {new Date(data?.loan_id?.created_at).getMonth() + 1}/
                    {new Date(data?.loan_id?.created_at).getFullYear()}
                  </span>
                )}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Monthly Income :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.monthlyIncome}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Loan Amount :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.annualIncome}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Exprience :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.exprience}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Name</div>
              <div
                style={{ fontWeight: "400", cursor: "pointer" }}
                className="titleboxflexofbank"
                onClick={() => {
                  navigate(`/dashboard/${data?.userName}/My Profile`);
                }}
              >
                {data?.loan_id?.user_id?.fullName}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Mobile :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.mobile}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Email</div>
              <div
                style={{ fontWeight: "400", cursor: "pointer" }}
                className="titleboxflexofbank"
              >
                {data?.loan_id?.emailId}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">userName :</div>
              <div
                onClick={() => {
                  navigate(`/dashboard/${data?.userName}/My Profile`);
                }}
                style={{ fontWeight: "400", cursor: "pointer" }}
                className="titleboxflexofbank"
              >
                {data?.loan_id?.userName}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Loan Type :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.loanType}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Loan Term</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.preferredLoanTerm}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Profession :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.profession}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank"> status</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loan_id?.status}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
