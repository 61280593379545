import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import img from "../../assets/walletimage/Activity.svg";
import img1 from "../../assets/Dashboard/Iconly-Light-outline-Bookmark.svg";
import img2 from "../../assets/Dashboard/Iconly-Light-outline-Chart.svg";
import img4 from "../../assets/Dashboard/Iconly-Light-outline-Document.svg";
import img5 from "../../assets/Dashboard/Iconly-Light-outline-Paper Plus.svg";
import img6 from "../../assets/Dashboard/Iconly-Light-outline-Work.svg";
import img7 from "../../assets/Dashboard/Iconly-Light-outline-Paper.svg";
import img8 from "../../assets/Dashboard/Iconly-Light-outline-Setting.svg";
import img12 from "../../assets/walletimage/Iconly-Light-Location.svg";
import img17 from "../../assets/walletimage/Group 12656.svg";
import img89 from "../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Profile.svg";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import img23 from "../../assets/Jobs/Iconly-Light-Paper Upload.svg";
import img18 from "../../assets/walletimage/Iconly-Light-outline-Ticket.svg";
import { List } from "@mui/material";
import img10 from "../../assets/Dashboard/file-invoice-dollar-solid.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import img19 from "../../assets/Dashboard/3 User.svg";
import img14 from "../../assets/Dashboard/success.png";
import img20 from "../../assets/Dashboard/question (1).png";
import img3 from "../../assets/Dashboard/Iconly-Light-Calendar.svg";
import img22 from "../../assets/Dashboard/expense.png";
import img25 from "../../assets/Finince/icons8-fund-48.png";
import img26 from "../../assets/Finince/icons8-calculator-64.png";
import img24 from "../../assets/Finince/icons8-bank-64.png";
import img27 from "../../assets/Dashboard/law.svg";
import img28 from "../../assets/Dashboard/contract.png";
export default function BrowseWorkSiadebar() {
  const navigate = useNavigate();

  const location = useLocation();

  const [colorsidebar, setColorsidebar] = useState(1);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setColorsidebar(1);
    }
    if (location.pathname === "/dashboard/users") {
      setColorsidebar(2);
    }

    if (location.pathname === "/dashboard/virtual_assistant") {
      setOpen100(true);
    }
    if (location.pathname === "/dashboard/tickets") {
      setOpen100(true);
    }

    if (location.pathname === "/dashboard/transactions") {
      setOpen100(true);
    }
    if (location.pathname === "/dashboard/PhonePe_payment") {
      setOpen100(true);
    }

    if (location.pathname === "/dashboard/Withdraw") {
      setOpen100(true);
    }

    if (location.pathname === "/dashboard/Buying-List") {
      setColorsidebar(3);
      setOpen102(true);
    }

    if (location.pathname === "/dashboard/products") {
      setColorsidebar(4);
      setOpen102(true);
    }

    if (location.pathname === "/dashboard/blogs") {
      setColorsidebar(5);
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/invoice") {
      setColorsidebar(6);
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/lend_finance") {
      setColorsidebar(6);
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/fund_application") {
      setColorsidebar(6);
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/expenditure") {
      setColorsidebar(6);
      setOpen105(true);
    }
    if (location.pathname === "/dashboard/addblog") {
      setColorsidebar(5);
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/addblog2") {
      setColorsidebar(5);
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/postCreator") {
      setColorsidebar(5);
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/allpost") {
      setColorsidebar(5);
      setOpen106(true);
    }
    if (location.pathname === "/dashboard/bookcatalogue") {
      setColorsidebar(7);
      setOpen101(true);
    }
    if (location.pathname === "/dashboard/paidproducts") {
      setColorsidebar(7);
      setOpen101(true);
    }
    if (location.pathname === "/dashboard/proposals") {
      setColorsidebar(8);
      setOpen101(true);
    }
    if (location.pathname === "/dashboard/category") {
      setColorsidebar(9);
      setOpen107(true);
    }
    if (location.pathname === "/dashboard/addcategory") {
      setColorsidebar(9);
      setOpen107(true);
    }
    if (location.pathname === "/dashboard/catalogue") {
      setColorsidebar(10);
      setOpen103(true);
    }

    if (location.pathname === "/dashboard/faq") {
      setColorsidebar(11);
      setOpen108(true);
    }
    if (location.pathname === "/dashboard/addfaq") {
      setColorsidebar(11);
      setOpen108(true);
    }
    if (location.pathname === "/dashboard/addfaq") {
      setColorsidebar(11);
    }

    if (location.pathname === "/dashboard/addstory") {
      setColorsidebar(12);
    }
    if (location.pathname === "/dashboard/story") {
      setColorsidebar(12);
      setOpen109(true);
    }
    if (location.pathname === "/dashboard/addstory") {
      setColorsidebar(12);
      setOpen109(true);
    }

    if (location.pathname === "/dashboard/contactUs") {
      setColorsidebar(13);
    }
    if (location.pathname === "/dashboard/cms") {
      setColorsidebar(14);
      setOpen110(true);
    }
    if (location.pathname === "/dashboard/team") {
      setColorsidebar(14);
      setOpen110(true);
    }
    if (location.pathname === "/dashboard/bookingList") {
      setColorsidebar(15);
      setOpen102(true);
    }
    if (location.pathname === "/dashboard/findtalent") {
      setColorsidebar(16);
      setOpen104(true);
    }
    if (location.pathname === "/dashboard/connect") {
      setColorsidebar(17);
      setOpen104(true);
    }

    if (location.pathname === "/dashboard/contract") {
      setOpen111(true);
    }
  }, [location]);
  const [open100, setOpen100] = React.useState(false);

  const handleClick100 = () => {
    setOpen100(!open100);
  };
  const [open101, setOpen101] = React.useState(false);

  const handleClick101 = () => {
    setOpen101(!open101);
  };
  const [open102, setOpen102] = React.useState(false);

  const handleClick102 = () => {
    setOpen102(!open102);
  };
  const [open103, setOpen103] = React.useState(false);

  const handleClick103 = () => {
    setOpen103(!open103);
  };

  const [open104, setOpen104] = React.useState(false);

  const handleClick104 = () => {
    setOpen104(!open104);
  };

  const [open105, setOpen105] = React.useState(false);

  const handleClick105 = () => {
    setOpen105(!open105);
  };
  const [open106, setOpen106] = React.useState(false);

  const handleClick106 = () => {
    setOpen106(!open106);
  };

  const [open107, setOpen107] = React.useState(false);

  const handleClick107 = () => {
    setOpen107(!open107);
  };
  const [open108, setOpen108] = React.useState(false);

  const handleClick108 = () => {
    setOpen108(!open108);
  };
  const [open109, setOpen109] = React.useState(false);

  const handleClick109 = () => {
    setOpen109(!open109);
  };
  const [open110, setOpen110] = React.useState(false);

  const handleClick110 = () => {
    setOpen110(!open110);
  };

  const [open111, setOpen111] = React.useState(false);

  const handleClick111 = () => {
    setOpen111(!open111);
  };

  const width = 900;

  return (
    <div
      style={{
        position: "sticky",
        width: width > 700 ? "" : "100%",
        height: "calc( 100vh - 4vw)",
        paddingTop: width > 700 && "0vw",
      }}
      className="ScrollTable sidebardashbord-container"
    >
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Link to="/dashboard">
          <div
            className={
              location.pathname === "/dashboard"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
            onClick={width <= 700 && toggleDrawer("left", false)}
            style={{
              borderTop: "none",
            }}
          >
            <img
              style={{
                width: width > 700 ? "1.8vw" : "5.5vw",
                margin: width > 700 ? "" : "0vw 2.3vw",
              }}
              src={img}
              alt=""
            />
            Dashboard
          </div>
        </Link>

        <Link to="/dashboard/contactUs">
          <div
            className={
              (colorsidebar === location.pathname) === "/dashboard/contactUs"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
          >
            <img src={img18} alt="" />
            Contact Us
          </div>
        </Link>

        <div
          onClick={handleClick110}
          className={
            colorsidebar === 14 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img8}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "1vw" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              CMS
            </div>
            <div>
              {" "}
              {open110 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open110}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/cms">
              <div
                className={
                  location.pathname === "/dashboard/cms"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Dashboard Banner
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/team">
              <div
                className={
                  location.pathname === "/dashboard/team"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img19}
                  alt=""
                />
                Team
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/businessValueForm">
              <div
                className={
                  location.pathname === "/dashboard/businessValueForm"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img5}
                  alt=""
                />
                Business Valuation Form
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={() => navigate("/dashboard/users")}
          className={
            (colorsidebar === location.pathname) === "/dashboard/users"
              ? "firstsidebarmenu1"
              : "firstsidebarmenu"
          }
        >
          <img src={img89} alt="" />
          Users
        </div>
      
        <div
          onClick={handleClick100}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img22}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "1vw" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Revenue
            </div>
            <div>
              {" "}
              {open100 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open100}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/virtual_assistant">
              <div
                className={
                  location.pathname === "/dashboard/virtual_assistant"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Virtual Assistant List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/tickets">
              <div
                className={
                  location.pathname === "/dashboard/tickets"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Transaction issue ticket
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/transactions">
              <div
                className={
                  location.pathname === "/dashboard/transactions"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Transaction List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/PhonePe_payment">
              <div
                className={
                  location.pathname === "/dashboard/PhonePe_payment"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Phonepe Payment List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/Withdraw">
              <div
                className={
                  location.pathname === "/dashboard/Withdraw"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Withdraw Request List
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick101}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img5}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "1vw" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Marketplace
            </div>
            <div>
              {" "}
              {open101 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open101}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/paidproducts">
              <div
                className={
                  location.pathname === "/dashboard/paidproducts"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Paid promotion
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/bookcatalogue">
              <div
                className={
                  location.pathname === "/dashboard/bookcatalogue"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Direct Buy List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/proposals">
              <div
                className={
                  location.pathname === "/dashboard/proposals"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Proposals
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick102}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img1}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Sell
            </div>
            <div>
              {" "}
              {open102 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open102}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/Buying-List">
              <div
                className={
                  location.pathname === "/dashboard/Buying-List"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img2}
                  alt=""
                />
                Order/ Buying List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/bookingList">
              <div
                className={
                  location.pathname === "/dashboard/bookingList"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Booking List
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/products">
              <div
                className={
                  location.pathname === "/dashboard/products"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Product /Service List
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick103}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img2}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Technology
            </div>
            <div>
              {" "}
              {open103 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open103}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/catalogue">
              <div
                className={
                  location.pathname === "/dashboard/catalogue"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img12}
                  alt=""
                />
                List of Websites
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick104}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img19}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Human Resourse
            </div>
            <div>
              {" "}
              {open104 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open104}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/findtalent">
              <div
                className={
                  location.pathname === "/dashboard/findtalent"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img6}
                  alt=""
                />
                Find Talent
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/connect">
              <div
                className={
                  location.pathname === "/dashboard/connect"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Connection List
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick105}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img10}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Finance
            </div>
            <div>
              {" "}
              {open105 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open105}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/invoice">
              <div
                className={
                  location.pathname === "/dashboard/invoice"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Invoices
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/expenditure">
              <div
                className={
                  location.pathname === "/dashboard/expenditure"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Expenditure
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/lend_finance">
              <div
                className={
                  location.pathname === "/dashboard/lend_finance" ||
                  location.pathname === "/dashboard/add_lend_finance"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img24}
                  alt=""
                />
                Lend Finance
              </div>
            </Link>
          </List>

          <List component="div" disablePadding>
            <Link to="/dashboard/fund_application">
              <div
                className={
                  location.pathname === "/dashboard/fund_application"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img25}
                  alt=""
                />
                Fund Application
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick111}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img25}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Legal
            </div>
            <div>
              {" "}
              {open111 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open111}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/contract">
              <div
                className={
                  location.pathname === "/dashboard/contract" ||
                  location.pathname.includes("/dashboard/editcontract") ||
                  location.pathname === "/dashboard/addcontract"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img26}
                  alt=""
                />
                Contract
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick106}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img4}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Marketing
            </div>
            <div>
              {" "}
              {open106 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open106}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/addblog">
              <div
                className={
                  location.pathname === "/dashboard/addblog"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <AddIcon
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Add a Blog by Author
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/addblog2">
              <div
                className={
                  location.pathname === "/dashboard/addblog2"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <AddIcon
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Add Blog by Team
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/blogs">
              <div
                className={
                  location.pathname === "/dashboard/blogs"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                List of Blogs
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/postCreator">
              <div
                className={
                  location.pathname === "/dashboard/postCreator"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img3}
                  alt=""
                />
                Post Creator
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/allpost">
              <div
                className={
                  location.pathname === "/dashboard/allpost"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                List of Posts
              </div>
            </Link>
          </List>
        </Collapse>
        <Link to="/dashboard/referral">
          <div
            className={
              location.pathname === "/dashboard/referral"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
            onClick={width <= 700 && toggleDrawer("left", false)}
          >
            <img src={img20} alt="" />
            Referral
          </div>
        </Link>
        <Link to="/dashboard/geturl">
          <div
            className={
              location.pathname === "/dashboard/geturl"
                ? "firstsidebarmenu1"
                : "firstsidebarmenu"
            }
            onClick={width <= 700 && toggleDrawer("left", false)}
          >
            <img src={img4} alt="" />
            Image Link
          </div>
        </Link>
        <div
          onClick={handleClick109}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img14}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Story
            </div>
            <div>
              {" "}
              {open109 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open109}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/addstory">
              <div
                className={
                  location.pathname === "/dashboard/addstory"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <AddIcon
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Add Story
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/story">
              <div
                className={
                  location.pathname === "/dashboard/story"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img23}
                  alt=""
                />
                List of Story
              </div>
            </Link>
          </List>
        </Collapse>
        <div
          onClick={handleClick107}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img17}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              Category
            </div>
            <div>
              {" "}
              {open107 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open107}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/addcategory">
              <div
                className={
                  location.pathname === "/dashboard/addcategory"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <AddIcon
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Add Category
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/category">
              <div
                className={
                  location.pathname === "/dashboard/category"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                List of Category
              </div>
            </Link>
          </List>
        </Collapse>

        <div
          onClick={handleClick108}
          className={
            colorsidebar === 2 ? "firstsidebarmenu1" : "firstsidebarmenu"
          }
          style={{ background: "white", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={img20}
                alt=""
                style={{
                  width: width > 700 ? "1.8vw" : "5.5vw",
                  margin: width > 700 ? "" : "0vw 2.3vw",
                  filter: "none",
                }}
              />
              FAQ
            </div>
            <div>
              {" "}
              {open108 ? (
                <ExpandLess
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              ) : (
                <ExpandMore
                  style={{
                    width: width > 700 ? "1.8vw" : "5.5vw",
                    margin: width > 700 ? "0vw 0.5vw 0vw 0vw" : "0vw 2.3vw",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <Collapse
          style={{ background: "#00000009" }}
          in={open108}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/dashboard/addfaq">
              <div
                className={
                  location.pathname === "/dashboard/addfaq"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <AddIcon
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "0 1vw" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                Add FAQ
              </div>
            </Link>
          </List>
          <List component="div" disablePadding>
            <Link to="/dashboard/faq">
              <div
                className={
                  location.pathname === "/dashboard/faq"
                    ? "firstsidebarmenu1"
                    : "firstsidebarmenu"
                }
                onClick={width <= 700 && toggleDrawer("left", false)}
                style={{
                  fontSize: width > 700 ? "0.91vw" : "2.7vw",
                  height: width > 700 ? "2.8vw" : "8vw",
                  borderTop: "none",
                }}
              >
                <img
                  style={{
                    width: width > 700 ? "1.4vw" : "5.5vw",
                    margin: width > 700 ? "" : "0vw 2.3vw",
                  }}
                  src={img7}
                  alt=""
                />
                List of FAQ
              </div>
            </Link>
          </List>
        </Collapse>
      </List>
    </div>
  );
}
