import React, { useEffect, useState } from "react";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import img3 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import axios from "axios";
import Box from "@mui/material/Box";
import img111111 from "../../../assets/unnamed.png";
import API_HOST from "../../../env";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "@mui/material/Modal";
import { Backdrop, CircularProgress } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import imageCompression from "browser-image-compression";
import img4 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Delete.svg";
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",

  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const stylex = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  maxHieght: "90vh",
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
};

const styl3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
export default function Geturl() {
  const [bannerimage, setBannerimage] = useState();
  const width = 900;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    console.log(false);
  };
  const handleToggle = () => {
    setOpen(true);
    console.log(true);
  };

  function download(filename, url) {
    var element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();
    //remove the hidden link
    //document.body.removeChild(element);
  }
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      console.log(new File([compressedFile], "name"));
      // smaller than maxSizeMB
      var name = imageFile.name;

      var url = URL.createObjectURL(compressedFile);
      setblob(url);

      await setBannerimage(
        new File([compressedFile], `${compressedFile?.name}`)
      );
    } catch (error) {
      console.log(error);
    }
  }

  const [blob, setblob] = useState();

  const [URlofimage, setURlofimage] = useState("");
  const [linkCopied, setLinkCopied] = useState(false);

  const handlecreateImageurl = () => {
    if (bannerimage) {
      const data = new FormData();
      console.log(bannerimage);
      data.append("image", bannerimage);

      handleToggle();

      axios
        .post(`${API_HOST}/images/add`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          setURlofimage(res.data?.success?.data?.image);
          handleClose();
        })
        .catch(() => {
          handleClose();
        });
    }
  };
  const handledleete = (id) => {
    const data = new FormData();
    console.log(id);
    data.append("imagesId", id);

    handleToggle();

    axios
      .post(`${API_HOST}/images/remove`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setURlofimage(res.data?.success?.data?.image);
        setrecall(!recall);
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  };

  const [allimages, setAllimages] = useState([]);
  const [page, setPage] = useState(1);

  const [recall, setrecall] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_HOST}/images/view?pageNumber=${page}&pageSize=12`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setAllimages(res.data?.success?.data);
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  }, [URlofimage, recall,page]);

  const [openx1, setOpenx1] = React.useState(false);
  const handleOpenx1 = () => setOpenx1(true);
  const handleClosex1 = () => setOpenx1(false);

  const [imagesave, setImagesave] = useState();

  return (
    <div style={{ height: "calc( 100vh - 4vw)" }} className="ScrollTable">
      <div style={{ padding: "2vw" }} className="profile-container">
        <div className="profile-box">
          <div className="jobpostedformheading">Create a New Image URL</div>
          <div className="projectAddbar">
            <div className="jobpodtedfieldtitile">Upload Image</div>
            <div
              style={{
                width: width > 700 ? "95%" : "95%",
                height: width > 700 ? "10vw" : "20vw",
                marginTop: "1vw",
              }}
              className="inputfilebox"
            >
              <div>
                <label htmlFor="inputctaelogfile">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload Story image Image
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>

            {bannerimage && (
              <div className="inputfilesshowncatboxsingle">
                <div className="inputfilesshowncatboxsingleimg">
                  <img
                    style={{ width: width > 700 ? "" : "5vw" }}
                    src={img3}
                    alt=""
                  />
                </div>
                <div className="fileselctednamecate">
                  {bannerimage ? bannerimage?.name.slice(0, 50) : bannerimage}
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "1vw",
                width: "22vw",
                justifyContent: "space-between",
              }}
            >
              <div
                onClick={() => {
                  setBannerimage();
                  setLinkCopied(false);
                  setURlofimage("");
                }}
                className="handlecirclieaboutsave"
              >
                Clear
              </div>
              {blob && (
                <div
                  onClick={() => {
                    download(bannerimage?.name, blob);
                  }}
                  className="handlecirclieaboutsave"
                  href={bannerimage}
                >
                  Download
                </div>
              )}

              <div
                onClick={() => {
                  handlecreateImageurl();
                }}
                className="handlecirclieaboutsave"
              >
                Create
              </div>
            </div>

            {URlofimage && (
              <div style={{ width: "10vw" }}>
                <div
                  style={{ width: "fit-content", marginTop: "2vw" }}
                  className="Errorbox"
                >
                  {URlofimage}
                </div>
                {linkCopied ? (
                  <div
                    onClick={() => {
                      setLinkCopied(true);
                    }}
                    className="handlecirclieaboutsave"
                  >
                    Copied
                  </div>
                ) : (
                  <CopyToClipboard text={URlofimage}>
                    <div
                      onClick={() => {
                        setLinkCopied(true);
                      }}
                      style={{ width: "13vw" }}
                      className="handlecirclieaboutsave"
                    >
                      Copy to clipboard
                    </div>
                  </CopyToClipboard>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="jobpodtedfieldtitile">All Uploaded Image</div>
        <div>
          <div
            style={{
              margin: "1vw",
              flexWrap: "wrap",

              justifyContent: "flex-start",
            }}
            className="activejobpistbudgetbox"
          >
            {allimages?.map((data) => {
              return (
                <div
                  style={{
                    marginRight: width > 700 ? "1vw" : "4vw",
                    marginBottom: width > 700 ? "" : "2vw",
                    marginTop: width > 700 ? "" : "2vw",
                  }}
                  className="boxofimageorpdf"
                >
                  <div
                    onClick={() => {
                      if (
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "jpeg" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "png" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "webp" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "svg" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "jpg"
                      ) {
                        handleOpenx1();
                        setImagesave(data?.image);
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    className="imageshowboxofpdf"
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "jpeg" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "png" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "webp" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "svg" ||
                        data?.image?.split(".")[
                          data?.image?.split(".")?.length - 1
                        ] === "jpg"
                          ? data?.image
                          : img111111
                      }
                      alt=""
                    />
                  </div>
                  <div className="imageshowboxofpdfname">
                    <div>
                      <img
                        src={img3}
                        style={{
                          color: "red",
                          width: width > 700 ? "1.7vw" : "4vw",
                        }}
                      />
                    </div>
                    <div className="nameifimagedocuments">
                      {data?.image?.split("$")[1]?.slice(0, 13)}
                    </div>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="inputfilesshowncatboxsingleimg"
                    >
                      <a href={`${data?.image}`} download>
                        {" "}
                        <CloudDownloadOutlinedIcon
                          style={{
                            fontSize: width > 700 ? "1.5vw" : "4vw",
                            margin: "0 0.2vw",
                          }}
                        />{" "}
                      </a>
                      <CopyToClipboard text={data?.image}>
                        <div>
                          <CopyAllIcon
                            style={{
                              fontSize: width > 700 ? "1.5vw" : "4vw",
                              margin: "0 0.2vw",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </CopyToClipboard>
                      <img
                        src={img4}
                        onClick={() => {
                          handledleete(data?.imagesId);
                        }}
                        style={{
                          width: width > 700 ? "1.5vw" : "4vw",
                          margin: "0 0.2vw",
                          cursor: "pointer",
                        }}
                      />{" "}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Modal
            open={openx1}
            onClose={handleClosex1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? stylex : styl3}>
              {imagesave && (
                <div className="imgbocofcerti">
                  <img style={{ maxHeight: "90vh" }} src={imagesave} alt="" />
                </div>
              )}
            </Box>
          </Modal>
        </div>
        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div>
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page - 1 > 0) {
                  setPage(page - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page - 4 > 0 ? false : true}
            onClick={() => setPage(page - 4)}
          >
            {page - 4}
          </div>
          <div
            hidden={page - 3 > 0 ? false : true}
            onClick={() => setPage(page - 3)}
          >
            {page - 3}
          </div>
          <div
            hidden={page - 2 > 0 ? false : true}
            onClick={() => setPage(page - 2)}
          >
            {page - 2}
          </div>
          <div
            hidden={page - 1 > 0 ? false : true}
            onClick={() => setPage(page - 1)}
          >
            {page - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page}</div>
          <div onClick={() => setPage(page + 1)}>{page + 1}</div>
          <div onClick={() => setPage(page + 2)}>{page + 2}</div>
          <div onClick={() => setPage(page + 3)}>{page + 3}</div>

          <div>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
}
