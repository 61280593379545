
import React, { useEffect, useState } from "react";

export default function Profilehobbies({ user, width }) {
  return (
    <div style={{ height: "fit-content" }} className="profilebadgecontainer">
      <div
        style={{ padding: "2vw", margin: "0" }}
        className="profiletitleandmenunav"
      >
        <div className="profiledetailstitle">Hobbies (Optional)</div>
      </div>

      <div className="pallskillboxes">
        {user?.hobbies?.map((skill) => {
          return <div className="pskillbox">{skill?.hobby}</div>;
        })}
      </div>
    </div>
  );
}
