import axios from "axios";
import React from "react";

import { useNavigate } from "react-router";
import API_HOST from "../../../env";

export default function Skillpopupcopy({ width, data, setRecall, recall }) {
  const navigate = useNavigate();
  const ref = React.createRef();

  return (
    <div ref={ref}>
      <div
        style={{
          alignItems: "center",
          margin: width > 700 ? "1vw 0vw" : "2vw 0vw",
          padding: width > 700 ? "0.5vw  1vw" : "2vw",
          color: "black",
          width: width > 700 ? "82vw" : "300vw",
          cursor: "pointer",
        }}
        className="navoftableblogsdata"
      >
        <div
          onClick={() => {
            navigate(
              `/dashboard/${data?._id?.refferdby?.userName[0]}/My Profile`
            );
          }}
          style={{ width: width > 700 ? "20vw" : "50vw" }}
        >
          <span>
            <img
              style={{
                height: "fit-content",
                width: width > 700 ? "1.4vw" : "5vw",
                margin: "0vw",
                marginRight: "0.2vw",
                height: width > 700 ? "1.4vw" : "5vw",
                borderRadius: "50%",
                objectFit: "contain",
              }}
              src={data?._id?.refferdby?.media[0]}
              alt=""
            />
          </span>{" "}
          {data?._id?.refferdby?.fullName[0]?.slice(0, 25)}
        </div>
        <div
          onClick={() => {
            navigate(
              `/dashboard/${data?._id?.users?.client?.userName[0]}/My Profile`
            );
          }}
          style={{ width: width > 700 ? "20vw" : "50vw" }}
        >
          <span>
            <img
              style={{
                height: "fit-content",
                width: width > 700 ? "1.4vw" : "5vw",
                margin: "0vw",
                height: width > 700 ? "1.4vw" : "5vw",
                marginRight: "0.2vw",
                borderRadius: "50%",
                objectFit: "contain",
              }}
              src={data?._id?.users?.media[0]}
              alt=""
            />
          </span>{" "}
          {data?._id?.users?.fullName[0]?.slice(0, 25)}
        </div>
        <div style={{ width: width > 700 ? "20vw" : "50vw" }}>
          {data?._id?.users?.emailId?.length > 26
            ? data?._id?.users?.emailId?.slice(0, 24) + ".."
            : data?._id?.users?.emailId}
        </div>

        <div style={{ width: width > 700 ? "10vw" : "90vw" }}>
          {data?._id?.users?.contactNo[0]}
        </div>

        <div style={{ width: width > 700 ? "10vw" : "39vw" }}>
          {new Date(data?._id?.users?.userCreateTime[0]).getDate()}/
          {new Date(data?._id?.users?.userCreateTime[0]).getMonth() + 1}/
          {new Date(data?._id?.users?.userCreateTime[0]).getFullYear()}
        </div>

        <div
          style={{
            width: width > 700 ? "9vw" : "39vw",
            display: "flex",
            textAlign: "left",

            justifyContent: "flex-start",
            color: !data?._id?.users?.suspend[0] ? "green" : "red",
            fontSize: width > 700 ? "0.85vw" : "2.6vw",
          }}
        >
          {data?._id?.users?.suspend[0] ? "Suspend" : "Active"}
        </div>
      </div>
    </div>
  );
}
