import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import API_HOST from "../../../env";
import axios from "axios";
import "@progress/kendo-theme-default/dist/all.css";
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./PageTemplate";
import moment from "moment";
export const ContractDetail = ({ width }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState();

  useEffect(() => {
    axios
      .get(`${API_HOST}/contract/viewContract?contractId=${id}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setdata(res?.data?.success?.data[0]);
      });
  }, [id]);

  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = useCallback(() => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  }, [pdfExportComponent]);

  return (
    <div
      style={{
        height: width > 700 ? "calc(100vh - 4vw )" : "calc(100vh - 14vw )",
      }}
      className="ScrollTable"
    >
    
      <div
        style={{
          width: "100%",
          background: "white",
          padding: width > 700 ? "1vw 1vw" : "2vw",
          margin: "1vw 0vw",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              zIndex: "100",
              padding: "0.5vw 0.7vw",
              backgroundColor: "white",
              color: "#000",
              fontSize: "1.2vw",
              borderRadius: "0.3vw",
              border: "1px solid #d7d7d7",
              marginBottom: width > 700 ? "0vw" : "3vw",
              marginTop: "0vw",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </button>
          <div
            style={{
              fontWeight: "600",
              fontSize: width > 700 ? "1.3vw" : "3.5vw",

              color: "#0052cc",
            }}
          >
            <div>{data?.contractName}</div>
          </div>

          <button
            ref={pdfExportComponent}
            onClick={() => {
              exportPDFWithComponent();
            }}
            style={{
              marginRight: width > 700 ? "2vw" : "0vw",
              width: width > 700 ? "12vw" : "32vw",
              marginTop: width > 700 ? "0vw" : "0vw",
              height: width <= 700 && "9vw",
              float: "right",
              marginLeft: "1vw",
            }}
            className="hb-buttonx"
          >
            <span>Download contract </span>
          </button>
        </div>

        <div
          style={{
            width: "600px",
            margin: "auto",
            height: "fit-content",
            marginTop: "2vw",
            border: "2px solid black",
            padding: "25px",
            maxWidth: "96vw",
            overflowX: "scroll",
          }}
        >
          <PDFExport
            ref={pdfExportComponent}
            fileName={data?.contractName}
            paperSize="A4"
            pageTemplate={() => PageTemplate(data)}
            margin={{ top: "1cm", left: "1cm", right: "1cm", bottom: "1cm" }}
          >
            <div
              style={{ width: "540px", padding: "0" }}
              className="Aboutabout-container"
            >
              <div className="contractNavbar">
                <img
                  src={data?.logo}
                  className="contractlogo"
                  alt="invalid"
                  onError={(e) => {
                    console.error("Error loading image:", e);
                  }}
                  crossorigin="anonymous"
                />
                <div className="contractNavbardata">
                  <div className="contractNavbardata1">
                    Date - {moment(data?.date).format("DD/MM/YYYY")}:
                    {data?.time}
                  </div>
                  <div className="contractNavbardata1">
                    Contract number - {data?.contractNo}{" "}
                  </div>
                </div>
              </div>
               <hr />
              <div className="contractHeadline">{data?.contractName}</div>

              <div className="contractinfo">
                This Contract is executed on (executionOf) by and between the
                following:
              </div>
              <div className="contractPartyDetailsbox">
                <div style={{borderRight:"1px solid black"}} className="contractpartyDetail">
                  <div className="contractDetailheading">
                    Name of contracting party
                  </div>
                  <div className="contractDetailvalue">
                    {data?.contractorName}
                  </div>
                  <div className="contractDetailheading">Name of company</div>
                  <div className="contractDetailvalue">{data?.companyName}</div>
                  <div className="contractDetailheading">Email</div>
                  <div className="contractDetailvalue">{data?.emailId}</div>
                  <div className="contractDetailheading">Mobile number</div>
                  <div className="contractDetailvalue">{data?.mobile}</div>

                  <div className="contractDetailheading">Address</div>
                  <div className="contractDetailvalue">{data?.address}</div>
                </div>
                <div className="contractpartyDetail">
                  <div className="contractDetailheading">
                    Name of contracting party
                  </div>
                  <div className="contractDetailvalue">
                    {data?.secondcontractorName}
                  </div>
                  <div className="contractDetailheading">Name of company</div>
                  <div className="contractDetailvalue">{data?.secondcompanyName}</div>
                  <div className="contractDetailheading">Email</div>
                  <div className="contractDetailvalue">{data?.secondemailId}</div>
                  <div className="contractDetailheading">Mobile number</div>
                  <div className="contractDetailvalue">{data?.secondmobile}</div>
                  <div className="contractDetailheading">Address</div>
                  <div className="contractDetailvalue">{data?.secondaddress}</div>
                </div>
              </div>
              <div
                style={{ width: "538px" }}
                className="textimage"
                dangerouslySetInnerHTML={{ __html: data?.detail }}
              ></div>
            </div>
          </PDFExport>
        </div>
      </div>
    </div>
  );
};
