import React from "react";
import { useNavigate } from "react-router";
import img2 from "../../../assets/Success stories Definition/checkmark (1).svg";

import img1 from "../../../assets/About/Iconly-Light-Location.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
export default function ProfileCatelogdetails({ width, data }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: width > 700 ? "18vw" : "45vw",
        height: width > 700 ? "26vw" : "65vw",
        marginTop: width > 700 ? "1vw" : "1.8vw",
        marginBottom: width > 700 ? "1vw" : "1.8vw",
        marginLeft: width > 700 ? "1vw" : "1.5vw",
        marginRight: width > 700 ? "1vw" : "1.5vw",
      }}
      className="Menuwebsite"
    >
      <div>
        <img
          style={{
            width: width > 700 ? "10vw" : "30vw",
            height: width > 700 ? "10vw" : "30vw",
            margin: "auto 3vw",
            borderRadius: "50%",
            filter:
              data?.product_id?.type !== "Service" &&
              data?.quantity < 1 &&
              "grayscale(1)",
            cursor: "pointer",
          }}
          src={data?.media}
          alt=""
        />
        <div style={{ height: "0.3vw" }}>
          {data?.verifiedByAdmin && (
            <img
              style={{
                width: "1.5vw",
                height: "1.5vw",
                position: "relative",
                left: "10.5vw",
                bottom: "1.9vw",
              }}
              src={img2}
              alt=""
            />
          )}
        </div>
        <div
          onClick={() => {}}
          style={{
            textAlign: "center",
            marginTop: "0.3vw",
            height: "2vw",
          }}
          className="namemenuweb"
        >
          {data?.fullName?.slice(0, 20)}
        </div>
        <div
          onClick={() => {}}
          style={{
            textAlign: "center",
            paddingTop: "0vw",
            fontSize: "0.91vw",
            color: "#0052cc",
            height: "1.55vw",
          }}
          className="namemenuweb"
        >
          {data?.title?.slice(0, 30)}
        </div>
        <div
          style={{
            height: width > 700 ? "3.5vw" : "6vw",
            paddingTop: "1vw",
            fontWeight: "400",
            textAlign: "center",
            fontSize: "0.9vw",
            lineHeight: "1.1vw",
            color: "#000000bb",
          }}
          className="pricemenuweb"
        >
          {data?.fullDescription?.length > 90
            ? data?.fullDescription?.slice(0, 95) + "..."
            : data?.fullDescription}
        </div>

        <div
          style={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
            margin: "0.35vw 0vw",
            marginTop: "0.35vw",
            alignItems: "center",
            height: "2.1vw",
          }}
          className="profileuserdfirst"
        >
          {data?.fLink && (
            <div
              style={{
                width: "25%",
                height: "2vw",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                style={{
                  width: width > 700 ? "1.8vw" : "5vw",
                  height: width > 700 ? "1.8vw" : "5vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href={data?.fLink}
                target="_blank"
              >
                <FacebookIcon
                  style={{
                    width: width > 700 ? "1.8vw" : "5vw",
                    height: width > 700 ? "1.8vw" : "5vw",
                    cursor: "pointer",
                    color: "#0052cc",
                  }}
                />
              </a>
            </div>
          )}
          {data?.gLink && (
            <div
              style={{
                width: "25%",
                height: "2vw",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <a
                style={{
                  width: width > 700 ? "1.8vw " : "5vw",
                  height: width > 700 ? "1.8vw" : "5vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href={data?.gLink}
                target="_blank"
              >
                <GoogleIcon
                  style={{
                    width: width > 700 ? "1.8vw" : "5vw",
                    height: width > 700 ? "1.8vw" : "5vw",
                    cursor: "pointer",
                    color: "#0052cc",
                  }}
                />
              </a>
            </div>
          )}
          {data?.lLink && (
            <div
              style={{
                width: "25%",
                height: "2vw",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <a
                style={{
                  width: width > 700 ? "1.8vw" : "5vw",
                  height: width > 700 ? "1.8vw" : "5vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href={data?.lLink}
                target="_blank"
              >
                <LinkedInIcon
                  style={{
                    width: width > 700 ? "1.8vw" : "5vw",
                    height: width > 700 ? "1.8vw" : "5vw",
                    cursor: "pointer",
                    color: "#0052cc",
                  }}
                />
              </a>
            </div>
          )}
          {data?.gitLink && (
            <div
              style={{
                width: "25%",
                height: "2vw",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <a
                style={{
                  width: width > 700 ? "1.8vw" : "5vw",
                  height: width > 700 ? "1.8vw" : "5vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href={data?.gitLink}
                target="_blank"
              >
                <GitHubIcon
                  style={{
                    width: width > 700 ? "1.8vw" : "5vw",
                    height: width > 700 ? "1.8vw" : "5vw",
                    cursor: "pointer",
                    color: "#0052cc",
                  }}
                />
              </a>
            </div>
          )}
        </div>

        <div
          style={{
            height: width > 700 ? "1.6vw" : "4.5vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "0.4vw",
          }}
          className="pricemenuweb"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <span>
              <img
                style={{
                  height: "fit-content",
                  width: width > 700 ? "1.3vw" : "3.7vw",
                  margin: "0vw",
                  marginRight: "0.1.8vw",
                }}
                src={img1}
                alt=""
              />
            </span>{" "}
            {data?.address?.slice(0, width > 700 ? 28 : 24)}
          </div>{" "}
        </div>

        <div
          onClick={() => {
            navigate(`/dashboard/${data?.userName}/My Profile`);
          }}
          className="Visitwebsitebutton"
        >
          View Profile
        </div>
      </div>
    </div>
  );
}
