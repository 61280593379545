import React, { useState } from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import img1 from "../../../assets/About/Iconly-Light-Location.svg";
import { makeStyles } from "@material-ui/core";
import ReactImageMagnify from "react-image-magnify";
import Slider from "react-slick";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import StarIcon from "@mui/icons-material/Star";
function SamplePrevArrow(props) {
  return (
    <div
      style={{
        width: "3vw",
        height: "3vw",

        borderRadius: "50%",
        background: "#FFF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
        margin: "1vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowUpwardIcon alt="prev" />
    </div>
  );
}

function SampleNextArrow(props) {
  return (
    <div
      style={{
        width: "3vw",
        height: "3vw",
        borderRadius: "50%",
        background: "#FFF",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.2)",
        margin: "1vw",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={props.onClick}
    >
      <ArrowDownwardIcon alt="next" />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",

    color: "#263238",
    border: "yellow !important",
  },
}));

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "0.51vw",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "1vw",
};

export default function ProfileCatelogdetails1({ width, data }) {
  const navigate = useNavigate();

  let settings = {
    infinite: false,
    speed: 250,
    arrows: true,
    slidesToShow: 3,
    initialSlide: 0,
    slidesToScroll: 2,
    vertical: true,
    swipeToSlide: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  const [displayImage, setDisplayImage] = useState(0);
  const sliderClick = (value) => {
    setDisplayImage(value);
  };


  const myStr = data?.website_id?.websiteName;
  const contentNameRoute = myStr
    .replaceAll(" ", "-")
    .replaceAll("/", "_")
    .replaceAll("?", "_");
  return (
    <div
      style={{
        width: "18vw",
        height: "23.8vw",
        marginTop: "1vw",
        marginBottom: "1vw",
        marginLeft: "1vw",
        marginRight: "1vw",
      }}
      className="Menuwebsite"
    >
      <div>
        <img
          style={{
            width: "16vw",
            height: "12vw",
            filter: data?.web_id?.quantity < 1 && "grayscale(1)",
            cursor: "pointer",
          }}
          src={data?.product_id?.files[0]?.file}
          alt=""
        />
        <div
          style={{
            cursor: "pointer",
          }}
          className="namemenuweb"
        >
          {data?.product_id?.productName?.length > 23
            ? data?.product_id?.productName?.slice(0, 21) + ".."
            : data?.product_id?.productName?.slice(0, 23)}
        </div>
        <div
          style={{
            color:
              data?.product_id?.type === "Service"
                ? ""
                : data?.quantity > 0
                ? ""
                : "red",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="stockmenuweb"
        >
          {data?.product_id?.type === "Service"
            ? data?.product_id?.serviceType
            : data?.quantity > 0
            ? data?.quantity + " Items Left"
            : "Out of Stock"}{" "}
          <span>
            {data?.product_id?.type ? data?.product_id?.type : "Product"}
          </span>
        </div>
        <div
          style={{ paddingBottom: "0.2vw", height: "2vw", fontSize: "1.1vw" }}
          className="pricemenuweb"
        >
          <span>Rs. {data?.product_id?.costPrice}</span>{" "}
          {data?.product_id?.sellingPrice && (
            <del
              style={{
                display: "flex",
                fontSize: "0.75vw",
                height: "0.8vw",
                margin: "0 0.5vw",
              }}
            >
              ( Rs {data?.product_id?.sellingPrice})
            </del>
          )}
          {data?.product_id?.sellingPrice && (
            <span style={{ color: "#0052cc" }}>
              {parseFloat(
                ((data?.product_id?.sellingPrice -
                  data?.product_id?.costPrice) *
                  100) /
                  data?.product_id?.sellingPrice
              ).toFixed(0)}
              % off{" "}
            </span>
          )}
        </div>

        <div
          style={{
            height: "1.5vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "0.2vw",
          }}
          className="pricemenuweb"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <span>
              <img
                style={{ width: "1.4vw", margin: "0vw", marginRight: "0.2vw" }}
                src={data?.website_id?.websiteLogo}
                alt=""
              />
            </span>{" "}
            {data?.website_id?.websiteName?.slice(0, 18)}
          </div>{" "}
          <div
            style={{ color: "#0052cc", display: "flex", alignItems: "center" }}
          >
            {data?.product_id?.rating?.$numberDecimal
              ? data?.product_id?.rating?.$numberDecimal?.slice(0, 1)
              : 0}
            <StarIcon style={{ width: "1.2vw", margin: "0vw" }} /> (
            {data?.product_id?.completedOrder
              ? data?.product_id?.completedOrder
              : 0}
            )
          </div>
        </div>
        <div
          style={{
            height: width > 700 ? "1.5vw" : "4.5vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "0.2vw",
          }}
          className="pricemenuweb"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <span>
              <img
                style={{
                  height: "fit-content",
                  width: width > 700 ? "1.3vw" : "3.7vw",
                  margin: "0vw",
                  marginRight: "0.2vw",
                }}
                src={img1}
                alt=""
              />
            </span>
            {data?.website_id?.companyAddress?.slice(0, width > 700 ? 28 : 24)}
          </div>{" "}
        </div>

        <div
          onClick={() =>
            data?.website_id?.websiteName
              ? navigate(
                  `/dashboard/my-website/${contentNameRoute}/${data?.website_id?.websiteId}`
                )
              : navigate(
                  `/dashboard/my-website/-/${data?.website_id?.websiteId}`
                )
          }
          className="Visitwebsitebutton"
        >
          Visit Website
        </div>
      </div>
    </div>
  );
}
