import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import imgfilter from "../../../assets/walletimage/Iconly-Light-Color-Filter.svg";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import { useSelector } from "react-redux";
import searchicon from "../../../assets/Dashboard/Iconly-Light-Search.svg";
import Skillpopupcopy from "./Skillpopupcopy";

import { useLocation, useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: "2vw",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 1,
};

export default function Referrel({ width }) {
  const { user } = useSelector((state) => state.user);

  const [page, setPage] = useState(1);
  const [setSelectedCategory, setSetSelectedCategory] = useState("");

  const [selectedbiddingvalue, setSelectedbiddingvalue] = useState(false);
  const [prevselectedbiddingvalue, setprevSelectedbiddingvalue] =
    useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [allusers, setAllusers] = useState([]);

  const [recall, setRecall] = useState(false);

  const [prevsort, setPrevsort] = useState("created_at");
  const [sort, setSort] = useState("created_at");

  const location = useLocation();
  const url = new URLSearchParams(location.search);

  let search = url.get("search");

  useEffect(() => {
    if (search !== null) {
      setSetSelectedCategory(search);
    }
  }, [search]);

  useEffect(() => {
    if (user?.userName) {
      axios
        .get(
          `${API_HOST}/referal/viewByAdmin?pageSize=12&pageNumber=${page}${`&search=${setSelectedCategory}`}${
            selectedbiddingvalue ? `&activeType=${selectedbiddingvalue}` : ""
          }${sort ? `&${sort}=true` : ""}
          `,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          setAllusers(res.data?.success?.data);
        });
    }
  }, [page, setSelectedCategory, recall, user]);

  const navigate = useNavigate();
  return (
    <div style={{ paddingTop: "1vw" }} className="BrowseWorkMain-cntainer">
      <div
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          height: width > 700 ? "4vw" : "10vw",
          marginBottom: "1vw",
        }}
        className="searchboxcontainer"
      >
        <div className="serachjobbox">
          <span>
            <img
              src={searchicon}
              style={{ fontSize: width > 700 ? "1.7vw" : "5vw" }}
            />{" "}
          </span>
          <input
            type="text"
            placeholder={"search"}
            value={setSelectedCategory}
            onChange={(e) => {
              url.set("search", e.target.value);
              navigate(location.pathname + "?" + url.toString());
            }}
          />
        </div>
        <div>
          <div
            style={{
              flexWrap: "wrap",
              marginTop: width > 700 ? "0vw" : "1vw",
              marginBottom: "0vw",
              paddingBottom: "0vw",
              paddingTop: "0vw",
            }}
            className="filterboxflex"
          >
            <div
              onClick={() => {
                handleOpen();
              }}
              className="filtericonbox"
            >
              <img src={imgfilter} alt="" /> {width > 700 && "Filter"}
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={width > 700 ? style : style1}>
                <div
                  style={{
                    maxHeight: "80vh",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div className="profiletitleandmenunav">
                    <div className="profiledetailstitle">Add Filters</div>
                    <div className="profiledetailnavmanu">
                      <div>
                        <CloseIcon
                          onClick={handleClose}
                          style={{
                            fontSize: width > 700 ? "1.5vw" : "4vw",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "#000000" }} />

                  <div className="jobpodtedfieldtitile">User Type</div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      onClick={() =>
                        setprevSelectedbiddingvalue(!prevselectedbiddingvalue)
                      }
                      type="checkbox"
                      name=""
                      id=""
                      style={{ margin: "0 1vw" }}
                      checked={prevselectedbiddingvalue ? true : false}
                    />
                    <span className="jobpodtedfieldtitile">Suspend</span>
                  </div>
                  <div className="jobpodtedfieldtitile">Sort by</div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      onClick={() => setPrevsort("abc")}
                      type="checkbox"
                      name=""
                      id=""
                      style={{ margin: "0 1vw" }}
                      checked={prevsort === "abc" ? true : false}
                    />
                    <span className="jobpodtedfieldtitile">Name</span>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      onClick={() => setPrevsort("created_at")}
                      type="checkbox"
                      name=""
                      id=""
                      style={{ margin: "0 1vw" }}
                      checked={prevsort === "created_at" ? true : false}
                    />
                    <span className="jobpodtedfieldtitile">
                      Date ( Recent )
                    </span>
                  </div>

                  <div
                    style={{ marginTop: "0.31vw" }}
                    className="handlemoreaboutskill"
                  >
                    <div
                      style={{
                        background: "white",
                        color: "black",
                        cursor: "pointer",
                      }}
                      className="handlecirclieaboutsave"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </div>

                    <div
                      style={{
                        background: "white",
                        color: "black",
                        cursor: "pointer",
                      }}
                      className="handlecirclieaboutsave"
                      onClick={() => {
                        setSelectedbiddingvalue(false);

                        setprevSelectedbiddingvalue(false);

                        setPrevsort("created_at");
                        setSort(false);
                      }}
                    >
                      Reset
                    </div>

                    <div
                      style={{ cursor: "pointer" }}
                      className="handlecirclieaboutsave"
                      onClick={() => {
                        setSelectedbiddingvalue(prevselectedbiddingvalue);

                        setSort(prevsort);
                        handleClose();
                        setRecall(!recall);
                      }}
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>

            {width > 700 && (
              <div
                onClick={() => {
                  setSelectedbiddingvalue(false);
                  handleClose();
                  setprevSelectedbiddingvalue(false);
                  setPrevsort("created_at");
                  setSort("created_at");
                  setRecall(!recall);
                }}
                style={{ cursor: "pointer" }}
                className="filtericonboxname"
              >
                Clear all
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          overflowX: "scroll",
          width: width > 700 ? "82vw" : "94vw",
          //   height: "calc(100vh - 12.5vw)",
        }}
        className="ScrollTable"
      >
        <div
          style={{
            margin: width > 700 ? "0vw 1vw" : "1vw",
            padding: "0vw 1vw",
            width: width > 700 ? "80vw" : "300vw",
            marginTop: width > 700 && "0.61vw",
          }}
          className="navoftableblogs"
        >
          <div style={{ width: width > 700 ? "20vw" : "50vw" }}>
            Referral by
            <span
              onClick={() => {
                setSort("buyerName");
              }}
            ></span>{" "}
          </div>
          <div style={{ width: width > 700 ? "20vw" : "50vw" }}>
            Full Name
            <span
              onClick={() => {
                setSort("buyerName");
              }}
            ></span>{" "}
          </div>
          <div style={{ width: width > 700 ? "20vw" : "50vw" }}>
            Email Id
            <span
              onClick={() => {
                setSort("buyerName");
              }}
            ></span>{" "}
          </div>
          <div style={{ width: width > 700 ? "10vw" : "90vw" }}>
            Mobile
            <span
              onClick={() => {
                setSort("buyerName");
              }}
            ></span>{" "}
          </div>

          <div style={{ width: width > 700 ? "10vw" : "40vw" }}>User Since</div>

          <div style={{ width: width > 700 ? "9vw" : "40vw" }}> Status </div>
        </div>
        {allusers?.length > 0 &&
          allusers?.map((data, index) => {
            return (
              <Skillpopupcopy
                width={width}
                data={data}
                index={index}
                page={page}
                setRecall={setRecall}
                recall={recall}
              />
            );
          })}

        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div>
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page - 1 > 0) {
                  setPage(page - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page - 4 > 0 ? false : true}
            onClick={() => setPage(page - 4)}
          >
            {page - 4}
          </div>
          <div
            hidden={page - 3 > 0 ? false : true}
            onClick={() => setPage(page - 3)}
          >
            {page - 3}
          </div>
          <div
            hidden={page - 2 > 0 ? false : true}
            onClick={() => setPage(page - 2)}
          >
            {page - 2}
          </div>
          <div
            hidden={page - 1 > 0 ? false : true}
            onClick={() => setPage(page - 1)}
          >
            {page - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page}</div>
          <div onClick={() => setPage(page + 1)}>{page + 1}</div>
          <div onClick={() => setPage(page + 2)}>{page + 2}</div>
          <div onClick={() => setPage(page + 3)}>{page + 3}</div>

          <div>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
