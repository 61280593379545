import React, { useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import img3 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Document.svg";
import img111111 from "../../../assets/unnamed.png";
import Modal from "@mui/material/Modal";

import {useNavigate} from 'react-router-dom'

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",

  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const stylex = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  maxHieght: "90vh",
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
};

const styl3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};

export default function Skillpopupcopy({ width, data, setRecall, recall }) {
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);

  const handleClosex = () => setOpenx(false);

  const [openx1, setOpenx1] = React.useState(false);
  const handleOpenx1 = () => setOpenx1(true);
  const handleClosex1 = () => setOpenx1(false);

  const [imagesave, setImagesave] = useState();
  
  const navigate = useNavigate()

  return (
    <div
      style={{
        alignItems: "center",
        margin: "1vw 0vw",
        padding: width > 700 ? "0.2vw  2vw" : "2vw",
        color: "black",
        width: width > 700 ? "80vw" : "192vw",
      }}
      className="navoftableblogsdata"
    >
      <div onClick={() => {
            navigate(`/dashboard/${data?.userName}/My Profile`);
          }} style={{ width: width > 700 ? "21vw" : "30vw" ,cursor :"pointer" }}>
        {data?.userName?.slice(0, 30)}
      </div>
      <div style={{ width: width > 700 ? "21vw" : "30vw" }}>
        {data?.name?.slice(0, 30)}
      </div>
      <div style={{ width: width > 700 ? "18vw" : "50vw" }}>
        {data?.head?.slice(0, 25)}
      </div>
      <div style={{ width: width > 700 ? "12vw" : "30vw" }}>
        {" "}
        Rs.{parseFloat(data?.finalGstAmount).toFixed(2)}
      </div>

      <div style={{ width: width > 700 ? "17vw" : "40vw" }}>
        Rs.{data?.totalAmount && parseFloat(data?.totalAmount).toFixed(2)}
      </div>
      <div style={{ width: width > 700 ? "10vw" : "30vw" }}>
        {data?.date && (
          <span>
            {new Date(data?.date).getDate()}/
            {new Date(data?.date).getMonth() + 1}/
            {new Date(data?.date).getFullYear()}
          </span>
        )}
      </div>
      <div
        style={{
          width: width > 700 ? "8vw" : "12vw",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <img
          onClick={() => handleOpenx()}
          style={{
            width: width > 700 ? "1.82vw" : "4vw",
            cursor: "pointer",
            marginRight: "1vw",
            filter: "brightness(0)",
          }}
          src={img3}
          alt=""
        />
      </div>
      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style2}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Expenditure Detail </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div className="titleboxflexofbank">Item Name / Expense Name</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.name}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Date :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.date?.slice(0, 10)}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">total Amount :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.totalAmount}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Head :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.head}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Type :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.type}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">SGST :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.sgst}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank"> CGST</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.cgst}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">IGST :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.igst}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank"> GST</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.finalGstAmount}
              </div>
            </div>
          </div>

          <div
            style={{
              margin: "1vw",
              flexWrap: "wrap",

              justifyContent: "flex-start",
            }}
            className="activejobpistbudgetbox"
          >
            {data?.files?.map((data) => {
              return (
                <div
                  style={{
                    marginRight: width > 700 ? "1vw" : "4vw",
                    marginBottom: width > 700 ? "" : "2vw",
                    marginTop: width > 700 ? "" : "2vw",
                  }}
                  className="boxofimageorpdf"
                >
                  <div
                    onClick={() => {
                      if (
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "jpeg" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "png" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "webp" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "svg" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "jpg"
                      ) {
                        handleOpenx1();
                        setImagesave(data?.file);
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    className="imageshowboxofpdf"
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "jpeg" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "png" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "webp" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "svg" ||
                        data?.file?.split(".")[
                          data?.file?.split(".")?.length - 1
                        ] === "jpg"
                          ? data?.file
                          : img111111
                      }
                      alt=""
                    />
                  </div>
                  <div className="imageshowboxofpdfname">
                    <div>
                      <PictureAsPdfIcon
                        style={{
                          color: "red",
                          fontSize: width > 700 ? "1.7vw" : "4vw",
                        }}
                      />
                    </div>
                    <div className="nameifimagedocuments">
                      {data?.file?.split("$")[1]?.slice(0, 17)}
                    </div>
                    <div className="inputfilesshowncatboxsingleimg">
                      <a href={`${data?.file}`} download>
                        {" "}
                        <CloudDownloadOutlinedIcon
                          style={{
                            fontSize: width > 700 ? "1.5vw" : "4vw",
                            margin: "0 1vw",
                          }}
                        />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openx1}
        onClose={handleClosex1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? stylex : styl3}>
          {imagesave && (
            <div className="imgbocofcerti">
              <img style={{ maxHeight: "90vh" }} src={imagesave} alt="" />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
