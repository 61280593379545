import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import { makeStyles } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "94vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  padding: "1vw",
};

export default function Skillpopupcopy({ width, data ,recall , setRecall}) {
  const navigate = useNavigate();
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);

  const handleverfyticket = () => {
    if (!data?.checked) {
      axios
        .post(
          `${API_HOST}/tickets/checkingTransactions`,
          {
            ticketId: data?.ticketId,
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          handleClosex();
          setRecall(!recall)
        });
    }
  };
  const handleCloseticket = () => {
    if (!data?.ticketSolved) { 
      axios
        .post(
          `${API_HOST}/tickets/statusUpdate`,
          {
            ticketId: data?.ticketId,
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          handleClosex();
          setRecall(!recall)
        });
    }
  };

  return (
    <div>
      <div
        style={{
          alignItems: "center",
          margin: "1vw 0vw",
          padding: width > 700 ? "0.5vw  1vw" : "2vw",
          color: "black",
          width: width > 700 ? "82vw" : "220vw",
          cursor: "pointer",
        }}
        className="navoftableblogsdata"
      >
        <div
          onClick={() => {
            navigate(`/dashboard/${data?.userName}/My Profile`);
          }}
          style={{ width: width > 700 ? "15vw" : "70vw", cursor: "pointer" }}
        >
          <span>
            <img
              style={{
                height: "fit-content",
                width: width > 700 ? "1.4vw" : "5vw",
                margin: "0vw",
                marginRight: "0.2vw",
                objectFit: "contain",
                borderRadius: "50%",
              }}
              src={data?.user_id?.media}
              alt=""
            />
          </span>
          {data?.user_id.fullName?.slice(0, 23)}
        </div>

        <div style={{ width: width > 700 ? "15vw" : "30vw" }}>
          {data?.ticketId}
        </div>
        <div style={{ width: width > 700 ? "15vw" : "70vw" }}>
          {data?.transactionId?.transactionId}
        </div>

        <div style={{ width: width > 700 ? "12vw" : "50vw" }}>
          {data?.transactionType}
        </div>
        <div style={{ width: width > 700 ? "7vw" : "30vw" }}>
          {new Date(data?.updated_at).getDate()}/
          {new Date(data?.updated_at).getMonth() + 1}/
          {new Date(data?.updated_at).getFullYear()}
        </div>
        <div
          style={{
            width: width > 700 ? "7vw" : "37vw",

            color: data?.status === "Closed" ? "green" : "red",
            fontSize: width > 700 ? "0.85vw" : "2.5vw",
            textAlign: "left",
          }}
        >
          {data?.status}
        </div>
        {
          <div
            style={{
              width: width > 700 ? "7vw" : "37vw",
              color: "#0052cc",
              fontSize: width > 700 ? "0.85vw" : "2.5vw",
              textAlign: "left",
              border: "1px solid #0052cc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              handleOpenx();
            }}
          >
            View
          </div>
        }
      </div>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style2}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Transaction Detail </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Transaction :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.transactionId?.transactionPurpose}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Transaction id :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.transactionId?.transactionId}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Transaction Type :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.transactionId?.transactionType}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Time And Date:
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.transactionId?.created_at && (
                  <span>
                    {new Date(data?.transactionId?.created_at).getHours()}:
                    {new Date(data?.transactionId?.created_at).getMinutes()}
                    {", "}
                    {new Date(data?.transactionId?.created_at).getDate()}/
                    {new Date(data?.transactionId?.created_at).getMonth() + 1}/
                    {new Date(data?.transactionId?.created_at).getFullYear()}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Amount :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                ₹ {data?.transactionId?.balance?.$numberDecimal}
              </div>
            </div>
          </div>

          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "50%" }} className="titleboxflexofbank">
                Status :
              </div>
              <div
                style={{
                  fontWeight: "400",
                  width: "100%",
                  color: data?.status === "complete" ? "#41B541" : "red",
                }}
                className="titleboxflexofbank"
              >
                {data?.transactionId?.status}
              </div>
            </div>
          </div>

          <div>
            <hr />
            <div className="profiledetailstitle">
              {" "}
              Transaction Issue Details{" "}
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  ticket id :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.ticketId}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  Issue Type :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.transactionType}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  Description :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.description}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  status :
                </div>
                <div
                  style={{
                    fontWeight: "400",
                    width: "100%",
                    color: data?.status !=='Open' ? "#41B541" : "red",
                  }}
                  className="titleboxflexofbank"
                >
                  {data?.status}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  check Status :
                </div>
                <div
                  style={{
                    fontWeight: "400",
                    width: "100%",
                    color: data?.checked ? "#41B541" : "red",
                  }}
                  className="titleboxflexofbank"
                >
                  {data?.checked ? "checked" : "not checked"}
                </div>
              </div>
            </div>
          
          </div>

          <hr style={{ color: "#000000" }} />

          <div
            style={{
              float: "right",
              marginBottom: "1vw",
              marginTop: width > 700 ? "0.3vw" : "1vw",
              width: width > 700 ? "20vw" : "70vw",
              justifyContent: "right",
            }}
            className="homejobbuttons"
          >
            {data?.ticketSolved ? (
              <button style={{ background: "white", marginRight: "2vw" }}>
                Closed
              </button>
            ) : (
              <button
                onClick={() => {
                  handleCloseticket();
                }}
                style={{ background: "white", marginRight: "2vw" }}
              >
                Close Ticket
              </button>
            )}
            {!data?.checked && (
              <button
                onClick={() => {
                  handleverfyticket();
                }}
                style={{ color: "white" }}
              >
                Verify Issue
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
