import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import StarRatings from "react-star-ratings";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import API_HOST from "../../../env";
import axios from "axios";
import img4 from "../../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router";
import img111111 from "../../../assets/unnamed.png";

import WebsiteCart from "./WebsiteCart";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const styl3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "hidden",
  padding: 1,
};
export default function BookingDetail({ jobdetail, width }) {
  const [down2, setDown2] = useState(false);
  const [down3, setDown3] = useState(false);
  const [down4, setDown4] = useState(false);

  const [data1, setdata1] = useState();
  const { id } = useParams();
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${API_HOST}/services/byId?sCartId=${id}`).then((res) => {
      setdata1(res?.data?.success?.data[0]);
    });
  }, [id]);

  const [imagesave, setImagesave] = useState();

  const [desc, setDesc] = useState("");
  const [descerr, setDescerr] = useState("");

  const [starBuyer, setStarBuyer] = useState(0);
  const [review, setReview] = useState("");

  const [setratingerror, setSetratingerror] = useState("");

  const handleendContarct = () => {
    axios
      .post(
        `${API_HOST}/services/acceptance`,
        {
          sCartId: id,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setdata1(res?.data?.success?.data);
        handleClose3();
      });
  };

  const handleAddIssue = () => {
    axios
      .post(
        `${API_HOST}/services/cancelService`,
        {
          sCartId: id,

          reason: desc,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        handleClose();
        setDesc("");

        setdata1(res?.data?.success?.data);
      });
  };

  const [ratingproducts, setRatingproducts] = useState([]);

  useEffect(() => {
    const array = [];

    if (data1?.rating > 0) {
      data1?.ratingForServices?.map((item) => {
        array?.push({
          product: item?.product_id,
          rating: item?.rating,
        });
      });
      setRatingproducts(array);
    } else {
      data1?.services?.map((item) => {
        array?.push({
          product: item?.web_id?.product_id,
          rating: 0,
        });
      });
      setRatingproducts(array);
    }
  }, [data1]);

  return (
    <div
      style={{
        height: width > 700 ? "calc(100vh - 4vw )" : "calc(100vh - 14vw )",
      }}
      className="ScrollTable"
    >
      <div
        style={{
          width: "100%",
          background: "white",
          padding: width > 700 ? "1vw 1vw" : "2vw",
          margin: "0vw",
        }}
      >
        <button
          style={{
            cursor: "pointer",
            zIndex: "100",
            padding: "0.5vw 0.7vw",
            backgroundColor: "white",
            color: "#000",
            fontSize: width > 700 ? "1.2vw" : "3.3vw",
            borderRadius: "0.3vw",
            border: "1px solid #d7d7d7",
            marginBottom: width > 700 ? "1vw" : "3vw",
            marginTop: "0vw",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon />
        </button>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="tagblue">
              {data1?.website_id?.category?.category}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                margin: "0",
                height: width > 700 ? "2.4vw" : "8vw",
                background: "#E4E4E4",
                fontSize: "400",
                marginRight: width > 700 ? "1vw" : "3vw",
              }}
              className="digitalwallate"
              onClick={handleOpen}
            >
              <span
                style={{
                  padding: "0.6vw 0.5vw",
                  fontSize: "400",
                  background: "none",
                }}
              >
                {data1?.cancelOrder ? data1?.cancelBy : "Cancel Order"}
              </span>
            </div>
          </div>
        </div>{" "}
        <div
          style={{
            fontWeight: "600",
            fontSize: width > 700 ? "1.3vw" : "3.5vw",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "1vw",
            marginLeft: "1vw",
            color: "black",
            justifyContent: "space-between",
          }}
        >
          <div>{data1?.website_id?.websiteName}</div>

          <div>
            <div>
              Status :{" "}
              <span style={{ color: "green", marginRight: "1vw" }}>
                {data1?.status}
              </span>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style1 : style2}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Cancel Booking</div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "4vw",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />

            <div style={{ marginLeft: "1vw" }} className="jobpodtedfieldtitile">
              Cancellation Reason
            </div>
            <div style={{ marginLeft: "1vw" }} className="jobpostfieldinputbox">
              <textarea
                style={{ paddingTop: "0.5vw" }}
                type="text"
                name="desc"
                value={
                  data1?.cancelOrder || data1?.deliverd ? data1?.reason : desc
                }
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
            <p
              style={{
                color: "red",
                fontSize: width > 700 ? "0.9vw" : "2.7vw",
                marginLeft: "1vw",
              }}
            >
              {descerr}
            </p>

            {!data1?.cancelOrder &&
              !data1?.serviceAccept &&
              data1?.servicePlaced && (
                <div
                  style={{ marginTop: "0.31vw" }}
                  className="handlemoreaboutskill"
                >
                  <div
                    style={{
                      cursor: "pointer",
                      width: width > 700 ? "10vw" : "30vw",
                    }}
                    className="handlecirclieaboutsave"
                    onClick={handleAddIssue}
                  >
                    Cancel Now
                  </div>
                </div>
              )}
          </Box>
        </Modal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <LocationOnOutlinedIcon
              style={{
                fontSize: width > 700 ? "1.5vw" : "4.5vw",
                fontWeight: "400",
                margin: "0.5vw 1vw",
              }}
            />
          </span>
          <span
            style={{
              fontSize: width > 700 ? (width > 700 ? "1.1vw" : "3vw") : "3vw",
              fontWeight: "500",
            }}
          >
            {data1?.website_id.remote
              ? "Remote"
              : data1?.website_id.companyAddress}
          </span>
        </div>
        <WebsiteCart
          width={width}
          websitedata={data1?.website_id}
          arrayofProducts={data1?.services}
        />
        <div
          style={{
            padding: width > 700 ? "1vw" : "2vw",
            boxShadow:
              "-7px -7px 15px #e9e9e9, 10px 10px 20px rgba(124, 124, 124, 0.4)",
            borderRadius: "1vw",
            marginLeft: "1vw",
            marginBottom: width <= 700 && "5vw",
            width: width > 700 ? "80vw" : "94vw",
          }}
          className="websiteoffercontainer "
        >
          <div style={{ marginTop: "0vw" }} className="offerheading">
            Buyer Detail <span style={{ marginLeft: "2vw" }}></span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: width <= 700 && "column-reverse",
            }}
          >
            <div className="orderdtailsinfoaddress">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "" : "100%" }}
                    htmlFor="jj"
                  >
                    <div>Fullname</div>
                    <input
                      value={data1?.fullName}
                      id="jj"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "22vw" : "100%",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label
                    style={{ width: width > 700 ? "" : "100%" }}
                    htmlFor="jj"
                  >
                    <div>Mobile</div>
                    <input
                      value={data1?.mobile}
                      id="jj"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "22vw" : "100%",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ccca">
                    <div>Email Id </div>
                    <input
                      className="websiteaddresdetailinput"
                      id="ccca"
                      disabled
                      value={data1?.emailId}
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ddda">
                    <div>State</div>
                    <input
                      value={data1?.buyerState}
                      id="ddda"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="eeea">
                    <div>Pincode</div>
                    <input
                      value={data1?.buyerPincode}
                      type="number"
                      id="eeea"
                      disabled
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "23vw" : "40vw",
                        fontWeight: "400",
                        marginRight: "2vw",
                      }}
                    ></input>
                  </label>
                </div>

                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="fffa">
                    <div>GST No</div>
                    <input
                      className="websiteaddresdetailinput"
                      id="fffa"
                      disabled
                      value={data1?.buyerGST}
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div
                style={{
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  margin: "0.3vw",
                }}
                className="offerheading"
              >
                <label style={{ width: "100%" }} htmlFor="kk">
                  <div style={{ width: "100%" }}> Address </div>
                  <textarea
                    id="kk"
                    className="websiteaddresdetailinput"
                    value={data1?.address}
                    rows={2}
                    disabled
                    style={{
                      width: width > 700 ? "100%" : "90vw",
                      fontWeight: "400",
                      fontSize: width > 700 ? "1" : "3vw",
                      lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                    }}
                  ></textarea>
                </label>
              </div>
            </div>
            <div className="orderdtailsinfopayment">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Total Taxable Price :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(data1?.totalPriceValue).toFixed(2)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Total Tax ( GST ) :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(data1?.totalGstValue).toFixed(2)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  {data1?.website_id?.chargeName} :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(data1?.website_id?.amount).toFixed(2)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  Grand Total :
                </div>
                <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                  {" "}
                  Rs {parseFloat(data1?.finalAmount).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "55%",
                marginTop: "0vw",
              }}
              className="offerheading"
            >
              Delivery Detail <span style={{ marginLeft: "2vw" }}></span>{" "}
            </div>
            {(data1?.modeOfTransport ||
              data1?.VehicleNo ||
              data1?.GRNO ||
              data1?.PONO ||
              data1?.eWAyBillNo ||
              data1?.eWayDate) && (
              <div
                style={{
                  marginTop: "0vw",
                  width: "40%",
                }}
                className="offerheading"
              >
                Transport Detail{" "}
                <span style={{ marginLeft: "1vw", fontSize: "1vw" }}>
                  {" "}
                  (If Seller Provide )
                </span>{" "}
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: width <= 700 && "column-reverse",
            }}
          >
            <div className="orderdtailsinfoaddress">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: width > 700 ? "50%" : "100%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="aaaa">
                    <div>Fullname</div>
                    <input
                      disabled
                      value={data1?.consignerName}
                      id="aaaa"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="bbba">
                    <div>Mobile </div>
                    <input
                      disabled
                      value={data1?.consignerMobile}
                      id="bbba"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ccca">
                    <div>Email Id </div>
                    <input
                      disabled
                      className="websiteaddresdetailinput"
                      id="ccca"
                      value={data1?.consignerEmailid}
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="ddda">
                    <div>State</div>
                    <input
                      disabled
                      value={data1?.consignerState}
                      id="ddda"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="eeea">
                    <div>Pincode</div>
                    <input
                      disabled
                      value={data1?.consignerPincode}
                      type="number"
                      id="eeea"
                      className="websiteaddresdetailinput"
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "23vw" : "40vw",
                        fontWeight: "400",
                        marginRight: "2vw",
                      }}
                    ></input>
                  </label>
                </div>

                <div
                  style={{
                    fontSize: width > 700 ? "1.2vw" : "3.3vw",
                    width: "50%",
                    margin: "0.3vw",
                  }}
                  className="offerheading"
                >
                  <label htmlFor="fffa">
                    <div>GST No</div>
                    <input
                      disabled
                      className="websiteaddresdetailinput"
                      id="fffa"
                      value={data1?.consignerGST}
                      style={{
                        fontSize: width > 700 ? "1vw" : "3vw",
                        width: width > 700 ? "25vw" : "40vw",
                        fontWeight: "400",
                      }}
                    ></input>
                  </label>
                </div>
              </div>

              <div
                style={{
                  fontSize: width > 700 ? "1.2vw" : "3.3vw",
                  margin: "0.3vw",
                }}
                className="offerheading"
              >
                <label style={{ width: "100%" }} htmlFor="ggga">
                  <div style={{ width: "100%" }}>Address </div>
                  <textarea
                    id="ggga"
                    className="websiteaddresdetailinput"
                    value={data1?.consignerAddress}
                    rows={2}
                    style={{
                      width: width > 700 ? "100%" : "90vw",
                      fontSize: width > 700 ? "1" : "3vw",
                      fontWeight: "400",
                      lineHeight: width > 700 ? "1.3vw" : "3.5vw",
                    }}
                    disabled
                  ></textarea>
                </label>
              </div>
            </div>
            {(data1?.modeOfTransport ||
              data1?.VehicleNo ||
              data1?.GRNO ||
              data1?.PONO ||
              data1?.eWAyBillNo ||
              data1?.eWayDate) && (
              <div className="orderdtailsinfopayment">
                {data1?.modeOfTransport ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      Mode of Transport
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      {data1?.modeOfTransport}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      Mode of Transport
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      --
                    </div>
                  </div>
                )}
                {data1?.VehicleNo ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      Vehicle No
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      {data1?.VehicleNo}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      Vehicle No
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      --
                    </div>
                  </div>
                )}
                {data1?.GRNO ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      GR No
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      {data1?.GRNO}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      GR No
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      --
                    </div>
                  </div>
                )}
                {data1?.PONO ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      PO No
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      {data1?.PONO}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      PO No
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      --
                    </div>
                  </div>
                )}
                {data1?.eWAyBillNo ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      E-way Bill no
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      {data1?.eWAyBillNo}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  ></div>
                )}
                {data1?.eWayDate ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: width > 700 ? "1.2vw" : "3.3vw",
                        margin: "0.3vw",
                      }}
                      className="offerheading"
                    >
                      E-way Date
                    </div>
                    <div style={{ fontSize: width > 700 ? "1.1vw" : "3vw" }}>
                      {" "}
                      {data1?.eWayDate}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  ></div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            height: down2
              ? `${
                  parseInt(
                    (data1?.files?.length + (width > 700 ? 3 : 1)) /
                      (width > 700 ? 4 : 2)
                  ) *
                    (width > 700 ? 13.5 : 36.5) +
                  (width > 700 ? 5 : 8)
                }vw`
              : "",
            width: width > 700 ? "" : "94vw",
          }}
          className="boxofextension"
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDown2(!down2);
            }}
            className="flexofboxextentionnav"
          >
            <div style={{ color: down2 ? "#0052CC" : "", marginLeft: "0.5vw" }}>
              {data1?.files?.length > 0
                ? "view Documents"
                : "This proposal Does not have  any Documents  !"}
            </div>
            <div>
              {!down2 ? (
                <KeyboardArrowDownIcon
                  style={{
                    fontSize: width > 700 ? "2vw" : "5vw",
                    margin: "1vw",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  style={{
                    fontSize: width > 700 ? "2vw" : "5vw",
                    margin: "1vw",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          </div>
          <div hidden={!down2}>
            <div
              style={{
                margin: "1vw",
                flexWrap: "wrap",
                marginTop: "0vw",
                justifyContent: "flex-start",
              }}
              className="activejobpistbudgetbox"
            >
              {data1?.files?.map((data) => {
                return (
                  <div
                    style={{
                      marginRight: width > 700 ? "1vw" : "4vw",
                      marginBottom: width > 700 ? "" : "2vw",
                      marginTop: width > 700 ? "" : "2vw",
                    }}
                    className="boxofimageorpdf"
                  >
                    <div
                      onClick={() => {
                        if (
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "jpeg" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "png" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "webp" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "svg" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "jpg"
                        ) {
                          handleOpenx();
                          setImagesave(data?.file);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="imageshowboxofpdf"
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        src={
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "jpeg" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "png" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "webp" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "svg" ||
                          data?.file?.split(".")[
                            data?.file?.split(".")?.length - 1
                          ] === "jpg"
                            ? data?.file
                            : img111111
                        }
                        alt=""
                      />
                    </div>
                    <div className="imageshowboxofpdfname">
                      <div>
                        <PictureAsPdfIcon
                          style={{
                            color: "red",
                            fontSize: width > 700 ? "1.7vw" : "4vw",
                          }}
                        />
                      </div>
                      <div className="nameifimagedocuments">
                        {data?.file?.split("$")[1]?.slice(0, 17)}
                      </div>
                      <div className="inputfilesshowncatboxsingleimg">
                        <a href={`${data?.file}`} download>
                          {" "}
                          <CloudDownloadOutlinedIcon
                            style={{
                              fontSize: width > 700 ? "1.5vw" : "4vw",
                              margin: "0 1vw",
                            }}
                          />{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Modal
            open={openx}
            onClose={handleClosex}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={width > 700 ? style : styl3}>
              {imagesave && (
                <div className="imgbocofcerti">
                  <img src={imagesave} alt="" />
                </div>
              )}
            </Box>
          </Modal>
        </div>
        {!data1?.cancelOrder &&
          data1?.servicePlaced &&
          data1?.serviceAccept && (
            <div
              style={{
                height: down3 ? `${width > 700 ? 43 : 180}vw` : "",
                width: width > 700 ? "" : "94vw",
              }}
              className="boxofextension"
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDown3(!down3);
                }}
                className="flexofboxextentionnav"
              >
                <div
                  style={{ color: down3 ? "#0052CC" : "", marginLeft: "0.5vw" }}
                >
                  Booking
                </div>
                <div>
                  {!down3 ? (
                    <KeyboardArrowDownIcon
                      style={{
                        fontSize: width > 700 ? "2vw" : "5vw",
                        margin: "1vw",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      style={{
                        fontSize: width > 700 ? "2vw" : "5vw",
                        margin: "1vw",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
              <div hidden={!down3}>
                <div
                  style={{
                    margin: width > 700 ? "1vw" : "0vw",
                    flexWrap: "wrap",
                    marginTop: "0vw",
                  }}
                  className="activejobpistbudgetbox"
                >
                  <div className="boxblackbackg">
                    Buyer ID <br />
                    <div>
                      <span>
                        {data1?.user_id?.userId ? data1?.user_id?.userId : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="boxblackbackg">
                    Buyer Name <br />
                    <div>
                      <span>
                        <img
                          style={{
                            width: width > 700 ? "1.5vw" : "4vw",
                            margin: "0 0.1vw",
                            borderRadius: "50%",
                          }}
                          src={data1?.user_id?.media || img4}
                          alt=""
                        />{" "}
                        {data1?.user_id?.fullName
                          ? data1?.user_id?.fullName
                          : "-"}
                      </span>
                    </div>
                  </div>

                  <div className="boxblackbackg">
                    Buyer Email <br />
                    <div>
                      <span>
                        {data1?.user_id?.emailId
                          ? data1?.user_id?.emailId
                          : "-"}
                      </span>
                    </div>
                  </div>

                  <div className="boxblackbackg">
                    Buyer Contact No <br />
                    <div>
                      <span>
                        {data1?.user_id?.contactNo
                          ? data1?.user_id?.contactNo
                          : "-"}
                      </span>
                    </div>
                  </div>

                  <div className="boxblackbackg">
                    Booking Date <br />
                    <div>
                      {data1?.servicePlacedDate && (
                        <span>
                          {new Date(data1?.servicePlacedDate).getHours()}:
                          {new Date(data1?.servicePlacedDate).getMinutes()}
                          {" , "}
                          {new Date(data1?.servicePlacedDate).getDate()}/
                          {new Date(data1?.servicePlacedDate).getMonth() + 1}/
                          {new Date(data1?.servicePlacedDate).getFullYear()}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="boxblackbackg">
                    Order Amount <br />
                    <div>
                      <span>
                        Rs {parseFloat(data1?.finalAmount).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="boxblackbackg">
                    Cancel Date <br />
                    <div>
                      {data1?.cancelDate && (
                        <span>
                          {new Date(data1?.cancelDate).getHours()}:
                          {new Date(data1?.cancelDate).getMinutes()}
                          {" , "}
                          {new Date(data1?.cancelDate).getDate()}/
                          {new Date(data1?.cancelDate).getMonth() + 1}/
                          {new Date(data1?.cancelDate).getFullYear()}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="boxblackbackg">
                    Seller Id <br />
                    <div>
                      <span>
                        {data1?.website_id?.userId?.userId
                          ? data1?.website_id?.userId?.userId
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="boxblackbackg">
                    Seller Name <br />
                    <div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            `/dashboard/${data1?.website_id?.userId?.userName}/My Profile`
                          );
                        }}
                      >
                        <img
                          style={{
                            width: width > 700 ? "1.5vw" : "4vw",
                            margin: "0 0.1vw",
                            borderRadius: "50%",
                          }}
                          src={data1?.website_id?.userId?.media || img4}
                          alt=""
                        />{" "}
                        {data1?.website_id?.userId?.fullName
                          ? data1?.website_id?.userId?.fullName
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="boxblackbackg">
                    Seller Email <br />
                    <div>
                      <span>
                        {data1?.website_id?.userId?.emailId
                          ? data1?.website_id?.userId?.emailId
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="boxblackbackg">
                    Seller Contact No <br />
                    <div>
                      <span>
                        {data1?.website_id?.userId?.contactNo
                          ? data1?.website_id?.userId?.contactNo
                          : "-"}
                      </span>
                    </div>
                  </div>

                  <div className="boxblackbackg">
                    Booking Accepted Date <br />
                    <div>
                      {data1?.serviceAcceptDate && (
                        <span>
                          {/* {data1?.deliverdDate?.slice(11, 16)}{" "}
                    {data1?.deliverdDate?.slice(0, 10)} */}
                          {new Date(data1?.serviceAcceptDate).getHours()}:
                          {new Date(data1?.serviceAcceptDate).getMinutes()}
                          {" , "}
                          {new Date(data1?.serviceAcceptDate).getDate()}/
                          {new Date(data1?.serviceAcceptDate).getMonth() + 1}/
                          {new Date(data1?.serviceAcceptDate).getFullYear()}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {!data1?.cancelOrder &&
          data1?.servicePlaced &&
          data1?.serviceAccept && (
            <div
              style={{
                height: down4
                  ? `${
                      parseInt(
                        (ratingproducts.length + (width > 700 ? 3 : 1)) /
                          (width > 700 ? 4 : 2)
                      ) *
                        (width > 700 ? 20.5 : 48) +
                      data1?.docs.length * (width > 700 ? 3 : 9) +
                      (width > 700 ? 36 : 88)
                    }vw`
                  : "",
                width: width > 700 ? "" : "94vw",
              }}
              className="boxofextension"
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDown4(!down4);
                }}
                className="flexofboxextentionnav"
              >
                <div>Booking Completion</div>
                <div>
                  {!down4 ? (
                    <KeyboardArrowDownIcon
                      style={{
                        fontSize: width > 700 ? "2vw" : "5vw",
                        margin: "1vw",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      style={{
                        fontSize: width > 700 ? "2vw" : "5vw",
                        margin: "1vw",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>

              <div hidden={!down4}>
                <div
                  style={{
                    marginLeft: "1vw",
                    marginTop: "0vw",
                    marginBottom: "1.5vw",
                  }}
                  className="flexofdtaes"
                >
                  <div className="datesofcontact">Rating For Products</div>
                </div>
                <div
                  style={{ marginLeft: width > 700 ? "1vw" : "0vw" }}
                  className="flexofoffers"
                >
                  {ratingproducts?.length > 0 &&
                    ratingproducts?.map((data) => {
                      return (
                        <div
                          style={{
                            height: width > 700 ? "18.5vw" : "46vw",
                            width: width > 700 ? "" : "42.5vw",
                          }}
                          className="Menuwebsite"
                        >
                          <div>
                            <img
                              style={{
                                cursor: "pointer",
                              }}
                              src={data?.product?.files[0]?.file}
                              alt=""
                            />
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                              className="namemenuweb"
                            >
                              {data?.product?.productName?.slice(0, 18)}
                            </div>

                            <div
                              style={{
                                marginBottom: "0vw",
                                paddingBottom: "0.3vw",
                              }}
                              className="pricemenuweb"
                            >
                              <span>Rs. {data?.product?.costPrice}</span>{" "}
                              {data?.product?.sellingPrice && (
                                <del
                                  style={{
                                    display: "flex",
                                    fontSize: width > 700 ? "0.7vw" : "2vw",
                                    margin: "0 0.3vw",
                                  }}
                                >
                                  ( Rs {data?.product?.sellingPrice})
                                </del>
                              )}
                              {data?.product?.sellingPrice &&
                                parseFloat(
                                  ((data?.product?.sellingPrice -
                                    data?.product?.costPrice) *
                                    100) /
                                    data?.product?.sellingPrice
                                ).toFixed(0) + "% off"}
                            </div>
                          </div>
                          <span
                            style={{
                              width: "10vw",
                              position: "relative",
                              bottom: "0.5vw",
                              left: "1vw",
                            }}
                          >
                            <StarRatings
                              rating={data?.rating}
                              starRatedColor="#0052CC"
                              starDimension={width > 700 ? "1.6vw" : "4vw"}
                              starSpacing={width > 700 ? "0.4vw" : "1vw"}
                              numberOfStars={5}
                              name="rating"
                            />
                          </span>
                        </div>
                      );
                    })}
                </div>
                <hr style={{ width: "96%", marginLeft: "0vw" }} />
                <div
                  style={{
                    marginLeft: "1vw",
                    marginTop: "0vw",
                    marginBottom: "1vw",
                  }}
                  className="flexofdtaes"
                >
                  <div className="datesofcontact">
                    Review And Rating For Website/Catalogue
                  </div>
                </div>
                <div className="chatcontaract">
                  <div
                    style={{
                      width: width > 700 ? "80vw" : "100%",
                      padding: "0vw",
                      height: "fit-content",
                    }}
                    className="chatboxescontact"
                  >
                    <div
                      style={{ marginLeft: "1vw", marginTop: "0vw" }}
                      className="flexofdtaes"
                    >
                      <div className="datesofcontact">Ratings</div>
                    </div>
                    <div className="workhistryboxdate">
                      <span
                        style={{
                          width: "10vw",
                          position: "relative",

                          left: "1vw",
                        }}
                      >
                        <StarRatings
                          rating={data1?.rating ? data1?.rating : starBuyer}
                          starRatedColor="#0052CC"
                          starDimension={width > 700 ? "1.6vw" : "4vw"}
                          starSpacing={width > 700 ? "0.3vw" : "1vw"}
                          numberOfStars={5}
                          name="rating"
                        />
                      </span>
                    </div>
                    {setratingerror && (
                      <div
                        style={{
                          color: "red",
                          fontSize: width > 700 ? "1vw" : "3vw",
                          margin: "1vw",
                        }}
                      >
                        {setratingerror}
                      </div>
                    )}
                    <div
                      style={{
                        left: "0vw",
                        width: "96%",
                        margin: "2vw 1vw 2vw 1vw",
                        display: "block",
                      }}
                      className="loginfield"
                    >
                      <div
                        style={{ marginBottom: "1vw" }}
                        className="jobpodtedfieldtitile"
                      >
                        Review
                      </div>
                      <div>
                        <textarea
                          name=""
                          id=""
                          className="reviewbox"
                          rows="10"
                          style={{ fontSize: width > 700 ? "1vw" : "3vw" }}
                          value={data1?.review ? data1?.review : review}
                          onChange={(e) => {
                            if (!data1?.review) {
                              setReview(e.target.value);
                            }
                          }}
                        ></textarea>
                      </div>
                    </div>
                    {data1?.docs?.length > 0 &&
                      data1?.docs?.map((dataqq) => {
                        return (
                          <div
                            style={{
                              width: "fit-content",
                              marginTop: "0vw",
                              marginBottom: "1vw",
                            }}
                            className="inputfilesshowncatboxsingle"
                          >
                            <div className="inputfilesshowncatboxsingleimg">
                              <img src={img1} alt="" />
                            </div>
                            <div className="fileselctednamecate">
                              {dataqq?.docx?.split("$")[1]?.slice(0, 22)}
                            </div>
                            <div className="inputfilesshowncatboxsingleimg">
                              <a href={`${dataqq?.docx}`} download>
                                {" "}
                                <CloudDownloadOutlinedIcon
                                  style={{
                                    fontSize: width > 700 ? "1.5vw" : "4vw",
                                    margin: "0 1vw",
                                  }}
                                />{" "}
                              </a>
                            </div>
                          </div>
                        );
                      })}{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
