import * as React from "react";

import "./contract.css";
import img from "../../../assets/Logo/tools 44 shorten.png";

const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
        }}
      >
        <div
          slot="template"
          style={{
            width: "100%",
            textAlign: "center",
            padding: "6px",
            marginRight: "10px",
            height: "0.7cm",
            display: "flex",
            alignItems: "center",
            justifyContent:"flex-end",
            bottom: 0,
          }}
        >
          <span className='contractFooter' style={{ fontSize: "18px", color: "black" }}>
            Contract created on{" "}
          </span>
          <div className="contractFooterimg">
            <img
              src={img}
              style={{ width: "42px", height: "15px" }}
              alt="invalid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageTemplate;
