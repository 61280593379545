import React, { useEffect, useState } from "react";

import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Skillpopupcopy from "./Skillpopupcopy";

import searchicon from "../../../assets/Dashboard/Iconly-Light-Search.svg";
import { useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    input: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "0.91vw",
      color: "#263238",
      border: "yellow !important",
    },
  }));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: "2vw",
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 1,
  };
export default function LoanApplication({ width }) {
  const [page1, setPage1] = useState(1);

  const [setSelectedCategory1, setSetSelectedCategory1] = useState("");

  const [allusers1, setAllusers1] = useState([]);

  const [recall1, setRecall1] = useState(false);
  const [selecteddelete1, setSelecteddelete1] = useState([]);
  const [sort, setSort] = useState("date");

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/loan/adminViewApplication?pageSize=10&pageNumber=${page1}&search=${setSelectedCategory1}&${sort}=true`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setAllusers1(res?.data?.success?.data);
        window.scrollTo(0, 0, { behavior: "smooth" });
      });
  }, [page1, setSelectedCategory1, recall1, sort]);
  const navigate = useNavigate();

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [dateerror, setdateerror] = useState("");
  const [datestart, setDatestart] = useState();
  const [dateend, setDateend] = useState();
  const handleexport = () => {
    axios
      .post(
        `${API_HOST}/loan/exportApplicant`,
        {
          to: dateend,
          from: datestart,
         
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        window.location.href = response.data?.success?.data;
        handleClose1();
      });
  };

  const handleexport1 = () => {
    axios
      .post(
        `${API_HOST}/loan/exportAll`,
        {},
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        window.location.href = response.data?.success?.data;
        handleClose1();
      });
  };
  return (
    <div style={{ paddingTop: "1vw" }} className="BrowseWorkMain-cntainer">
   
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          height: width > 700 ? "4vw" : "10vw",
          marginBottom: "1vw",
          marginTop: width > 700 ? "0" : "1vw",
        }}
        className="searchboxcontainer"
      >
        <div
          style={{
            height: width > 700 ? "3vw" : "9vw",
            paddingRight: "0.3vw",
            marginTop: "0vw",
          }}
          className="serachjobbox"
        >
          <span>
            <img
              src={searchicon}
              style={{ width: width > 700 ? "1.7vw" : "5vw" }}
              alt=""
              alt=""
            />
          </span>
          <input
            type="text"
            placeholder={"search here .."}
            value={setSelectedCategory1}
            onChange={(e) => {
              setSetSelectedCategory1(e.target.value);
            }}
          />
        </div>
        <button
                onClick={()=>handleOpen1()}
          style={{
            marginRight: width > 700 ? "2vw" : "0vw",
            width: width > 700 ? "12vw" : "32vw",
            marginTop: width > 700 ? "0vw" : "2vw",
            float: "right",
          }}
          className="hb-buttonx"
        >
          <span>Export Applications</span>
        </button>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div
              style={{
                maxHeight: "80vh",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">Add Filters</div>
                <div className="profiledetailnavmanu">
                  <div>
                    <CloseIcon
                      onClick={handleClose1}
                      style={{
                        fontSize: width > 700 ? "1.5vw" : "4vw",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr style={{ color: "#000000" }} />
              <div className="jobpodtedfieldtitile"> Select Dates </div>

              <div
                style={{ display: "flex", alignItems: "center", width: "97%" }}
              >
                <div style={{ width: "50%" }}>
                  <div className="jobpodtedfieldtitile">From</div>
                  <div className="jobpostfieldinputbox">
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={datestart}
                      className="input-homejobformdate"
                      name=""
                      id=""
                      maxlength="4"
                      onChange={(e) => {
                        setDatestart(e.target.value);
                        setdateerror("");
                      }}
                    />
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <div className="jobpodtedfieldtitile">To</div>

                  <div className="jobpostfieldinputbox">
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={dateend}
                      className="input-homejobformdate"
                      name=""
                      id=""
                      maxlength="4"
                      onChange={(e) => {
                        setDateend(e.target.value);
                        setdateerror("");
                      }}
                    />
                  </div>
                </div>
              </div>
              <p style={{ color: "red" }} className="redp">
                {dateerror}
              </p>

              <div
                style={{ marginTop: "0.31vw" }}
                className="handlemoreaboutskill"
              >
                <div
                  style={{
                    cursor: "pointer",
                    marginRight: "2vw",
                    width: width > 700 ? "13vw" : "26vw",
                  }}
                  className="handlecirclieaboutsave"
                  onClick={() => {
                    handleexport1();
                  }}
                >
                  Export All Data
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="handlecirclieaboutsave"
                  onClick={() => {
                    if (!datestart || !dateend) {
                      setdateerror("Please Enter Date");
                    } else {
                      handleexport();
                    }
                  }}
                >
                  Export
                </div>
              </div>
            </div>
          </Box>
        </Modal>
 
      </div>

      <div
        style={{ overflowX: "scroll", width: width > 700 ? "82vw" : "92vw" }}
        className="ScrollTable"
      >
        <div
          style={{
            margin: "0vw 1vw",
            padding: "0vw 1vw",
            width: width > 700 ? "80vw" : "192vw",
            marginTop: width > 700 && "0.01vw",
          }}
          className="navoftableblogs"
        >
          <div style={{ width: width > 700 ? "16vw" : "30vw" }}>
            Loan Purpose
          </div>
          <div style={{ width: width > 700 ? "12vw" : "50vw" }}>Loan type</div>
          <div style={{ width: width > 700 ? "15vw" : "30vw" }}>Loan Term</div>

          <div style={{ width: width > 700 ? "15vw" : "40vw" }}>Profession</div>

          <div style={{ width: width > 700 ? "8vw" : "30vw" }}>Date</div>

          <div style={{ width: width > 700 ? "9vw" : "15vw" }}> Status </div>
          <div style={{ width: width > 700 ? "9.5vw" : "15vw" }}> </div>
        </div>
        {allusers1?.length > 0 &&
          allusers1?.map((data, index) => {
            return (
              <Skillpopupcopy
                width={width}
                data={data}
                index={index}
                page={page1}
                setSelecteddelete={setSelecteddelete1}
                selecteddelete={selecteddelete1}
                setRecall={setRecall1}
                recall={recall1}
              />
            );
          })}

        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div
            onClick={() => {
              if (page1 > 1) {
                setPage1(page1 - 1);
              }
            }}
          >
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page1 - 1 > 0) {
                  setPage1(page1 - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page1 - 4 > 0 ? false : true}
            onClick={() => setPage1(page1 - 4)}
          >
            {page1 - 4}
          </div>
          <div
            hidden={page1 - 3 > 0 ? false : true}
            onClick={() => setPage1(page1 - 3)}
          >
            {page1 - 3}
          </div>
          <div
            hidden={page1 - 2 > 0 ? false : true}
            onClick={() => setPage1(page1 - 2)}
          >
            {page1 - 2}
          </div>
          <div
            hidden={page1 - 1 > 0 ? false : true}
            onClick={() => setPage1(page1 - 1)}
          >
            {page1 - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page1}</div>
          <div onClick={() => setPage1(page1 + 1)}>{page1 + 1}</div>
          <div onClick={() => setPage1(page1 + 2)}>{page1 + 2}</div>
          <div onClick={() => setPage1(page1 + 3)}>{page1 + 3}</div>

          <div onClick={() => setPage1(page1 + 1)}>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage1(page1 + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
