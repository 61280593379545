import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box"; 
import CloseIcon from "@mui/icons-material/Close";
import coin_symbol from '../../../assets/wallet/coin_symbol.png'
// import axios from "axios";
// import API_HOST from "../../../env";
// import { makeStyles } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "94vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  padding: "1vw",
};
export default function Skillpopupcopy({ width, data }) {
  const navigate = useNavigate();
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);

  return (
    <div
      style={{
        alignItems: 'center',
        padding: '0.75vw 1vw',
        margin: width > 700 ? '1vw 0vw' : '2vw 0vw',
        width: width > 700 ? '82vw' : '194vw',
      }}
      className='navoftableblogsdata'
    >
      <div
        onClick={() => {
          navigate(`/dashboard/${data?.userName}/My Profile`);
        }}
        style={{ width: width > 700 ? '15vw' : '70vw', cursor: 'pointer' }}
      >
        <span>
          <img
            style={{
              height: 'fit-content',
              width: width > 700 ? '1.4vw' : '5vw',
              margin: '0vw',
              marginRight: '0.2vw',
              objectFit: 'contain',
              borderRadius: '40%',
            }}
            src={data?.client_refrence_id?.media}
            alt=''
          />
        </span>
        {data?.client_refrence_id.fullName?.slice(0, 23)}
      </div>

      <div style={{ width: '15vw' }}>{data?.phonepeTransactionId}</div>
      <div style={{ width: '15vw' }}>{data?.transactionId}</div>
      <div style={{ width: '7vw' }}>
        {data?.money_in_wallet ? 'Added' : 'not Added'}
      </div>
      <div style={{ width: '10vw' }}>
        {data?.created_at && (
          <span>
            {new Date(data?.created_at).getHours()}:
            {new Date(data?.created_at).getMinutes()}
            {', '}
            {new Date(data?.created_at).getDate()}/
            {new Date(data?.created_at).getMonth() + 1}/
            {new Date(data?.created_at).getFullYear()}
          </span>
        )}
      </div>
      <div style={{ width: '7vw' }}>
        ₹ {data?.phonepe_amounttotal?.$numberDecimal}
      </div>
      <div
        style={{
          width: '8vw',
          color: data?.phonepe_payment_status === 'SUCCESS' ? '#41B541' : 'red',
          fontWeight: '500',
        }}
      >
        {data?.phonepe_payment_status}
      </div>

      <button
        style={{ height: width > 700 ? '2vw' : '4.5vw' }}
        className='hb-buttonx'
        onClick={() => {
          handleOpenx();
        }}
      >
        View Detail
      </button>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={width > 700 ? style : style2}>
          <div
            style={{ padding: '1vw' }}
            className='profiletitleandmenunav'
          >
            <div className='profiledetailstitle'>
              {' '}
              Stripe Transaction Detail{' '}
            </div>
            <div className='profiledetailnavmanu'>
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? '1.5vw' : '4vw',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: '#000000' }} />
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                Payment Transaction Id :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.payment_transactionId}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                PhonePe Transaction id :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.phonepeTransactionId}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                Transaction id :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.transactionId}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                money_in_wallet:
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.money_in_wallet ? 'Added' : 'not Added'}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                Time And Date:
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.created_at && (
                  <span>
                    {new Date(data?.created_at).getHours()}:
                    {new Date(data?.created_at).getMinutes()}
                    {', '}
                    {new Date(data?.created_at).getDate()}/
                    {new Date(data?.created_at).getMonth() + 1}/
                    {new Date(data?.created_at).getFullYear()}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                Amount :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                <div
                  className=''
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.3rem',
                  }}
                >
                  <div style={{ width: '18px', height: '18px' }}>
                    <img
                      src={coin_symbol}
                      style={{ width: '100%', height: '100%' }}
                      alt='coin_symbol'
                    />
                  </div>
                  <span style={{ marginTop: '0.1rem' }}>
                    {data?.phonepe_amounttotal?.$numberDecimal}
                  </span>
                </div>{' '}
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                Method :
              </div>
              <div
                style={{ fontWeight: '400', width: '100%' }}
                className='titleboxflexofbank'
              >
                {data?.phonepe_paymentmode}
              </div>
            </div>
          </div>

          <div
            style={{ width: '100%', paddingTop: '0vw' }}
            className='flexofbankdetails'
          >
            <div
              style={{ width: '100%' }}
              className='flexofbankdetails'
            >
              <div
                style={{ width: '40%' }}
                className='titleboxflexofbank'
              >
                Status :
              </div>
              <div
                style={{
                  fontWeight: '400',
                  width: '100%',
                  color:
                    data?.phonepe_payment_status === 'SUCCESS'
                      ? '#41B541'
                      : 'red',
                }}
                className='titleboxflexofbank'
              >
                {data?.phonepe_payment_status}
              </div>
            </div>
          </div>

          <hr style={{ color: '#000000' }} />

          <div
            style={{
              float: 'right',
              marginBottom: '1vw',
              marginTop: width > 700 ? '0.3vw' : '1vw',
              width: width > 700 ? '20vw' : '70vw',
              justifyContent: 'right',
            }}
            className='homejobbuttons'
          >
            <button
              onClick={() => {
                handleClosex();
              }}
              style={{ background: 'white', marginRight: '2vw' }}
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
