import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import img2 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Edit.svg";
import img3 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Document.svg";
import img4 from "../../../assets/Dashboard/Skill center – 2/Iconly-Light-outline-Delete.svg";
import axios from "axios";
import API_HOST from "../../../env";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",

  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const stylex = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  maxHieght: "90vh",
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};
const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
};

const styl3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "91vw",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
};

export default function Skillpopupcopy({ width, data, setRecall, recall }) {
  const navigate = useNavigate();

  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);
  const handledelete = () => {
    axios
      .post(
        `${API_HOST}/loan/deleteApplication`,
        { loanId: data?.loanId },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then(() => {
        handleClosex3();
        setRecall(!recall);
      });
  };



  const [openx3, setOpenx3] = React.useState(false);
  const handleOpenx3 = () => setOpenx3(true);
  const handleClosex3 = () => setOpenx3(false);

  const handlewithdraw = () => {
    axios
      .post(
        `${API_HOST}/loan/adminActions`,
        { loanId: data?.loanId, status: "approved" },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then(() => {
        handleClosex();
        setRecall(!recall);
      });
  };
  const handlereject = () => {
    axios
      .post(
        `${API_HOST}/loan/adminActions`,
        { loanId: data?.loanId, status: "rejected" },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then(() => {
        handleClosex();
        setRecall(!recall);
      });
  };

  return (
    <div
      style={{
        alignItems: "center",
        margin: "1vw 0vw",
        padding: width > 700 ? "0.2vw  2vw" : "2vw",
        color: "black",
        width: width > 700 ? "80vw" : "192vw",
      }}
      className="navoftableblogsdata"
    >
      <div style={{ width: width > 700 ? "16vw" : "30vw" }}>
        {data?.loanPurpose?.slice(0, 30)}
      </div>
      <div style={{ width: width > 700 ? "12.1vw" : "50vw" }}>
        {data?.loanType?.slice(0, 25)}
      </div>
      <div style={{ width: width > 700 ? "15vw" : "30vw" }}>
        {data?.preferredLoanTerm?.slice(0, 30)}
      </div>
      <div style={{ width: width > 700 ? "15vw" : "40vw" }}>
        {data?.profession?.slice(0, 30)}
      </div>
      <div style={{ width: width > 700 ? "8vw" : "30vw" }}>
        {data?.created_at && (
          <span>
            {new Date(data?.created_at).getDate()}/
            {new Date(data?.created_at).getMonth() + 1}/
            {new Date(data?.created_at).getFullYear()}
          </span>
        )}
      </div>
      <div
        style={{
          width: width > 700 ? "8vw" : "30vw",
          color: data?.status === "pending" ? "red" : "green",
        }}
      >
        {data?.status}
      </div>
      <div
        style={{
          width: width > 700 ? "8vw" : "12vw",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <img
          onClick={() => handleOpenx()}
          style={{
            width: width > 700 ? "1.82vw" : "4vw",
            cursor: "pointer",
            marginRight: "1vw",
            filter: "brightness(0)",
          }}
          src={img3}
          alt=""
        />

        <img
          onClick={() => handleOpenx3()}
          style={{
            width: width > 700 ? "1.82vw" : "4vw",
            cursor: "pointer",
          }}
          src={img4}
          alt=""
        />
      </div>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style2}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Application Detail </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div className="titleboxflexofbank">Loan purpose :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loanPurpose}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Date :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.created_at?.slice(0, 10)}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Monthly Income :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.monthlyIncome}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Loan Amount :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.annualIncome}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Exprience :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.exprience}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">User</div>
              <div
                style={{ fontWeight: "400" ,cursor:"pointer" }}
                className="titleboxflexofbank"
                onClick={() => {
                  navigate(
                    `/dashboard/${data?.userName}/My Profile`
                  );
                }}
              >
                {data?.user_id?.fullName}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Mobile :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.mobile}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Email</div>
              <div
                style={{ fontWeight: "400" ,cursor:"pointer" }}
                className="titleboxflexofbank"
               
              >
                {data?.emailId}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">userName :</div>
              <div  onClick={() => {
                  navigate(
                    `/dashboard/${data?.userName}/My Profile`
                  );
                }} style={{ fontWeight: "400" ,cursor:"pointer" }} className="titleboxflexofbank">
                {data?.userName}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Loan Type :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.loanType}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Loan Term</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.preferredLoanTerm}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Profession :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.profession}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank"> status</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.status}
              </div>
            </div>
          </div>
          <hr />
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div className="titleboxflexofbank">Lend Finance :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.finacer_id?.title}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Finance Provider :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.finacer_id?.organizerName_bankName}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank"> Finance by :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.finacer_id?.postedByName}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingTop: "0vw",
              alignItems: "flex-start",
            }}
            className="flexofbankdetails"
          >
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank">Finance limit :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.finacer_id?.finaceLimit}
              </div>
            </div>
            <div
              style={{ width: "50%", alignItems: "flex-start" }}
              className="flexofbankdetails"
            >
              <div className="titleboxflexofbank"> Intrest rate :</div>
              <div style={{ fontWeight: "400" }} className="titleboxflexofbank">
                {data?.finacer_id?.intrestRate}
              </div>
            </div>
          </div>

          <div
            style={{
              float: "right",
              marginBottom: "1vw",
              marginTop: width > 700 ? "1vw" : "1vw",
              width: "fit-content",
            }}
            className="homejobbuttons"
          >
            <button
              style={{ background: "white" }}
              onClick={() => handleClosex()}
            >
              Close
            </button>

            {!data?.applicationApproved && data?.status !== "withdrawl" && (
              <button
                style={{ color: "white", marginLeft: "2vw" }}
                onClick={handlereject}
              >
                Reject
              </button>
            )}
            {!data?.applicationApproved && data?.status !== "withdrawl" && (
              <button
                style={{ color: "white", marginLeft: "2vw" }}
                onClick={handlewithdraw}
              >
                Approve
              </button>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openx3}
        onClose={handleClosex3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style1 : style3}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle">Delete Application</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex3}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div
            style={{ left: "0vw", width: "100%", padding: "1vw" }}
            className="loginfield"
          >
            Are you sure Want to Delete this Application ?
          </div>

          <hr style={{ color: "#000000" }} />
          <div
            style={{ marginTop: "0.31vw", padding: "1vw" }}
            className="handlemoreaboutskill"
          >
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClosex3}
            >
              Cancel
            </div>
            <div
              onClick={() => {
                handledelete();
              }}
              style={{ cursor: "pointer", background: "red" }}
              className="handlecirclieaboutsave"
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
