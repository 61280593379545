import React, { useEffect, useState } from "react";
import "./Team.css";
import { useNavigate } from "react-router";
import img2 from "../../../assets/My profile – 28/Component 85 – 16 (1).svg";
import edit_logo from "../../../assets/My profile – 28/edit_logo.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import img3 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
import imageCompression from "browser-image-compression";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

export default function TeamCard({ data, recall, setRecall, setcertificated }) {
  const width = 900;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const deleltecatalogue = () => {
    axios
      .post(
        `${API_HOST}/team/removeTeam`,
        {
          _id: data?._id,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )

      .then((res) => {
        setRecall(!recall);
        handleClose();
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      //   await uploadToServer(compressedFile); // write your own logic
      await setIamge(new File([compressedFile], `${compressedFile?.name}`));
    } catch (error) {
      console.log(error);
    }
  }
  const [iamge, setIamge] = useState();

  const [name, setname] = useState("");
  const [role, setcRole] = useState("");
  const [Experience, setExperience] = useState("");
  useEffect(() => {
    setname(data?.fullName);
    setcRole(data?.role);
    setExperience(data?.experience);
  }, [data]);

  const handleaddcertificate = () => {
    const formdata = new FormData();

    formdata.append("_id", data?._id);
    formdata.append("fullName", name);
    formdata.append("role", role);
    formdata.append("experience", Experience);

    if (iamge) {
      formdata.append("image", iamge);
    }
    axios
      .post(`${API_HOST}/team/editTeam`, formdata, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        axios.get(`${API_HOST}/team/viewTeam`).then((res) => {
          setcertificated(res?.data?.success?.data);
          handleClose1();
        });
      });
  };
  return (
    <div>
      <div
        style={{
          height: "25vw",
          width: "17vw",
          marginRight: "1vw",
          marginLeft: "1vw",
        }}
        className="carouselbox"
      >
        <div style={{ height: "15vw" }} className="hc-cboximg">
          <img
            style={{
              objectFit: "cover",
              height: "15vw",
            }}
            src={data?.image}
            alt=""
          />

          <div
            style={{
              height: "15vw",
              position: "relative",
              bottom: "15vw",

              display: "flex",
            }}
            className="pportimg2"
          >
            <div
              style={{ width: "fit-content", display: "flex" }}
              className="porfolioprofilemenu"
            >
              <div onClick={handleOpen1} className="porfolioprofilemenu">
                <img src={edit_logo} alt="" />
              </div>

              <div onClick={handleOpen} className="porfolioprofilemenu">
                <img src={img2} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: "1.3vw",
            width: "89%",
          }}
          className="hc-cboxname"
        >
          {data.fullName}
        </div>
        <div
          style={{
            fontSize: "1.1vw",
            width: "90%",
          }}
          className="hc-cboxdesc"
        >
          {data?.role}
        </div>
        <div
          style={{
            fontSize: "0.9vw",
            width: "90%",
          }}
          className="hc-cboxdesc"
        >
          {data?.experience}
        </div>
      </div>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div style={{ maxHeight: "90vh", overflowY: "scroll" }}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">Edit team member</div>
              <div className="profiledetailnavmanu">
                <CloseIcon
                  onClick={handleClose1}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    position: "relative",
                    right: "1vw",
                  }}
                />
              </div>
            </div>
            <hr style={{ color: "#000000" }} />
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Member Name"
                variant="outlined"
                value={name}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setname(e.target.value)}
              />
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Role"
                variant="outlined"
                value={role}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setcRole(e.target.value)}
              />
            </div>
            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                id="outlined-basic"
                label="Experience"
                variant="outlined"
                value={Experience}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                inputProps={{ className: classes.input }}
                onChange={(e) => setExperience(e.target.value)}
              />
            </div>

            <div
              style={{
                width: width > 700 ? "99%" : "99%",
                height: width > 700 ? "10vw" : "20vw",
              }}
              className="inputfilebox"
            >
              <div>
                <label htmlFor="inputctaelogfile">
                  <div className="inputicon">
                    <img src={img} alt="" />
                  </div>
                  <div className="inputcateaddformfile">
                    Drag and Drop ,Browse to upload Banner Image
                  </div>
                  <input
                    type="file"
                    id="inputctaelogfile"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                    hidden
                  />
                </label>
              </div>
            </div>

            <div className="inputfilesshowncatboxsingle">
              <div className="inputfilesshowncatboxsingleimg">
                <img
                  style={{ width: width > 700 ? "" : "5vw" }}
                  src={img3}
                  alt=""
                />
              </div>
              <div className="fileselctednamecate">
                {iamge ? iamge?.name.slice(0, 50) : data?.image}
              </div>
            </div>

            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose1}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={handleaddcertificate}
              >
                SAVE
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle"> Delete team member</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "2.7vw",
              fontWeight: "300",
            }}
            className="profiledetailstitle"
          >
            The action will delete this member From teams.
          </div>
          <div
            style={{
              color: "gray",
              fontSize: width > 700 ? "1vw" : "2.7vw",
              fontWeight: "400",
              marginBottom: "5vw",
            }}
            className="profiledetailstitle"
          >
            Are you sure you want to delete this team member
          </div>
          <hr style={{ color: "#000000" }} />
          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={deleltecatalogue}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
