import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { SearchSharp } from "@mui/icons-material";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Slider from "react-slick";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ProfileCatelogdetails1 from "./ProfileCatelogDetails1";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import img1 from "../../../assets/Jobs/Iconly-Light-Delete.svg";
import img from "../../../assets/Jobs/Iconly-Light-Edit.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 3,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxHeight: "90vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};
const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div style={{ height: "0vw" }}>
      <div
        style={{
          display: "flex",
          background: "#0052CC",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          zIndex: "50",
          cursor: "pointer",
          bottom: props?.width > 700 ? "9vw" : "25vw",
          left: props?.width > 700 ? "64.5vw" : "88vw",
          width: props?.width > 700 ? "2.5vw" : "7vw",
          height: props?.width > 700 ? "2.5vw" : "7vw",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
        }}
        onClick={onClick}
      >
        <ArrowForward
          style={{
            width: props?.width > 700 ? "2vw" : "6vw",
            height: props?.width > 700 ? "2vw" : "6vw",
            objectFit: "cover",
            fontSize: "2vw",
            color: "white",
          }}
          alt=""
        />
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div style={{ height: "0vw" }}>
      <div
        style={{
          display: "flex",
          background: "#0052CC",
          position: "relative",
          zIndex: "50",
          cursor: "pointer",
          top: props?.width > 700 ? "6vw" : "20.45vw",
          left: props?.width > 700 ? "-0.25vw" : "-3vw",
          width: props?.width > 700 ? "2.5vw" : "7vw",
          height: props?.width > 700 ? "2.5vw" : "7vw",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <ArrowBack
          style={{
            width: props?.width > 700 ? "2vw" : "6vw",
            height: props?.width > 700 ? "2vw" : "6vw",
            objectFit: "cover",
            fontSize: "2vw",
            color: "white",
          }}
          alt=""
        />
      </div>
    </div>
  );
}

export default function PaidProduct({ width }) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [totalpages, settotalpages] = useState(1);
  const [setSelectedCategory, setSetSelectedCategory] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [newheadline, setNewheadline] = useState("");

  const [callagename, setCallagename] = useState("");
  const [recall, setRecall] = useState(false);
  const [eventsdata, setEventsdata] = useState([]);

  const handleCreate = () => {
    const formdata = new FormData();
    formdata.append("headLine", callagename);
    axios
      .post(`${API_HOST}/tops/addTopProducts`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose();
      });
  };

  useEffect(() => {
    axios
      .get(`${API_HOST}/tops/viewTopProduct`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setEventsdata(res?.data?.success?.data);
        window.scrollTo(0, 0, { behavior: "smooth" });
      });
  }, [recall]);
  let dragging = false;
  let settings1 = {
    dots: false,
    speed: 700,
    rows: 1,
    adaptiveHeight: true,
    swipeToSlide: true,
    className: "slider variable-width",
    variableWidth: true,
    cssEase: "ease-out",
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),
    nextArrow: <SampleNextArrow width={width} />,
    prevArrow: <SamplePrevArrow width={width} />,
  };

  const [selectforedit, setSelectforedit] = useState();

  const handleedit = () => {
    const formdata = new FormData();
    formdata.append("headLine", newheadline);
    formdata.append("topsId", selectforedit?.topsId);
    axios
      .post(`${API_HOST}/tops/editTopProducts`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        handleClose1();
      });
  };


  const handledelete =()=>{
    const formdata = new FormData();
    
    formdata.append("topsId", selectforedit?.topsId);
    axios
    .post(`${API_HOST}/tops/deleteTop`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      },
    })
    .then((res) => {
      setRecall(!recall);
      handleClose2();
    });
  }


  return (
    <div className="BrowseWorkMain-cntainer">
      <div className="searchboxcontainer">
        <div className="serachjobbox">
          <span>
            <SearchSharp style={{ fontSize: "1.7vw" }} />
          </span>
          <input
            type="text"
            placeholder="Search headline"
            value={setSelectedCategory}
            onChange={(e) => {
              setSetSelectedCategory(e.target.value);
            }}
          />
        </div>

        <div
          onClick={() => {
            handleOpen();
          }}
          className="digitalwallate"
        >
          <span style={{ color: "#0052CC" }}>Add New Headline</span>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div className="profiletitleandmenunav">
              <div className="profiledetailstitle">
                Add marketplace Headline
              </div>
              <div className="profiledetailnavmanu">
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#000000" }} />

            <div style={{ left: "0vw", width: "100%" }} className="loginfield">
              <TextField
                value={callagename}
                id="outlined-basic"
                label="Headline"
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    fontSize: width > 700 ? "1vw" : "3vw",
                    fontFamily: "Poppins",
                    fontStyle: "500",
                    fontWeight: "500",
                    color: "black",
                  },
                }}
                onChange={(e) => {
                  setCallagename(e.target.value);
                }}
                inputProps={{ className: classes.input }}
              />
            </div>

            <div
              style={{ marginTop: "0.31vw" }}
              className="handlemoreaboutskill"
            >
              <div
                style={{
                  background: "white",
                  color: "black",
                  cursor: "pointer",
                }}
                className="handlecirclieaboutsave"
                onClick={handleClose}
              >
                Cancel
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="handlecirclieaboutsave"
                onClick={handleCreate}
              >
                Create
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="ScrollTable">
        {eventsdata?.map((data) => {
          return (
            <div className="LatestBlogsContainer">
              <div
                style={{
                  fontSize: width > 700 ? "1.35vw" : "4vw",
                  padding: width > 700 ? "0.35vw" : "1vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className=""
              >
                {data?.headLine}

                <div style={{ width: "7vw", height: "2vw", display: "flex" }}>
                  <img
                    onClick={() => {
                      handleOpen1();
                      setSelectforedit(data);
                      setNewheadline(data?.headLine);
                    }}
                    style={{
                      margin: "0vw 0.5vw",
                      marginRight: "1vw",
                      width: "2vw ",
                      height: "2vw",
                      borderRadius: "50%",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    src={img}
                    alt=""
                  />
                  <img
                    onClick={() => {
                      handleOpen2();
                      setSelectforedit(data);
                    }}
                    style={{
                      margin: "0vw 0.5vw",
                      marginRight: "3vw",
                      width: "2vw ",
                      height: "2vw",
                      borderRadius: "50%",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    src={img1}
                    alt=""
                  />
                </div>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {data?.topProduct?.map((data) => {
                  return (
                    <div>
                      <ProfileCatelogdetails1
                        data={data?.product_id}
                        width={width}
                        dragging={dragging}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page - 1 > 0) {
                  setPage(page - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page - 4 > 0 ? false : true}
            onClick={() => setPage(page - 4)}
          >
            {page - 4}
          </div>
          <div
            hidden={page - 3 > 0 ? false : true}
            onClick={() => setPage(page - 3)}
          >
            {page - 3}
          </div>
          <div
            hidden={page - 2 > 0 ? false : true}
            onClick={() => setPage(page - 2)}
          >
            {page - 2}
          </div>
          <div
            hidden={page - 1 > 0 ? false : true}
            onClick={() => setPage(page - 1)}
          >
            {page - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page}</div>
          <div onClick={() => setPage(page + 1)}>{page + 1}</div>
          <div onClick={() => setPage(page + 2)}>{page + 2}</div>
          <div onClick={() => setPage(page + 3)}>{page + 3}</div>

          <div onClick={() => setPage(page + 1)}>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Edit marketplace Headline</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose1}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />

          <div style={{ left: "0vw", width: "100%" }} className="loginfield">
            <TextField
              value={newheadline}
              id="outlined-basic"
              label="Headline"
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              onChange={(e) => {
                setNewheadline(e.target.value);
              }}
              inputProps={{ className: classes.input }}
            />
          </div>

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose1}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="handlecirclieaboutsave"
              onClick={handleedit}
            >
              Edit
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style1}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Delete Promotion</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose2}
                  style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          Are You sure want to delete this '{selectforedit?.headLine}' Promotion
          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                background: "white",
                color: "black",
                cursor: "pointer",
              }}
              className="handlecirclieaboutsave"
              onClick={handleClose2}
            >
              Cancel
            </div>
            <div
              style={{ cursor: "pointer" , background:"red" }}
              className="handlecirclieaboutsave"
              onClick={handledelete}
            >
              Delete
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
