import React, { useCallback, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!
import "./Postcreator.css";
import "../../../index.css";
import "react-tippy/dist/tippy.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
import imageCompression from "browser-image-compression";
import { TextField } from "@mui/material";
import img from "../../../assets/Web 1280 – 14/Icon.svg";
import img1 from "../../../assets/Web 1280 – 14/Group 9831.svg";
import Modal22 from "./Modal22";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import API_HOST from "../../../env";
import Upcomingevent from "./Upcomingevent";

import imgla from "../../../assets/Web 1280 – 2/Group 9969.svg";
import imgra from "../../../assets/Web 1280 – 2/Group 9970.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 3,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxHeight: "90vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",

  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflow: "scroll",
  boxShadow: 24,
  p: 3,
};

const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxHeight: "90vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
}));

export default function PostCreator({ width }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const [types, setTypes] = useState("");
  const [year1, setyear1] = useState("");
  const [arrayoffiles, setArrayoffiles] = useState([]);
  const [year2, setyear2] = useState("");
  const [wrongcallageaddname, setWrongcallageaddname] = useState(false);
  const [descriptioncallageadd, setdescriptioncallageadd] = useState("");
  const [callagename, setCallagename] = useState("");
  const [themecolr, setthemecolr] = useState("#000000");
  const [checkedtocompany, setCheckedtocompany] = useState(true);
  const [startdateerr, setStartdateerr] = useState(false);
  const [typesfilter, setTypesfilter] = useState("");

  const handleCreate = () => {
    if (!callagename) {
      setWrongcallageaddname(true);
      return;
    }
    if (!year1) {
      setStartdateerr(true);
      return;
    }

    if (arrayoffiles?.length===0) {
        setShowimageerror(true);
        return;
      }

    const formdata = new FormData();
    formdata.append("title", callagename);
    formdata.append("description", descriptioncallageadd);
    formdata.append("startDate", year1);
    formdata.append("endDate", year2);
    formdata.append("colour", themecolr);
    formdata.append("typeOfPost", types);
    formdata.append("allDay", checkedtocompany);
    if (arrayoffiles?.length > 0) {
      arrayoffiles?.map((data) => {
        formdata.append("fileName", data);
      });
    }

    axios
      .post(`${API_HOST}/posts/addPost`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setRecall(!recall);
        setCallagename("");
        setdescriptioncallageadd("");
        setyear1("");
        setyear2("");
        setArrayoffiles([]);
        setTypes("");
        setShowimageerror(false)
        setthemecolr("#000000");
        setCheckedtocompany(true);
        handleClose();
      });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      setShowimageerror(false)
      //   await uploadToServer(compressedFile); // write your own logic
      await setArrayoffiles([
        ...arrayoffiles,
        new File([compressedFile], `${compressedFile?.name}`),
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  const [anchorElx2, setAnchorElx2] = React.useState(null);
  const handleClickx2 = (event) => {
    setAnchorElx2(event.currentTarget);
  };

  const handleClosex2 = () => {
    setAnchorElx2(null);
  };
  const openx2 = Boolean(anchorElx2);
  const idx2 = openx2 ? "simple-popover" : undefined;
  const [recall, setRecall] = useState(false);
  const [calendereventdata, setCalendereventdata] = useState([]);
  const [eventsdata, setEventsdata] = useState("");
  const [upcoming, setUpcoming] = useState();

  const [setdatefilter, setsetdatefilter] = useState("");

  useEffect(() => {
    axios
      .get(`${API_HOST}/posts/allPosts`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        console.log(res?.data?.success?.data);
        setEventsdata(res?.data?.success?.data);
        window.scrollTo(0, 0, { behavior: "smooth" });
      });
  }, [recall]);

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/posts/viewPostbyUser?pageSize=10&pageNumber=1${
          setdatefilter && "&from=" + setdatefilter
        }${typesfilter && "&typeOfPost=" + typesfilter}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        console.log(res?.data?.success?.data);
        setUpcoming(res?.data?.success?.data);
        setSelecteventpost();
        window.scrollTo(0, 0, { behavior: "smooth" });
      });
  }, [recall, typesfilter, setdatefilter]);

  useEffect(() => {
    if (eventsdata?.length > 0) {
      let array1 = [];
      eventsdata?.map((data) => {
        array1.push({
          extendedProps: data,
          //    allDay: data?.allDay,
          start: data?.startDate,
          end: data?.endDate,
        });
      });

      setCalendereventdata(array1);
    }
  }, [eventsdata]);

  const handleDateClick = (e) => {
    setsetdatefilter(e.dateStr);
  };

  const [selecteventpost, setSelecteventpost] = useState();
  const [selectedpost, setSelectedpost] = useState();
  const [indexofimage, setIndexofimage] = useState(0);

  useEffect(() => {
    if (selecteventpost?.files?.length > 0) {
      setSelectedpost(selecteventpost?.files[0]);
      setIndexofimage(0);
    } else {
      setSelectedpost();
      setIndexofimage(0);
    }
  }, [selecteventpost]);

  const ref = useRef();

 

  const [companydata, setCompanydata] = useState("");
  useEffect(() => {
    axios
      .get(`${API_HOST}/companyDetails/viewExisted`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        setCompanydata(res?.data?.success?.data);
      });
  }, []);

  const [showimageerror, setShowimageerror] = useState(false)

  return (
    <div
      style={{
        height: width > 700 ? "calc(100vh - 4vw )" : "calc(100vh - 14vw )",
        background: "white",
      }}
      className="ScrollTable"
    >
      <div
        style={{
          background: "white",
          padding: width > 700 ? "1vw 1vw" : "2vw",
          paddingBottom: "0.75vw",
          paddingTop: "0.75vw",
        }}
        id="calendar"
      >
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          themeSystem="container"
          selectable
          dateClick={handleDateClick}
          events={calendereventdata}
          eventContent={({ event, timeText }) => (
            <Modal22 event={event} timeText={timeText} width={width} />
          )}
        />
      </div>

      <div
        style={{
          height: width > 700 ? "calc(100vh - 38vw )" : "calc(100vh - 40vw )",
          background: "white",
          minHeight: "19vw",
        }}
        className="ScrollTable"
      >
        <div
          style={{
            background: "white",
            padding: width > 700 ? "0vw 1vw" : "0vw 1vw",
            paddingTop: "0vw",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              width: "25%",
              borderRight: "1px solid #00000040",
              height:
                width > 700 ? "calc(100vh - 38vw )" : "calc(100vh - 40vw )",
              minHeight: "19vw",
            }}
          >
            <div className="Titleofpostcreator">Post Creator</div>
            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                height:
                  width > 700 ? "calc(100vh - 40vw )" : "calc(100vh - 40vw )",
                minHeight: "17vw",
              }}
            >
              <button
                className="postcreatebutton"
                onClick={() => {
                  handleOpen();
                }}
              >
                {" "}
                Create New Post{" "}
              </button>
              <div className="textofpostcreotor">Post Types</div>
              <button
                className="postcreatebutton1"
                onClick={() => {
                  if (typesfilter === "Motivational Post") {
                    setTypesfilter("");
                  } else {
                    setTypesfilter("Motivational Post");
                  }
                }}
                style={{
                  backgroundColor: "#0053cc40",
                  color: "#0053cc",
                  border:
                    typesfilter === "Motivational Post" && "1px solid  #0053cc",
                }}
              >
                <span>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.4vw",
                      marginRight: width > 700 ? "0.5vw" : "1vw",
                    }}
                  />
                </span>
                Motivational Post
              </button>
              <button
                className="postcreatebutton1"
                onClick={() => {
                  if (typesfilter === "Company Announcements") {
                    setTypesfilter("");
                  } else {
                    setTypesfilter("Company Announcements");
                  }
                }}
                style={{
                  backgroundColor: "#b948bc40",
                  color: "#b948bc",
                  border:
                    typesfilter === "Company Announcements" &&
                    "1px solid  #b948bc",
                }}
              >
                <span>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.4vw",
                      marginRight: width > 700 ? "0.5vw" : "1vw",
                    }}
                  />
                </span>
                Company Announcements
              </button>
              <button
                className="postcreatebutton1"
                onClick={() => {
                  if (typesfilter === "Informative Post") {
                    setTypesfilter("");
                  } else {
                    setTypesfilter("Informative Post");
                  }
                }}
                style={{
                  backgroundColor: "#ff529440",
                  color: "#ff5294",
                  border:
                    typesfilter === "Informative Post" && "1px solid  #ff5294",
                }}
              >
                <span>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.4vw",
                      marginRight: width > 700 ? "0.5vw" : "1vw",
                    }}
                  />
                </span>
                Informative Post
              </button>
              <button
                className="postcreatebutton1"
                onClick={() => {
                  if (typesfilter === "Celebration Post") {
                    setTypesfilter("");
                  } else {
                    setTypesfilter("Celebration Post");
                  }
                }}
                style={{
                  backgroundColor: "#ff836c40",
                  color: "#ff836c",
                  border:
                    typesfilter === "Celebration Post" && "1px solid  #ff836c",
                }}
              >
                <span>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.4vw",
                      marginRight: width > 700 ? "0.5vw" : "1vw",
                    }}
                  />
                </span>
                Celebration Post
              </button>
              <button
                className="postcreatebutton1"
                onClick={() => {
                  if (typesfilter === "Promotions and Discount Post") {
                    setTypesfilter("");
                  } else {
                    setTypesfilter("Promotions and Discount Post");
                  }
                }}
                style={{
                  backgroundColor: "#ffbf5740",
                  color: "#ffbf57",
                  border:
                    typesfilter === "Promotions and Discount Post" &&
                    "1px solid  #ffbf57",
                }}
              >
                <span>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.4vw",
                      marginRight: width > 700 ? "0.5vw" : "1vw",
                    }}
                  />
                </span>
                Promotions and Discount Post
              </button>
              <button
                className="postcreatebutton1"
                onClick={() => {
                  if (typesfilter === "Product or Service Post") {
                    setTypesfilter("");
                  } else {
                    setTypesfilter("Product or Service Post");
                  }
                }}
                style={{
                  backgroundColor: "#10965040",
                  color: "#109650",
                  border:
                    typesfilter === "Product or Service Post" &&
                    "1px solid  #109650",
                }}
              >
                <span>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.4vw",
                      marginRight: width > 700 ? "0.5vw" : "1vw",
                    }}
                  />
                </span>
                Product or Service Post
              </button>
              <button
                className="postcreatebutton1"
                onClick={() => {
                  if (typesfilter === "Customer Testimonial Post") {
                    setTypesfilter("");
                  } else {
                    setTypesfilter("Customer Testimonial Post");
                  }
                }}
                style={{
                  backgroundColor: "#95450040",
                  color: "#954500",
                  border:
                    typesfilter === "Customer Testimonial Post" &&
                    "1px solid  #954500",
                }}
              >
                <span>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: width > 700 ? "0.91vw" : "2.4vw",
                      marginRight: width > 700 ? "0.5vw" : "1vw",
                    }}
                  />
                </span>
                Customer Testimonial Post
              </button>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={width > 700 ? style : style1}>
                <div className="profiletitleandmenunav">
                  <div className="profiledetailstitle">Add Post</div>
                  <div className="profiledetailnavmanu">
                    <div>
                      <CloseIcon
                        onClick={handleClose}
                        style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
                      />
                    </div>
                  </div>
                </div>
                <hr style={{ color: "#000000" }} />

                <div
                  style={{ left: "0vw", width: "100%" }}
                  className="loginfield"
                >
                  <TextField
                    error={!wrongcallageaddname ? false : true}
                    value={callagename}
                    id="outlined-basic"
                    label="Post Title"
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: !wrongcallageaddname ? "black" : "#dc3545",
                      },
                    }}
                    onChange={(e) => {
                      setWrongcallageaddname(false);
                      setCallagename(e.target.value);
                    }}
                    inputProps={{ className: classes.input }}
                  />
                </div>

                <div
                  style={{
                    left: "0vw",
                    width: width > 700 ? "100%" : "99%",
                    marginLeft: "0%",
                  }}
                  className="loginfield"
                  onClick={handleClickx2}
                >
                  <TextField
                    id="outlined-basic"
                    label="Post Type"
                    variant="outlined"
                    value={types}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                        paddingRight: "2vw",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => {
                      setTypes(e.target.value);
                    }}
                  />
                  {width > 700 && (
                    <span style={{ width: "0.1vw" }}>
                      <KeyboardArrowDownOutlined
                        style={{
                          fontSize: "1.5vw",
                          position: "relative",
                          right: "2vw",
                          top: "1vw",
                        }}
                      />
                    </span>
                  )}
                </div>

                <Popover
                  id={idx2}
                  open={openx2}
                  anchorEl={anchorElx2}
                  onClose={handleClosex2}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div
                    style={{
                      maxHeight: width > 700 ? "18vw" : "40vw",
                      overflow: "scroll",
                      width: width > 700 ? "46.5vw" : "90vw",
                    }}
                  >
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setTypes("Motivational Post");
                        setthemecolr("#0053cc");
                        handleClosex2();
                      }}
                    >
                      Motivational Post
                    </Typography>
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setTypes("Celebration Post");
                        setthemecolr("#ff836c");
                        handleClosex2();
                      }}
                    >
                      Celebration Post
                    </Typography>
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setTypes("Company Announcements");
                        setthemecolr("#b948bc");
                        handleClosex2();
                      }}
                    >
                      Company Announcements
                    </Typography>
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setTypes("Informative Post");
                        handleClosex2();
                        setthemecolr("#ff5294");
                      }}
                    >
                      Informative Post
                    </Typography>
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setTypes("Promotions and Discount Post");
                        handleClosex2();
                        setthemecolr("#ffbf57");
                      }}
                    >
                      Promotions and Discount Post
                    </Typography>
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setTypes("Customer Testimonial Post");
                        handleClosex2();
                        setthemecolr("#954500");
                      }}
                    >
                      Customer Testimonial Post
                    </Typography>
                    <Typography
                      sx={{
                        p: 0.51,
                        pl: 1,
                        ml: 1,
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setTypes("Product or Service Post");
                        handleClosex2();
                        setthemecolr("#109650");
                      }}
                    >
                      Product or Service Post
                    </Typography>
                  </div>
                </Popover>

                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <div className="jobpodtedfieldtitile">Start Date</div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        style={{
                          left: "0vw",
                          width: "100%",
                          margin: "0.3vw 0",
                        }}
                        className="loginfield"
                        // onClick={handleClickx}
                      >
                        <div className="" style={{ width: "100%" }}>
                          <div style={{ width: "100%" }}>
                            <div className="jobpostfieldinputbox">
                              <input
                                style={{ width: "100%" }}
                                type="datetime-local"
                                className="input-homejobformdate"
                                name=""
                                id=""
                                value={year1}
                                maxlength="4"
                                onChange={(e) => {
                                  setyear1(e.target.value);
                                  setStartdateerr(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="jobpodtedfieldtitile">
                      End Date (Excluded)
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        style={{
                          left: "0vw",
                          width: "100%",
                          margin: "0.3vw 0",
                        }}
                        className="loginfield"
                        // onClick={handleClickx2}
                      >
                        <div className="" style={{ width: "100%" }}>
                          <div style={{ width: "100%" }}>
                            <div className="jobpostfieldinputbox">
                              <input
                                style={{ width: "100%" }}
                                type="datetime-local"
                                className="input-homejobformdate"
                                name=""
                                id=""
                                value={year2}
                                maxlength="4"
                                onChange={(e) => {
                                  setyear2(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                  </div>
                </div>
                <div style={{ color: "red" }} className="redp">
                  {startdateerr && "Please select start date"}
                </div>
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    onChange={() => {
                      setCheckedtocompany(!checkedtocompany);
                    }}
                    checked={checkedtocompany}
                    name=""
                    id="checkbox"
                  />

                  <div
                    className=""
                    style={{
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "#000000",
                      display: "inline",
                      margin: "0 1vw",
                    }}
                  >
                    set Post / Event for Full Day
                  </div>
                </label>
                <div
                  style={{ left: "0vw", width: "100%" }}
                  className="loginfield"
                >
                  <TextField
                    id="outlined-basic"
                    label="Description (Optional)"
                    variant="outlined"
                    value={descriptioncallageadd}
                    multiline
                    rows="1"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => {
                      setdescriptioncallageadd(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    width: width > 700 ? "100%" : "100%",
                    border: "1px solid black",
                    borderRadius: "5px",
                    height: width > 700 ? "3vw" : "9vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: width > 700 ? "0.5vw" : "1.5vw",
                    marginLeft: "0",
                    left: "0",
                    marginTop: "1.5vw",
                  }}
                  className="loginfield"
                >
                  <div className="jobpodtedfieldtitile">
                    {themecolr ? themecolr : "Choose a Theme color"}
                  </div>
                  <input
                    id="outlined-basic"
                    label="Choose a Theme color "
                    variant="outlined"
                    type="color"
                    value={themecolr}
                    style={{
                      width: width > 700 ? "2vw" : "7vw",
                      height: width > 700 ? "2vw" : "7vw",
                    }}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setthemecolr(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    marginBottom: "0.5vw",
                    marginTop: "1.3vw",
                    fontSize: width > 700 ? "1.2vw" : "3.2vw",
                  }}
                  className="jobpodtedfieldtitile"
                >
                  Post Image
                </div>
                <div
                  style={{
                    background: "white",
                    padding: "0.51vw",
                    marginTop: "0vw",
                  }}
                >
                  <div style={{ height: "8vw" }} className="inputfilebox">
                    <div>
                      <label htmlFor="inputctaelogfile">
                        <div className="inputicon">
                          <img src={img} alt="" />
                        </div>
                        <div className="inputcateaddformfile">
                          Drag and Drop ,Browse to upload
                        </div>
                        <input
                          type="file"
                          id="inputctaelogfile"
                          onChange={(e) => {
                            handleImageUpload(e);
                          }}
                          hidden
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    arrayoffiles?.length > 0 ? "inputfilesshowncatebox" : ""
                  }
                >
                  {arrayoffiles?.length > 0 &&
                    arrayoffiles?.map((file, index) => {
                      return (
                        <div className="inputfilesshowncatboxsingle">
                          <div className="inputfilesshowncatboxsingleimg">
                            <img src={img1} alt="" />
                          </div>
                          <div className="fileselctednamecate">
                            {file?.name}
                          </div>
                          <div className="inputfilesshowncatboxsingleimg">
                            <img
                              style={{
                                width: width > 700 ? "1.5vw" : "4vw",
                                marginLeft: "1vw",
                                cursor: "pointer",
                              }}
                              src={img1}
                              alt=""
                              onClick={() => {
                                setArrayoffiles([
                                  ...arrayoffiles.slice(0, index),
                                  ...arrayoffiles.slice(
                                    index + 1,
                                    arrayoffiles.length
                                  ),
                                ]);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {showimageerror && <p
                  className="redp"
                  style={{
                    color: "red",
                    fontSize: width > 700 ? "0.91vw" : "2.5vw",
                  }}
                > Add Post image for create a Post  !</p>}
                {/* <hr style={{ color: "#000000" }} /> */}

                <div
                  style={{ marginTop: "0.31vw" }}
                  className="handlemoreaboutskill"
                >
                  <div
                    style={{
                      background: "white",
                      color: "black",
                      cursor: "pointer",
                    }}
                    className="handlecirclieaboutsave"
                    onClick={handleClose}
                  >
                    Cancel
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="handlecirclieaboutsave"
                    onClick={handleCreate}
                  >
                    Create
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
          <div
            style={{
              width: "45%",
              borderRight: "1px solid #00000040",
              height:
                width > 700 ? "calc(100vh - 38vw )" : "calc(100vh - 40vw )",
              minHeight: "19vw",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="Titleofpostcreator"
            >
              Upcoming Events{" "}
              <div>
                {setdatefilter && (
                  <span
                    style={{
                      margin: width > 700 ? "0vw 0.5vw" : "1vw 2vw",
                      marginLeft: "0.5vw",
                      marginBottom: "0.2vw",
                      width: "fit-content",
                      padding: width > 700 ? "0.2vw 0.6vw" : "0.5vw 1vw",
                    }}
                    className="tagblue"
                  >
                    {setdatefilter}{" "}
                    <span>
                      <CloseIcon
                        onClick={() => {
                          setsetdatefilter();
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: width > 700 ? "1vw" : "4vw",
                          position: "relative",
                          bottom: "0.1vw",
                        }}
                      />
                    </span>
                  </span>
                )}
                {typesfilter && (
                  <span
                    style={{
                      margin: width > 700 ? "0vw 0.5vw" : "1vw 2vw",
                      marginLeft: "0.5vw",
                      marginBottom: "0.2vw",
                      width: "fit-content",
                      padding: width > 700 ? "0.2vw 0.6vw" : "0.5vw 1vw",
                    }}
                    className="tagblue"
                  >
                    {typesfilter}{" "}
                    <span>
                      <CloseIcon
                        onClick={() => {
                          setTypesfilter("");
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: width > 700 ? "1vw" : "4vw",
                          position: "relative",
                          bottom: "0.1vw",
                        }}
                      />
                    </span>
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                width: "100%",
                overflowY: "scroll",
                height:
                  width > 700 ? "calc(100vh - 40vw )" : "calc(100vh - 42vw )",
                minHeight: "17vw",
              }}
            >
              {upcoming?.length > 0 &&
                upcoming?.map((data) => {
                  return (
                    <Upcomingevent
                      data={data}
                      width={width}
                      setRecall={setRecall}
                      recall={recall}
                      setSelecteventpost={setSelecteventpost}
                    />
                  );
                })}
            </div>
          </div>
          <div
            style={{
              width: "29%",
              height:
                width > 700 ? "calc(100vh - 38vw )" : "calc(100vh - 40vw )",
              minHeight: "19vw",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="Titleofpostcreator"
            >
              {selecteventpost
                ? selecteventpost?.title?.slice(0, 30)
                : "Post Preview"}
              <div>
                {/* {typesfilter && (
                  <span
                    style={{
                      margin: width > 700 ? "0vw 0.5vw" : "1vw 2vw",
                      marginLeft: "0.5vw",
                      marginBottom: "0.2vw",
                      width: "fit-content",
                      padding: width > 700 ? "0.2vw 0.6vw" : "0.5vw 1vw",
                    }}
                    className="tagblue"
                  >
                    {typesfilter}{" "}
                    <span>
                      <CloseIcon
                        onClick={() => {
                          setTypesfilter("");
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: width > 700 ? "1vw" : "4vw",
                          position: "relative",
                          bottom: "0.1vw",
                        }}
                      />
                    </span>
                  </span>
                )} */}
              </div>
            </div>
            {selecteventpost && (
              <div
                style={{
                  width: "100%",
                  overflowY: "scroll",
                  height:
                    width > 700 ? "calc(100vh - 40vw )" : "calc(100vh - 42vw )",
                  minHeight: "17vw",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <div className="previewImage">
                  <span
                    style={{
                      width: width > 700 ? "2vw" : "7vw",
                      height: width > 700 ? "2vw" : "7vw",
                    }}
                  >
                    <div
                      hidden={indexofimage === 0}
                      style={{
                        display: "block",
                        background: "white",
                        zIndex: "50",
                        cursor: "pointer",
                        position: "relative",
                        left: "0.51vw",
                        width: width > 700 ? "2vw" : "7vw",
                        height: width > 700 ? "2vw" : "7vw",
                        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.3)",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        setSelectedpost(
                          selecteventpost?.files[indexofimage - 1]
                        );
                        setIndexofimage(indexofimage - 1);
                      }}
                    >
                      <img
                        src={imgla}
                        style={{
                          background: "white",
                          width: width > 700 ? "2vw" : "7vw",
                          height: width > 700 ? "2vw" : "7vw",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    </div>
                  </span>
                  <img src={selectedpost} alt="" />{" "}
                  <span style={{ width: "1vw" }}>
                    <div
                      hidden={
                        indexofimage + 1 === selecteventpost?.files?.length
                      }
                      style={{
                        display: "block",
                        background: "white",
                        zIndex: "50",
                        cursor: "pointer",
                        position: "relative",
                        right: "0.51vw",
                        width: width > 700 ? "2vw" : "7vw",
                        height: width > 700 ? "2vw" : "7vw",
                        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.3)",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        setSelectedpost(
                          selecteventpost?.files[indexofimage + 1]
                        );
                        setIndexofimage(indexofimage + 1);
                      }}
                    >
                      <img
                        src={imgra}
                        style={{
                          width: width > 700 ? "2vw" : "7vw",
                          height: width > 700 ? "2vw" : "7vw",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
