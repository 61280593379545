import React from "react";

export default function WebsiteCart({
  width,
  websitedata,
  arrayofProducts,
  setArrayofProducts,
}) {
  return (
    <div
      style={{ marginLeft: "1vw", width: width > 700 ? "80vw" : "94vw" }}
      className="websiteoffercontainer"
    >
      <div style={{ color: "#0052cc" }} className="offerheading">
        Booking Summary
      </div>

      <div className="flexofoffers">
        {arrayofProducts?.length > 0 &&
          arrayofProducts?.map((data, index) => {
            return (
              <div className="cartwebsitex">
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      style={{
                        cursor: "pointer",
                      }}
                      src={data?.web_id?.product_id?.files[0]?.file}
                      alt=""
                    />
                    <div className="cartquantity">
                      <div style={{ cursor: "pointer" }}>-</div>
                      <div>{data?.qnumbers}</div>
                      <div style={{ cursor: "pointer" }}>+</div>
                    </div>
                  </div>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: width > 700 ? "13vw" : "32vw",
                      width: "100%",
                      marginLeft: width <= 700 && "1vw",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="namemenuweb"
                    >
                      {data?.web_id?.product_id?.productName?.slice(0, 23)}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        margin: "0.5vw 0vw",
                        marginBottom: "0vw",
                        paddingBottom: "0vw",
                      }}
                      className="pricemenuweb"
                    >
                      <span>
                        Rs.
                        {parseFloat(data?.totalPrice).toFixed(2)}
                      </span>
                    </div>
                    <div
                      style={{ color: data?.web_id?.qnumbers > 0 ? "" : "red" }}
                      className="stockmenuweb"
                    >
                      Total GST ({data?.web_id?.product_id?.gstRate}%) - Rs{" "}
                      {parseFloat(data?.totalGST).toFixed(2)}{" "}
                    </div>
                    <div style={{ marginTop: "0.5vw" }} className="">
                      {data?.web_id?.product_id?.addOn?.map((data1) => {
                        return (
                          <div style={{ margin: "0vw" }} className="addonbox">
                            <div
                              style={{ alignItems: "center" }}
                              className="addondata"
                            >
                              <div
                                style={{
                                  width: width > 700 ? "10%" : "7vw",
                                  fontSize: width > 700 ? "0.8vw" : "2.4vw",
                                }}
                                className=""
                              >
                                <input
                                  style={{
                                    margin: "0.5vw 0vw",
                                    height: width > 700 ? "1vw" : "2.5vw",
                                  }}
                                  type="checkbox"
                                  name=""
                                  id=""
                                  checked={
                                    arrayofProducts[index]?.addOn?.findIndex(
                                      (x) => x._id === data1?._id
                                    ) > -1
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  width: "70%",
                                  fontSize: width > 700 ? "0.8vw" : "2.5vw",
                                  margin: width > 700 ? "0.2vw" : "0.5vw",
                                }}
                                className="addonname"
                              >
                                {data1.name}
                              </div>
                              <div
                                style={{
                                  width: "20%",
                                  fontSize: width > 700 ? "0.8vw" : "2.5vw",
                                  margin: "0.2vw",
                                }}
                                className="addonname"
                              >
                                Rs {data1.amount}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {data?.type === "Hourly" ? (
                  <div className="timeanddatecart">
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>Date</div>
                      <input type="date" value={data?.fromDate}         disabled />
                    </div>
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>Time</div>
                      <input
                        style={{ width: "8vw" }}
                        type="time"
                        pattern="[0-9]{2}:[0-9]{2}"
                        min="00:00"
                        max="24:00"
                        value={data?.fromHour}
                        disabled
                      />
                    </div>
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>No. of Hrs</div>{" "}
                      <input
                        style={{ width: "6.5vw" }}
                        type="number"
                        value={data?.totalHours}
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <div className="timeanddatecart">
                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>From</div>
                      <input type="date" value={data?.fromDate} disabled />
                    </div>

                    <div>
                      <div style={{ marginLeft: "0.5vw" }}>To</div>
                      <input
                        style={{
                          border: data?.totalDays < 1 && "2px solid red",
                        }}
                        type="date"
                        value={data?.toDate}
                        disabled
                      />
                    </div>

                    <div>
                      <div style={{ marginLeft: "0vw" }}>No. of Days</div>{" "}
                      <input
                        style={{ width: "6vw" }}
                        type="number"
                        value={data?.totalDays}         disabled
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
