import axios from "axios";
import React from "react";

import { useNavigate } from "react-router";
import API_HOST from "../../../env";

export default function Skillpopupcopy({ width, data, setRecall, recall }) {
  const navigate = useNavigate();
  const ref = React.createRef();
  const handleaccept = (id) => {
    axios
      .post(
        `${API_HOST}/connection/withdrawConnection`,
        { connectionId: id },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setRecall(!recall);
      });
  };
  return (
    <div ref={ref}>
      <div
        style={{
          alignItems: "center",
          margin: width > 700 ? "1vw 0vw" : "2vw 0vw",
          padding: width > 700 ? "0.5vw  1vw" : "2vw",
          color: "black",
          width: width > 700 ? "82vw" : "210vw",
          cursor: "pointer",
        }}
        className="navoftableblogsdata"
      >
        <div
          onClick={() =>
            navigate(
              `/dashboard/${data?._id?.connector?.userName[0]}/My Profile`
            )
          }
          style={{ width: width > 700 ? "15.2vw" : "50vw" }}
        >
          <span>
            <img
              style={{
                height: "fit-content",
                width: width > 700 ? "1.4vw" : "5vw",
                margin: "0vw",
                height: width > 700 ? "1.4vw" : "5vw",
                marginRight: "0.2vw",
                objectFit: "contain",
              }}
              src={data?._id?.connector?.media[0]}
              alt=""
            />
          </span>{" "}
          {data?._id?.connector?.fullName[0]?.slice(0, 25)}
        </div>
        <div
          onClick={() =>
            navigate(`/dashboard/${data?._id?.client?.userName[0]}/My Profile`)
          }
          style={{ width: width > 700 ? "15.2vw" : "50vw" }}
        >
          <span>
            <img
              style={{
                height: "fit-content",
                width: width > 700 ? "1.4vw" : "5vw",
                height: width > 700 ? "1.4vw" : "5vw",
                margin: "0vw",
                marginRight: "0.2vw",
                objectFit: "contain",
              }}
              src={data?._id?.client?.media[0]}
              alt=""
            />
          </span>{" "}
          {data?._id?.client?.fullName[0]?.slice(0, 25)}
        </div>
        <div style={{ width: width > 700 ? "14.1vw" : "50vw" }}>
          {data?._id?.client?.title[0]?.slice(0, 20)}
        </div>

        <div style={{ width: width > 700 ? "25.5vw" : "90vw" }}>
          {data?._id?.purpose?.slice(0, 40)}
        </div>

        <div style={{ width: width > 700 ? "7vw" : "30vw" }}>
          {new Date(data?._id?.created_at).getDate()}/
          {new Date(data?._id?.created_at).getMonth() + 1}/
          {new Date(data?._id?.created_at).getFullYear()}
        </div>

        <div
          style={{
            width: width > 700 ? "8vw" : "40vw",
            display: "flex",
            textAlign: "left",
            justifyContent: "flex-start",
            color: data?._id?.Status === "Accepted" ? "green" : "red",
            fontSize: width > 700 ? "0.85vw" : "2.6vw",
          }}
        >
          {data?._id?.Status}
        </div>
        <div
          style={{
            width: width > 700 ? "8vw" : "40vw",
            display: "flex",
            textAlign: "left",
            justifyContent: "flex-start",

            fontSize: width > 700 ? "0.85vw" : "2.6vw",
          }}
        >
          {data?._id?.accept ? (
            <button
              style={{ height: "2vw" }}
              className="hb-buttonx"
              onClick={() => {
                navigate(`/dashboard/connection/${data?._id?.connectionId}`);
              }}
            >
              View
            </button>
          ) : (
            <div style={{ width: "8vw" }}></div>
          )}
        </div>
      </div>
    </div>
  );
}
