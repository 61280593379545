import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import API_HOST from "../../../env";
import { makeStyles } from "@material-ui/core";
import { Popover, TextField, Typography } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  select: {
    height: "2.5vw",
    width: "100%",
    marginTop: "0.2vw",
    padding: "1vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
    lineHeight: "120%",
    color: "#FFFFFF",
  },
  select2: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0.9vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    color: "#FFFFFF",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "white",
  },
}));
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",

  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  height: "fit-content",
  overflow: "scroll",
  padding: "1vw",
};

const style2 = {
  position: "absolute",
  maxHieght: "90vh",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "94vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  padding: "1vw",
};
export default function Skillpopupcopy({ width, data, setRecall, recall }) {
  const navigate = useNavigate();
  const [openx, setOpenx] = React.useState(false);
  const handleOpenx = () => setOpenx(true);
  const handleClosex = () => setOpenx(false);
  const classes = useStyles();
  const handlecomplete = () => {
    axios
      .post(
        `${API_HOST}/withdrawl/completeWithdrawl`,
        {
          withdrawlId: data?.withdrawlId,
          userName: data?.requester_userName,
          checkOut_type: name,
          checkOut_refrence_id: id,
          checkOut_description: desc,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        handleClose();
        handleClosex();
        setRecall(!recall);
      });
  };
  const handlereject = () => {
    axios
      .post(
        `${API_HOST}/withdrawl/declineWithdrawl`,
        {
          withdrawlId: data?.withdrawlId,
          userName: data?.requester_userName,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        handleClosex();
        setRecall(!recall);
      });
  };

  const [name, setname] = useState("");
  const [id, setid] = useState("");

  const [desc, setDesc] = useState("");
  const [titileError, setTitileError] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [walletmoney, setWalletmoney] = useState("");
  const handlewalletdata = (userName) => {
    axios
      .post(
        `${API_HOST}/wallet/viewWalletbyuserName`,
        { userName: userName },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setWalletmoney(res?.data?.success?.data);

        setChecktransaction(!checktransaction);
      })
      .catch(() => {});
  };

  return (
    <div
      style={{
        alignItems: "center",
        padding: "0.75vw 1vw",
        margin: width > 700 ? "1vw 0vw" : "2vw 0vw",
        width: width > 700 ? "82vw" : "194vw",
      }}
      className="navoftableblogsdata"
    >
      <div
        onClick={() => {
          navigate(`/dashboard/${data?.requester_userName}/My Profile`);
        }}
        style={{ width: width > 700 ? "15vw" : "70vw", cursor: "pointer" }}
      >
        <span>
          <img
            style={{
              height: "fit-content",
              width: width > 700 ? "1.4vw" : "5vw",
              margin: "0vw",
              marginRight: "0.2vw",
              objectFit: "contain",
              borderRadius: "40%",
            }}
            src={data?.requester_user_id?.media}
            alt=""
          />
        </span>
        {data?.requester_user_id.fullName?.slice(0, 23)}
      </div>

      <div style={{ width: "15vw" }}>{data?.withdrawlId}</div>
      <div style={{ width: "15vw" }}>{data?.transactionId}</div>

      <div style={{ width: "10vw" }}>
        {data?.created_at && (
          <span>
            {new Date(data?.created_at).getHours()}:
            {new Date(data?.created_at).getMinutes()}
            {", "}
            {new Date(data?.created_at).getDate()}/
            {new Date(data?.created_at).getMonth() + 1}/
            {new Date(data?.created_at).getFullYear()}
          </span>
        )}
      </div>
      <div style={{ width: "7vw" }}>₹ {data?.checkOut_balance}</div>
      <div
        style={{
          width: "8vw",
          color: data?.checkOut_process === "complete" ? "#41B541" : "red",
          fontWeight: "500",
        }}
      >
        {data?.checkOut_process}
      </div>

      <button
        style={{ height: width > 700 ? "2vw" : "4.5vw" }}
        className="hb-buttonx"
        onClick={() => {
          handleOpenx();
          handlewalletdata(data?.requester_user_id?.userName);
        }}
      >
        View Detail
      </button>

      <Modal
        open={openx}
        onClose={handleClosex}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style : style2}>
          <div style={{ padding: "1vw" }} className="profiletitleandmenunav">
            <div className="profiledetailstitle">Withdraw Detail </div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClosex}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                Withdraw Id:
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.withdrawlId}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                Transaction id :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.transactionId}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                Debit from wallet:
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.updateAmountInWallet ? "yes" : "no"}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                Time And Date:
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                {data?.created_at && (
                  <span>
                    {new Date(data?.created_at).getHours()}:
                    {new Date(data?.created_at).getMinutes()}
                    {", "}
                    {new Date(data?.created_at).getDate()}/
                    {new Date(data?.created_at).getMonth() + 1}/
                    {new Date(data?.created_at).getFullYear()}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                Amount :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                ₹ {data?.checkOut_balance}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                Current wallet amount** :
              </div>
              <div
                style={{ fontWeight: "400", width: "100%" }}
                className="titleboxflexofbank"
              >
                ₹ {walletmoney?.balance?.$numberDecimal}
              </div>
            </div>
          </div>

          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                Status :
              </div>
              <div
                style={{
                  fontWeight: "400",
                  width: "100%",
                  color:
                    data?.checkOut_process === "complete" ? "#41B541" : "red",
                }}
                className="titleboxflexofbank"
              >
                {data?.checkOut_process}
              </div>
            </div>
          </div>
          <div
            style={{ width: "100%", paddingTop: "0vw" }}
            className="flexofbankdetails"
          >
            <div style={{ width: "100%" }} className="flexofbankdetails">
              <div style={{ width: "40%" }} className="titleboxflexofbank">
                payment Status :
              </div>
              <div
                style={{
                  fontWeight: "400",
                  width: "100%",
                  color: data?.checkOut_status ? "#41B541" : "red",
                }}
                className="titleboxflexofbank"
              >
                {data?.checkOut_status ? "yes" : "no"}
              </div>
            </div>
          </div>

          <div>
            <hr />
            <div className="profiledetailstitle">Payment detail</div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  name :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.requester_details[0]?.name}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  Email :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.requester_details[0]?.email}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  Phone :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.requester_details[0]?.phone}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  upi :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.requester_details[0]?.upi}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  payment time :
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.checkOut_complete_time && (
                    <span>
                      {new Date(data?.checkOut_complete_time).getHours()}:
                      {new Date(data?.checkOut_complete_time).getMinutes()}
                      {", "}
                      {new Date(data?.checkOut_complete_time).getDate()}/
                      {new Date(data?.checkOut_complete_time).getMonth() + 1}/
                      {new Date(data?.checkOut_complete_time).getFullYear()}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  payment by:
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.checkOut_type}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  Transaction / Refrence Id:
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.checkOut_refrence_id}
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", paddingTop: "0vw" }}
              className="flexofbankdetails"
            >
              <div style={{ width: "100%" }} className="flexofbankdetails">
                <div style={{ width: "50%" }} className="titleboxflexofbank">
                  payment description:
                </div>
                <div
                  style={{ fontWeight: "400", width: "100%" }}
                  className="titleboxflexofbank"
                >
                  {data?.checkOut_description}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              float: "right",
              marginBottom: "1vw",
              marginTop: width > 700 ? "0.3vw" : "1vw",
              width: width > 700 ? "45vw" : "70vw",
              justifyContent: "right",
            }}
            className="homejobbuttons"
          >
            <button
              onClick={() => {
                handleClosex();
              }}
              style={{ background: "white", marginRight: "1vw" }}
            >
              Close
            </button>

            {!data?.checkOut_decline && !data?.checkOut_status && (
              <button
                onClick={() => {
                  handlereject();
                }}
                style={{ color: "white", marginRight: "1vw", width: "10vw" }}
              >
                Reject Request
              </button>
            )}
            {!data?.checkOut_decline && !data?.checkOut_status && (
              <button
                onClick={() => {
                  handleOpen();
                }}
                style={{ color: "white" }}
              >
                Complete
              </button>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={width > 700 ? style1 : style2}>
          <div className="profiletitleandmenunav">
            <div className="profiledetailstitle">Raise Issue</div>
            <div className="profiledetailnavmanu">
              <div>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    fontSize: width > 700 ? "1.5vw" : "4vw",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={{ color: "#000000" }} />
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "92%" : "100% ",
              marginLeft: "1vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Payment Type"
              variant="outlined"
              value={name}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setname(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              left: "0vw",
              width: width > 700 ? "92%" : "100% ",
              marginLeft: "1vw",
            }}
            className="loginfield"
          >
            <TextField
              id="outlined-basic"
              label="Transaction / refrence id"
              variant="outlined"
              value={id}
              style={{ width: "100%" }}
              InputLabelProps={{
                style: {
                  fontSize: width > 700 ? "1vw" : "3vw",
                  fontFamily: "Poppins",
                  fontStyle: "500",
                  fontWeight: "500",
                  color: "black",
                },
              }}
              inputProps={{ className: classes.input }}
              onChange={(e) => {
                setid(e.target.value);
              }}
            />
          </div>

          <p style={{ color: "red", fontSize: "0.91vw" }}>{titileError}</p>
          <div style={{ marginLeft: "1vw" }} className="jobpodtedfieldtitile">
            Description
          </div>
          <div style={{ marginLeft: "1vw" }} className="jobpostfieldinputbox">
            <textarea
              style={{ paddingTop: "0.5vw" }}
              type="text"
              name="desc"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>

          <div style={{ marginTop: "0.31vw" }} className="handlemoreaboutskill">
            <div
              style={{
                cursor: "pointer",
                width: width > 700 ? "10vw" : "30vw",
              }}
              className="handlecirclieaboutsave"
              onClick={() => {
                handlecomplete();
              }}
            >
              Complete now
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
