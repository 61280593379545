import React, { useEffect, useState } from "react";
import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import searchicon from "../../../assets/Dashboard/Iconly-Light-Search.svg";
import ContractList from "./ContractList";
import { useLocation, useNavigate } from "react-router";

export default function Contract({ width }) {
  const [page1, setPage1] = useState(1);

  const [setSelectedCategory1, setSetSelectedCategory1] = useState("");

  const [allusers1, setAllusers1] = useState([]);

  const [recall1, setRecall1] = useState(false);
  const [selecteddelete1, setSelecteddelete1] = useState([]);

  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const navigate = useNavigate();

  let search = url.get("search");

  useEffect(() => {
    if (search) {
      setSetSelectedCategory1(search);
    } else {
      setSetSelectedCategory1("");
    }
  }, [search]);

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/contract/adminContract?pageSize=10&pageNumber=${page1}&search=${setSelectedCategory1}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setAllusers1(res?.data?.success?.data);
        window.scrollTo(0, 0, { behavior: "smooth" });
      });
  }, [page1, setSelectedCategory1, recall1]);

  return (
    <div className="BrowseWorkMain-cntainer">
   
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          height: width > 700 ? "4vw" : "10vw",
          marginBottom: "1vw",
        }}
        className="searchboxcontainer"
      >
        <div
          style={{
            height: width > 700 ? "3vw" : "9vw",
            paddingRight: "0.3vw",
            marginTop: "0vw",
          }}
          className="serachjobbox"
        >
          <span>
            <img
              src={searchicon}
              style={{ width: width > 700 ? "1.7vw" : "5vw" }}
              alt=""
            />
          </span>
          <input
            type="text"
            placeholder={"Search  here .."}
            value={setSelectedCategory1}
            onChange={(e) => {
              if (e.target.value === "") {
                navigate("/dashboard/contract");
              } else {
                url.set("search", e.target.value);
                navigate("/dashboard/contract" + "?" + url.toString());
              }
            }}
          />
        </div>
        {/* <button
          onClick={() => {
            navigate("/dashboard/addcontract");
          }}
          style={{
            marginRight: width > 700 ? "2vw" : "0vw",
            width: width > 700 ? "12vw" : "32vw",
            marginTop: width > 700 ? "0vw" : "0vw",
            height: width <= 700 && "9vw",
            float: "right",
            marginLeft: "1vw",
          }}
          className="hb-buttonx"
        >
          <span>Create Contract</span>
        </button> */}
      </div>

      <div
        style={{ overflowX: "scroll", width: "92vw" }}
        className="ScrollTable"
      >
        <div
          style={{
            margin: "0vw 1vw",
            padding: "0vw 1vw",
            width: width > 700 ? "80vw" : "192vw",
            marginTop: width > 700 && "0.01vw",
          }}
          className="navoftableblogs"
        >
          <div style={{ width: width > 700 ? "11.5vw" : "30vw" }}>
            Contract No
          </div>
          <div style={{ width: width > 700 ? "17.5vw" : "50vw" }}>
            Contract name
          </div>
          <div style={{ width: width > 700 ? "13.4vw" : "20vw" }}>
            Contract with
          </div>
          <div style={{ width: width > 700 ? "17.5vw" : "40vw" }}>
            Contracting company
          </div>

          <div style={{ width: width > 700 ? "10vw" : "30vw" }}>Date</div>

          <div style={{ width: width > 700 ? "6vw" : "15vw" }}> </div>
        </div>
        {allusers1?.length > 0 &&
          allusers1?.map((data, index) => {
            return (
              <ContractList
                width={width}
                data={data}
                index={index}
                page={page1}
                setSelecteddelete={setSelecteddelete1}
                selecteddelete={selecteddelete1}
                setRecall={setRecall1}
                recall={recall1}
              />
            );
          })}

        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div
            onClick={() => {
              if (page1 > 1) {
                setPage1(page1 - 1);
              }
            }}
          >
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page1 - 1 > 0) {
                  setPage1(page1 - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page1 - 4 > 0 ? false : true}
            onClick={() => setPage1(page1 - 4)}
          >
            {page1 - 4}
          </div>
          <div
            hidden={page1 - 3 > 0 ? false : true}
            onClick={() => setPage1(page1 - 3)}
          >
            {page1 - 3}
          </div>
          <div
            hidden={page1 - 2 > 0 ? false : true}
            onClick={() => setPage1(page1 - 2)}
          >
            {page1 - 2}
          </div>
          <div
            hidden={page1 - 1 > 0 ? false : true}
            onClick={() => setPage1(page1 - 1)}
          >
            {page1 - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page1}</div>
          <div onClick={() => setPage1(page1 + 1)}>{page1 + 1}</div>
          <div onClick={() => setPage1(page1 + 2)}>{page1 + 2}</div>
          <div onClick={() => setPage1(page1 + 3)}>{page1 + 3}</div>

          <div onClick={() => setPage1(page1 + 1)}>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage1(page1 + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
