import React, { useEffect, useState } from "react";
import "./profile.css";
import "./croper.css";
import "./background.css";
import img2 from "../../../../assets/Success stories Definition/checkmark (1).svg";
import img4 from "../../../../assets/My profile – 28/pexels-stefan-stefancik-91227.png";
import img from "../../../../assets/Success stories/alvaro-reyes-qWwpHwip31M-unsplash.png";
import facebookIcon from "../../../../assets/Logo/facebook_icon.png";
import GoogleIcon from "../../../../assets/Logo/7611770.png";
import LinkedInIcon from "../../../../assets/Logo/174857.png";
import GitHubIcon from "../../../../assets/Logo/Octicons-mark-github.svg.png";
import dob from "../../../../assets/Logo/Calendar.png";
import location from "../../../../assets/Logo/Location.png";
import phone from "../../../../assets/Logo/Call.png";
import email from "../../../../assets/Logo/Message.png";
import imglogo from "../../../../assets/Logo/tools 44 shorten.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import axios from "axios";
import API_HOST from "../../../../env";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    padding: 22.5,
  },
  section: {
    margin: 1,
    padding: 1,
    marginBottom: 2,
  },
  section1: {
    margin: 1,
    padding: 1,
    marginTop: 5,
  },

  sectionleft: {
    margin: 0,
    padding: 0,
    width: "27.5%",
    float: "left",
    height: "100%",
  },
  sectionright: {
    margin: 0,
    padding: 0,
    width: "67.5%",
    float: "right",
    height: "100%",
  },

  viewer: {
    width: "80vw",
    height: "90vh",
  },

  flexBoxforleftandright: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  Blueheading: {
    color: "#0052cc",
  },
  simpletext: {
    fontSize: "11px",
    color: "#000000b1",

    paddingTop: "2px",
  },
  simpletextBlod: {
    width: "100%",
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "90%",
    height: "130px",
    objectFit: "cover",
  },
  fullName: {
    fontSize: "28px",
    marginBottom: "1px",
    color: "#0052cc",
    paddingTop: "0",
  },
  worktitle: {
    fontSize: "15px",
    marginBottom: "6px",
    marginTop: "5px",
    color: "#0052ccd1",
  },
  hr: {
    width: "100%",
    borderBottom: "1px solid gray",
    height: "0",
    marginTop: "5px",
    marginBottom: "4px",
  },
  flexBoxforhalf: {
    display: "flex",
    flexDirection: "row",
    margin: "5px 0px",
  },
  flexBoxonly: {
    width: "25%",
    display: "flex",
    flexDirection: "row",
    paddingRight: 2,
  },
  imagex: {
    width: "17px",
    height: "17px",
    margin: "0px",
    objectFit: "contain",
  },
  imagexx: {
    width: "15px",
    height: "15px",
    margin: "0.5px",
    objectFit: "contain",
  },
  blueheading: {
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
    color: "#0052cc",
    margin: "8px 0",
    marginBottom: "5px",
  },
  bluesubheading: {
    fontSize: "12px",
    fontFamily: "Helvetica-Bold",
    color: "#0052cc",
    margin: "2px 0",
  },
  blacksubheading: {
    fontSize: "11px",
    fontFamily: "Helvetica-Bold",
    color: "#000000cc",
    margin: "1px 0",
  },
  profileFlex: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    margin: "3px 0",
  },
  justifyContent: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "1px 0",
    width: "100%",
  },
  justifyContent2: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "2px 0",
    marginLeft: "3px",
  },

  content: {
    marginBottom: 60, // Adjust the margin to accommodate the logo
  },
  logo: {
    width: "50px",
    height: "15px",
    fontSize: "13px",
    objectFit: "contain",
    marginTop: "3px",
    marginLeft: "2px",
  },

  footer: {
    position: "absolute",
    bottom: 5,
    left: 30,
    right: 10,
    textAlign: "right",
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    justifyContent: "flex-end",
    color: "#000000b4",
  },
  bluedot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: "#0052cc",
  },
  bluedotcover: {
    width: "0px",
    position: "relative",
    right: "1.5vw",
    top: "3px",
  },
});

Font.register({
  family: "Roboto",
  fonts: [
    { src: "<path-to-normal-font-variant>" },
    { src: "<path-to-italic-font-variant>", fontStyle: "italic" },
    { src: "<path-to-italic-font-variant>", fontWeight: "600" },
  ],
});

const MyDoc = ({
  user,
  width,
  Refrencesall,
  portfolioall,
  workexp,
  education,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.flexBoxforleftandright}>
          <View style={styles.sectionleft}>
            {user?.media && (
              <Image
                style={styles.image}
                src={{
                  uri: user?.media,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
                alt=""
              />
            )}
          </View>
          <View style={styles.sectionright}>
            <View>
              <Text
                style={{
                  ...styles.simpletextBlod,
                  ...styles.fullName,
                }}
              >
                {user?.fullName}
              </Text>
              <Text style={{ ...styles.worktitle }}>{user?.title}</Text>
            </View>

            <Text style={styles.simpletext}>{user?.fullDescription}</Text>

            <View style={styles.hr}></View>
          </View>
        </View>
      </View>
      <View style={styles.section1}>
        <View style={styles.flexBoxforleftandright}>
          <View style={styles.sectionleft}>
            <Text style={styles.blueheading}>Profile</Text>
            <View style={styles?.flexBoxforhalf}>
              <View style={styles?.flexBoxonly}>
                <Text style={styles.simpletext}>
                  <Link style={{ textDecoration: "none" }} src={user?.fLink}>
                    {" "}
                    <Image style={styles.imagex} src={facebookIcon} alt="" />
                  </Link>
                </Text>
              </View>

              <View style={styles?.flexBoxonly}>
                <Text>
                  <Link style={{ textDecoration: "none" }} src={user?.gLink}>
                    {" "}
                    <Image style={styles.imagex} src={GoogleIcon} alt="" />
                  </Link>{" "}
                </Text>
              </View>

              <View style={styles?.flexBoxonly}>
                <Text>
                  <Link style={{ textDecoration: "none" }} src={user?.lLink}>
                    {" "}
                    <Image style={styles.imagex} src={LinkedInIcon} alt="" />
                  </Link>{" "}
                </Text>
              </View>

              <View style={styles?.flexBoxonly}>
                <Text>
                  <Link style={{ textDecoration: "none" }} src={user?.gitLink}>
                    {" "}
                    <Image style={styles.imagex} src={GitHubIcon} alt="" />
                  </Link>{" "}
                </Text>
              </View>
            </View>

            {user?.contactNo && (
              <View style={styles.profileFlex}>
                <Image src={phone} alt="" style={styles.imagexx} />{" "}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: "3px",
                  }}
                >
                  {user?.contactNo}
                </Text>
              </View>
            )}
            {user?.emailId && (
              <View style={styles.profileFlex}>
                <Image src={email} alt="" style={styles.imagexx} />{" "}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: "3px",
                  }}
                >
                  {user?.emailId}
                </Text>
              </View>
            )}
            {user?.dob && (
              <View style={styles.profileFlex}>
                <Image src={dob} alt="" style={styles.imagexx} />{" "}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: "3px",
                  }}
                >
                  {user?.dob && new Date(user?.dob).getDate()}/
                  {user?.dob && new Date(user?.dob).getMonth() + 1}/
                  {user?.dob && new Date(user?.dob).getFullYear()}
                </Text>
              </View>
            )}

            {user?.address && (
              <View style={styles.profileFlex}>
                <Image src={location} alt="" style={styles.imagexx} />{" "}
                <Text
                  style={{
                    ...styles.simpletext,
                    paddingLeft: "3px",
                  }}
                >
                  {user?.address}
                </Text>
              </View>
            )}
            {user?.languages?.length > 0 && (
              <Text style={styles.blueheading}>Language</Text>
            )}
            {user?.languages?.length > 0 &&
              user?.languages?.map((lang) => {
                return (
                  <View style={styles.justifyContent2}>
                    <Text style={styles.blacksubheading}>{lang?.language}</Text>
                    <View
                      style={{
                        ...styles.profileFlex,

                        width: "50px",
                        height: "4px",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          width:
                            lang?.proficiencyLevel === "Highly"
                              ? "100%"
                              : lang?.proficiencyLevel === "Intermediate"
                              ? "65%"
                              : "36%",
                          marginTop: "3px",
                          height: "4px",
                          backgroundColor: "#0052cc",
                        }}
                      ></View>
                      <View
                        style={{
                          width:
                            lang?.proficiencyLevel === "Highly"
                              ? "0%"
                              : lang?.proficiencyLevel === "Intermediate"
                              ? "34%"
                              : "66%",
                          height: "4px",
                          marginTop: "3px",
                          backgroundColor: "#0052cc90",
                        }}
                      ></View>
                    </View>
                  </View>
                );
              })}

            {user?.noOfSkillSet?.length > 0 && (
              <Text style={styles.blueheading}>Skills</Text>
            )}

            {user?.noOfSkillSet?.length > 0 &&
              user?.noOfSkillSet?.map((skill, ind) => {
                return (
                  ind % 2 !== 1 && (
                    <View style={styles.justifyContent2}>
                      <Text
                        style={{
                          ...styles.blacksubheading,
                          width: "50%",
                        }}
                      >
                        {skill?.skill}
                      </Text>
                      {ind < user?.noOfSkillSet?.length && (
                        <Text
                          style={{
                            ...styles.blacksubheading,
                            width: "50%",
                          }}
                        >
                          {user?.noOfSkillSet[ind + 1]?.skill}
                        </Text>
                      )}
                    </View>
                  )
                );
              })}

            {user?.hobbies?.length > 0 && (
              <Text style={styles.blueheading}>Hobbies</Text>
            )}
            {user?.hobbies?.length > 0 &&
              user?.hobbies?.map((skill, ind) => {
                return (
                  ind % 2 !== 1 && (
                    <View style={styles.justifyContent2}>
                      <Text
                        style={{
                          ...styles.blacksubheading,
                          width: "50%",
                        }}
                      >
                        {skill?.hobby}
                      </Text>
                      {ind < user?.noOfSkillSet?.length && (
                        <Text
                          style={{
                            ...styles.blacksubheading,
                            width: "50%",
                          }}
                        >
                          {user?.hobbies[ind + 1]?.hobby}
                        </Text>
                      )}
                    </View>
                  )
                );
              })}

            {Refrencesall?.length > 0 && (
              <Text style={styles.blueheading}>Refrence</Text>
            )}
            {Refrencesall?.length > 0 &&
              Refrencesall?.map((refrence) => {
                return (
                  <View style={{ marginBottom: "5px" }}>
                    <View>
                      <Text
                        style={{
                          ...styles.blacksubheading,
                          width: "100%",
                        }}
                      >
                        {refrence?.name}
                      </Text>
                    </View>

                    <View>
                      <Text
                        style={{
                          ...styles.simpletext,
                          width: "100%",
                        }}
                      >
                        {refrence?.title}
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...styles.simpletext,
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.simpletextBlod,
                        }}
                      >
                        P:
                      </Text>
                      {refrence?.phone}
                    </Text>
                    <Text
                      style={{
                        ...styles.simpletext,
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          ...styles.simpletextBlod,
                          width: "100%",
                        }}
                      >
                        E:
                      </Text>
                      {refrence?.emailId}
                    </Text>
                  </View>
                );
              })}
          </View>
          <View style={styles.sectionright}>
            {workexp?.length > 0 && (
              <Text style={styles.blueheading}>Work Experience</Text>
            )}

            {workexp?.length > 0 &&
              workexp?.map((work) => {
                return (
                  <View
                    style={{
                      marginLeft: "5px",
                      marginTop: "3px",
                      marginBottom: "3px",
                      width: "100%",
                    }}
                  >
                    <View style={styles.justifyContent}>
                      <View
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          width: "78%",
                        }}
                      >
                        <View style={styles.bluedotcover}>
                          <View style={styles.bluedot}></View>
                        </View>
                        <Text style={styles.bluesubheading}>
                          {work?.companyName}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.simpletext}>
                          <Text style={styles.simpletext}>
                            {work?.startDate &&
                              new Date(work?.startDate).getDate()}
                            /
                            {work?.startDate &&
                              new Date(work?.startDate).getMonth() + 1}
                            /
                            {work?.startDate &&
                              new Date(work?.startDate).getFullYear()}
                            -{" "}
                            {!work?.workingCurrently
                              ? new Date(work?.endDate).getDate() +
                                "/" +
                                (new Date(work?.endDate).getMonth() + 1) +
                                "/" +
                                new Date(work?.endDate).getFullYear()
                              : "Present"}
                          </Text>
                        </Text>
                      </View>
                    </View>
                    <Text style={styles.blacksubheading}>{work?.jobTitle}</Text>
                    <Text style={styles.simpletext}>{work?.description}</Text>
                  </View>
                );
              })}

            {education?.length > 0 && (
              <Text style={styles.blueheading}>Education</Text>
            )}

            {education?.length > 0 &&
              education?.map((educa) => {
                return (
                  <View
                    style={{
                      marginLeft: "5px",
                      marginTop: "3px",
                      marginBottom: "3px",
                      width: "100%",
                    }}
                  >
                    <View style={styles.justifyContent}>
                      <View
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          width: "78%",
                        }}
                      >
                        <View style={styles.bluedotcover}>
                          <View style={styles.bluedot}></View>
                        </View>

                        <Text style={styles.bluesubheading}>
                          {educa?.collegeName}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.simpletext}>
                          {educa?.fromDate &&
                            new Date(educa?.fromDate).getFullYear()}
                          -
                          {educa?.toDate &&
                            new Date(educa?.toDate).getFullYear()}
                        </Text>
                      </View>
                    </View>
                    <Text style={styles.blacksubheading}>{educa?.degree} {educa?.areaOfStudy? `(${educa?.areaOfStudy})`:""} </Text>
                  </View>
                );
              })}

            {portfolioall?.length > 0 && (
              <Text style={styles.blueheading}>Projects</Text>
            )}

            {portfolioall?.length > 0 &&
              portfolioall?.map((portfolio) => {
                return (
                  <View
                    style={{
                      marginLeft: "5px",
                      marginTop: "3px",
                      marginBottom: "3px",
                      width: "100%",
                    }}
                  >
                    <View style={styles.justifyContent}>
                      <View
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          width: "78%",
                        }}
                      >
                        <View style={styles.bluedotcover}>
                          <View style={styles.bluedot}></View>
                        </View>
                        <Text style={styles.bluesubheading}>
                          {portfolio?.projectTitle}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.simpletext}>
                          {portfolio?.startingDate &&
                            new Date(portfolio?.startingDate).getDate()}
                          /
                          {portfolio?.startingDate &&
                            new Date(portfolio?.startingDate).getMonth() + 1}
                          /
                          {portfolio?.startingDate &&
                            new Date(portfolio?.startingDate).getFullYear()}
                          -{" "}
                          {!portfolio?.currentlyWorking
                            ? new Date(portfolio?.completionDate).getDate() +
                              "/" +
                              (new Date(portfolio?.completionDate).getMonth() +
                                1) +
                              "/" +
                              new Date(portfolio?.completionDate).getFullYear()
                            : "Present"}
                        </Text>
                      </View>
                    </View>
                    <Text style={styles.blacksubheading}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        src={portfolio?.projectUrl}
                      >
                        {portfolio?.projectUrl}
                      </Link>
                    </Text>
                    <Text style={styles.simpletext}>
                      {portfolio?.projectDescription}
                    </Text>
                  </View>
                );
              })}
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.simpletext}> Resume created on</Text>
        <Image style={styles.logo} src={imglogo} />
      </View>
    </Page>
  </Document>
);
export default function ProfileBanner({
  user,
  Refrencesall,
  portfolioall,
  workexp,
  education,
}) {
  const width = 900;

  const [referdata, setReferdata] = useState();

  useEffect(() => {
      if (user?.userName) {
          
          axios
            .get(`${API_HOST}/referal/viewByAdminOthereSingle?userName=${user?.userName}`, {
              headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
              },
            })
            .then((res) => {
              setReferdata(res?.data?.success?.data?.[0]);
            
              handleClosex1();
            })
            .catch((err) => {
              console.log(err);
            });
      }
  }, [user]);

  return (
    <div className="Profilebanner-container">
      <div className="coverPiccontainer">
        <div className="coverimgqgeprofile">
          <img src={user?.coverPic ? user?.coverPic : img} alt="" />
        </div>
      </div>

      <div className="profilePiccontainer">
        <div className="image-profilepart">
          <img src={user?.media ? user?.media : img4} alt="" />
          <div className="profileeditimgqgeprofile"></div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          className="profilebannernameandpost"
        >
          <div>
            <div className="profilebannernameandpostname">{user?.fullName}</div>
            <div className="profilebannernameandpostemail">
              <span>
                <img src={img2} alt="" />
              </span>
              {user?.userName}
            </div>
          </div>
          <div>
            <button
              style={{
                margin: width > 700 ? "1.2vw 0vw" : "4vw 0vw",
                width: "fit-content",
                paddingLeft: "1vw",
                marginRight: "1vw",
                paddingRight: "1vw",
              }}
              className={referdata? "hb-buttonx" : "hb-button"}
            >
              {referdata ? referdata?._id?.refferdby?.userName[0] : "No Referral"}
            </button>
            <PDFDownloadLink
              document={
                <MyDoc
                  user={user}
                  Refrencesall={Refrencesall}
                  portfolioall={portfolioall}
                  workexp={workexp}
                  education={education}
                />
              }
              fileName={`${user?.fullName}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  ""
                ) : (
                  <button
                    onClick={() => {}}
                    style={{
                      margin: width > 700 ? "1.2vw 0vw" : "4vw 0vw",
                      marginRight: "2vw",
                    }}
                    className={user?.openForWork ? "hb-buttonx" : "hb-button"}
                  >
                    Resume
                  </button>
                )
              }
            </PDFDownloadLink>

            <button
              style={{ margin: width > 700 ? "1.2vw 0vw" : "4vw 0vw" }}
              className={user?.openForWork ? "hb-buttonx" : "hb-button"}
            >
              {!user?.openForWork ? "Close for Work" : "Open For Work"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
