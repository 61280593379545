import React, { useEffect, useState } from "react";
import axios from "axios";
import API_HOST from "../../../env";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import Skillpopupcopy from "./Skillpopupcopy";
import searchicon from "../../../assets/Dashboard/Iconly-Light-Search.svg";
import { useNavigate } from "react-router";

export default function Expenditure({ width }) {
  const [page1, setPage1] = useState(1);

  const [setSelectedCategory1, setSetSelectedCategory1] = useState("");

  const [allusers1, setAllusers1] = useState([]);

  const [recall1, setRecall1] = useState(false);
  const [selecteddelete1, setSelecteddelete1] = useState([]);
  const [sort, setSort] = useState("date");

  useEffect(() => {
    axios
      .get(
        `${API_HOST}/expenditure/adminViewsExpenditure?pageSize=10&pageNumber=${page1}&search=${setSelectedCategory1}&${sort}=true`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((res) => {
        setAllusers1(res?.data?.success?.data);
        window.scrollTo(0, 0, { behavior: "smooth" });
      });
  }, [page1, setSelectedCategory1, recall1, sort]);
  const navigate = useNavigate();
  return (
    <div style={{ paddingTop: "1vw" }} className="BrowseWorkMain-cntainer">
      <div
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          height: width > 700 ? "4vw" : "10vw",
          marginBottom: "1vw",
          marginTop: width > 700 ? "0" : "1vw",
        }}
        className="searchboxcontainer"
      >
        <div
          style={{
            height: width > 700 ? "3vw" : "9vw",
            paddingRight: "0.3vw",
            marginTop: "0vw",
          }}
          className="serachjobbox"
        >
          <span>
            <img
              src={searchicon}
              style={{ width: width > 700 ? "1.7vw" : "5vw" }}
              alt=""
              alt=""
            />
          </span>
          <input
            type="text"
            placeholder={"search here .."}
            value={setSelectedCategory1}
            onChange={(e) => {
              setSetSelectedCategory1(e.target.value);
            }}
          />
        </div>

       
      </div>

      <div
        style={{ overflowX: "scroll", width: width > 700 ? "82vw" : "92vw" }}
        className="ScrollTable"
      >
        <div
          style={{
            margin: "0vw 1vw",
            padding: "0vw 1vw",
            width: width > 700 ? "80vw" : "192vw",
            marginTop: width > 700 && "0.01vw",
          }}
          className="navoftableblogs"
        >
          <div style={{ width: width > 700 ? "19vw" : "30vw" }}>
            Username
          </div>
          <div style={{ width: width > 700 ? "19vw" : "30vw" }}>
            Item /Product
          </div>
          <div style={{ width: width > 700 ? "16vw" : "50vw" }}>Head</div>
          <div style={{ width: width > 700 ? "11vw" : "30vw" }}>Total GST</div>

          <div style={{ width: width > 700 ? "16vw" : "40vw" }}>
            Total Amount
            <span
              onClick={() => {
                setSort("totalAmount");
              }}
            >
              {sort === "totalAmount" ? (
                <UnfoldLessIcon
                  style={{
                    width: width > 700 ? "2vw" : "5vw",
                    cursor: "pointer",
                    color: "#0052CC",
                  }}
                />
              ) : (
                <UnfoldMoreIcon
                  style={{
                    width: width > 700 ? "2vw" : "5vw",
                    cursor: "pointer",
                  }}
                />
              )}{" "}
            </span>{" "}
          </div>

          <div style={{ width: width > 700 ? "11vw" : "30vw" }}>
            Date
            <span
              onClick={() => {
                setSort("date");
              }}
            >
              {sort === "date" ? (
                <UnfoldLessIcon
                  style={{
                    width: width > 700 ? "2vw" : "5vw",
                    cursor: "pointer",
                    color: "#0052CC",
                  }}
                />
              ) : (
                <UnfoldMoreIcon
                  style={{
                    width: width > 700 ? "2vw" : "5vw",
                    cursor: "pointer",
                  }}
                />
              )}
            </span>
          </div>

          <div style={{ width: width > 700 ? "8vw" : "15vw" }}> </div>
        </div>
        {allusers1?.length > 0 &&
          allusers1?.map((data, index) => {
            return (
              <Skillpopupcopy
                width={width}
                data={data}
                index={index}
                page={page1}
                setSelecteddelete={setSelecteddelete1}
                selecteddelete={selecteddelete1}
                setRecall={setRecall1}
                recall={recall1}
              />
            );
          })}

        <div
          style={{ width: width > 700 ? "25vw" : "90vw" }}
          className="paginationbox"
        >
          <div
            onClick={() => {
              if (page1 > 1) {
                setPage1(page1 - 1);
              }
            }}
          >
            <ArrowBackIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                if (page1 - 1 > 0) {
                  setPage1(page1 - 1);
                }
              }}
            />
          </div>

          <div
            hidden={page1 - 4 > 0 ? false : true}
            onClick={() => setPage1(page1 - 4)}
          >
            {page1 - 4}
          </div>
          <div
            hidden={page1 - 3 > 0 ? false : true}
            onClick={() => setPage1(page1 - 3)}
          >
            {page1 - 3}
          </div>
          <div
            hidden={page1 - 2 > 0 ? false : true}
            onClick={() => setPage1(page1 - 2)}
          >
            {page1 - 2}
          </div>
          <div
            hidden={page1 - 1 > 0 ? false : true}
            onClick={() => setPage1(page1 - 1)}
          >
            {page1 - 1}
          </div>
          <div style={{ color: "#2A6599" }}>{page1}</div>
          <div onClick={() => setPage1(page1 + 1)}>{page1 + 1}</div>
          <div onClick={() => setPage1(page1 + 2)}>{page1 + 2}</div>
          <div onClick={() => setPage1(page1 + 3)}>{page1 + 3}</div>

          <div onClick={() => setPage1(page1 + 1)}>
            <ArrowForwardIosIcon
              style={{ fontSize: width > 700 ? "1.5vw" : "4vw" }}
              onClick={() => {
                setPage1(page1 + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
