import axios from "axios";
import React from "react";

import { useNavigate } from "react-router";
import API_HOST from "../../../env";

export default function Skillpopupcopy({ width, data }) {
  const navigate = useNavigate();
  const ref = React.createRef();

  const handlecreatelink = () => {
    if (data?.customer_id) {
      axios
        ?.post(
          `${API_HOST}/stripe/portalSessionAdmin`,
          { return_url: window.location.href, customer_id: data?.customer_id },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          window.location.replace(res?.data?.portalSession);
        })
        .catch(() => {});
    }
  };

  return (
    <div ref={ref}>
      <div
        style={{
          alignItems: "center",
          margin: "1vw 0vw",
          padding: width > 700 ? "0.5vw  1vw" : "2vw",
          color: "black",
          width: width > 700 ? "82vw" : "220vw",
          cursor: "pointer",
        }}
        className="navoftableblogsdata"
      >
        <div style={{ width: width > 700 ? "17vw" : "70vw" }}>
          {data?.fullName}
        </div>

        <div style={{ width: width > 700 ? "9vw" : "30vw" }}>
          {" "}
          {data?.contactNo}
        </div>
        <div style={{ width: width > 700 ? "21vw" : "70vw" }}>
          {" "}
          {data?.emailId?.length > 25
            ? data?.emailId?.slice(0, 25) + ".."
            : data?.emailId}
        </div>

        <div style={{ width: width > 700 ? "9vw" : "50vw" }}>
          Rs.{parseFloat(data?.amount_total) / 100}
        </div>
        <div style={{ width: width > 700 ? "8vw" : "30vw" }}>
          {new Date(data?.created_at).getDate()}/
          {new Date(data?.created_at).getMonth() + 1}/
          {new Date(data?.created_at).getFullYear()}
        </div>
        <div
          style={{
            width: width > 700 ? "7vw" : "37vw",

            color: data?.payment_status === "paid" ? "green" : "red",
            fontSize: width > 700 ? "0.85vw" : "2.5vw",
            textAlign: "left",
          }}
        >
          {data?.payment_status}
        </div>
        {data?.payment_status === "paid" && data?.customer_id && (
          <div
            style={{
              width: width > 700 ? "7vw" : "37vw",
              color: "#0052cc",
              fontSize: width > 700 ? "0.85vw" : "2.5vw",
              textAlign: "left",
              border: "1px solid #0052cc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              handlecreatelink();
            }}
          >
            View
          </div>
        )}
      </div>
    </div>
  );
}
