import React from "react";
import BusinessValueFormCard from "./BusinessValueFormCard";
import { useEffect, useState } from "react";
import img1 from "../../../assets/My profile – 28/Component 70 – 6.svg";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import API_HOST from "../../../env";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "48vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.91vw",
    color: "#263238",
    border: "yellow !important",
  },
  select: {
    height: "1vw",
    width: "100%",
    padding: "1vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
  },
  select2: {
    height: "1vw",
    width: "100%",
    padding: "1vw 0.5vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
  },
  select3: {
    height: "1vw",
    width: "100%",
    marginTop: "0.1vw",
    padding: "0vw 0vw",
    marginLeft: "0vw",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.51vw",
    lineHeight: "100%",
    position: "relative",
    top: "0.81vw",
    left: "0.2vw",
  },
  icon: {
    fill: "black",
  },
}));

const BusinessValueForm = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const width = 1000;

  const [errorp, setErrorp] = useState("");
  const [certificated, setcertificated] = useState([]);

  const handleaddcertificate = () => {
    const formdata = new FormData();

    formdata.append("title", title);
    formdata.append("feildType", type);
    (type === "Multiple Choice" ||
      type === "Checkboxes" ||
      type === "Drop Down") &&
      formdata.append("options", JSON.stringify(optionValues));
    type === "Number" && formdata.append("valueMultiple", valueMultiple);
    formdata.append("required", required);

    axios
      .post(`${API_HOST}/businessValueForm/addBusinessform`, formdata, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then((res) => {
        axios
          .get(`${API_HOST}/businessValueForm/viewBusinessform`)
          .then((res) => {
            setcertificated(res?.data?.success?.data);
            handleClose();
          });
      });
  };
  const [title, setTitle] = useState("");
  const [type, settype] = useState(0);
  const [required, setRequired] = useState(false);
  const [valueMultiple, setvalueMultiple] = useState();
  const [optionValues, setOptionValues] = useState([
    { label: "", value: null },
  ]);
  const handleChange3 = (event) => {
    settype(event.target.value);
  };
  const [recall, setRecall] = useState(true);

  useEffect(() => {
    axios.get(`${API_HOST}/businessValueForm/viewBusinessform`).then((res) => {
      setcertificated(res?.data?.success?.data);
    });
  }, [recall]);

  return (
    <div style={{ height: "calc( 100vh - 4vw)" }} className="ScrollTable">
      <div
        style={{ height: "fit-content", marginRight: "1%" }}
        className="Dashbaorddataboxes"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="profiledetailstitle"
        >
          <div>
            Business Valuation Form{" "}
            <span style={{ fontSize: "1vw" }}>
              ( Please add and remove form fields one by one)
            </span>
          </div>
          <div
            onClick={handleOpen}
            className="profiledetailnavmanu"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "fit-content",
              left: "0",
            }}
          >
            <img style={{ width: "2.5vw" }} src={img1} alt="" />
            Add
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={width > 700 ? style : style1}>
            <div style={{ maxHeight: "90vh", overflowY: "scroll" }}>
              <div className="profiletitleandmenunav">
                <div className="profiledetailstitle">
                  Add Business Valuation Form Field
                </div>
                <div className="profiledetailnavmanu">
                  <CloseIcon
                    onClick={handleClose}
                    style={{
                      fontSize: width > 700 ? "1.5vw" : "4vw",
                      position: "relative",
                      right: "1vw",
                    }}
                  />
                </div>
              </div>
              <hr style={{ color: "#000000" }} />
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <TextField
                  id="outlined-basic"
                  label="Field title"
                  variant="outlined"
                  value={title}
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    style: {
                      fontSize: width > 700 ? "1vw" : "3vw",
                      fontFamily: "Poppins",
                      fontStyle: "500",
                      fontWeight: "500",
                      color: "black",
                    },
                  }}
                  inputProps={{ className: classes.input }}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div
                style={{ left: "0vw", width: "100%" }}
                className="loginfield"
              >
                <Box
                  sx={{
                    background: "white",
                    border: "1px solid #7070705b",
                    height: "3vw",
                    width: "64vw",
                    borderRadius: "5px",
                    padding: "0.4vw 0.2vw",
                  }}
                  className="setting-toggler"
                >
                  <FormControl variant="standard" fullWidth>
                    <Select
                      className={classes.select2}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={type}
                      disableUnderline
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      onChange={handleChange3}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: "white",

                            "& .MuiMenuItem-root": {
                              padding: "0.1vw 2vw",
                              width: "100%",
                              height: "3vw",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "1vw",
                              lineHeight: "24px",
                              color: "#6b6b6b",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={() => {}} value={0} hidden>
                        Select Type
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          settype("Text");
                        }}
                        value={"Text"}
                      >
                        Text
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          settype("Number");
                        }}
                        value={"Number"}
                      >
                        Number
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          settype("Multiple Choice");
                        }}
                        value={"Multiple Choice"}
                      >
                        Multiple Choice
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          settype("Checkboxes");
                        }}
                        value={"Checkboxes"}
                      >
                        Checkboxes
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          settype("Drop Down");
                        }}
                        value={"Drop Down"}
                      >
                        Drop Down
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>

              {type === "Number" && (
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Value Multiple Ratio"
                    variant="outlined"
                    value={valueMultiple}
                    style={{ width: "100%" }}
                    type="number"
                    InputLabelProps={{
                      style: {
                        fontSize: width > 700 ? "1vw" : "3vw",
                        fontFamily: "Poppins",
                        fontStyle: "500",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                    inputProps={{ className: classes.input }}
                    onChange={(e) => setvalueMultiple(e.target.value)}
                  />
                </div>
              )}
              {(type === "Multiple Choice" ||
                type === "Checkboxes" ||
                type === "Drop Down") &&
                optionValues?.map((data, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",

                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          left: "0vw",
                          width: width > 700 ? "47%" : "100% ",
                          marginTop: "0",
                          marginLeft: "0%",
                        }}
                        className="loginfield"
                      >
                        <TextField
                          id="outlined-basic"
                          label="label"
                          variant="outlined"
                          value={data.label}
                          style={{ width: "100%" }}
                          type="text"
                          InputLabelProps={{
                            style: {
                              fontSize: width > 700 ? "1vw" : "3vw",
                              fontFamily: "Poppins",
                              fontStyle: "500",
                              fontWeight: "500",
                              color: "black",
                            },
                          }}
                          inputProps={{ className: classes.input }}
                          onChange={(e) =>
                            setOptionValues([
                              ...optionValues.slice(0, index),
                              {
                                label: e.target.value,
                                value: optionValues[index].value,
                              },
                              ...optionValues.slice(
                                index + 1,
                                optionValues.length
                              ),
                            ])
                          }
                        />
                      </div>

                      <div
                        style={{
                          left: "0vw",
                          width: width > 700 ? "47%" : "100% ",
                          marginTop: "0",
                          marginLeft: "0%",
                        }}
                        className="loginfield"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Value "
                          variant="outlined"
                          value={data.value}
                          style={{ width: "100%" }}
                          type="number"
                          InputLabelProps={{
                            style: {
                              fontSize: width > 700 ? "1vw" : "3vw",
                              fontFamily: "Poppins",
                              fontStyle: "500",
                              fontWeight: "500",
                              color: "black",
                            },
                          }}
                          inputProps={{ className: classes.input }}
                          onChange={(e) =>
                            setOptionValues([
                              ...optionValues.slice(0, index),
                              {
                                label: optionValues[index].label,
                                value: e.target.value,
                              },
                              ...optionValues.slice(
                                index + 1,
                                optionValues.length
                              ),
                            ])
                          }
                        />
                      </div>

                      <div
                        style={{
                          left: "0vw",
                          width: width > 700 ? "3%" : "10% ",
                          marginTop: "0",
                          marginLeft: "0%",
                        }}
                        className="loginfield"
                      >
                        <CloseIcon
                          onClick={() => {
                            if (optionValues.length > 1) {
                              setOptionValues([
                                ...optionValues.slice(0, index),
                                ...optionValues.slice(
                                  index + 1,
                                  optionValues.length
                                ),
                              ]);
                            }
                          }}
                          style={{
                            fontSize: width > 700 ? "1.5vw" : "4vw",
                            position: "relative",
                            top: "1vw",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              {(type === "Multiple Choice" ||
                type === "Checkboxes" ||
                type === "Drop Down") && (
                <div
                  style={{ marginTop: "0.1vw" }}
                  className="addmoreservicecatalog"
                  onClick={() => {
                    setOptionValues([
                      ...optionValues,
                      { label: "", value: null },
                    ]);
                  }}
                >
                  <span>
                    <AddIcon
                      style={{ fontSize: width > 700 ? "1.3vw" : "4vw" }}
                    />
                  </span>{" "}
                  Add More Offers
                </div>
              )}

              <FormControlLabel
                label="Required"
                className="m-1"
                control={
                  <Switch
                    checked={required}
                    onChange={(e) => setRequired(!required)}
                  />
                }
              />
              <p
                style={{
                  color: "red",
                  fontSize: width > 700 ? "0.9vw" : "2.5vw",
                  lineHeight: "2vw",
                }}
              >
                {errorp}
              </p>

              <div
                style={{ marginTop: "0.31vw" }}
                className="handlemoreaboutskill"
              >
                <div
                  style={{
                    background: "white",
                    color: "black",
                    cursor: "pointer",
                  }}
                  className="handlecirclieaboutsave"
                  onClick={handleClose}
                >
                  Cancel
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="handlecirclieaboutsave"
                  onClick={handleaddcertificate}
                >
                  SAVE
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          className="DashbaordBoxes"
        >
          {certificated?.map((data) => {
            return (
              <BusinessValueFormCard
                data={data}
                recall={recall}
                setRecall={setRecall}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BusinessValueForm;
